import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Tabs } from 'antd'
import { DateAndDimensionFilters } from 'modules/core/components'
import { useQuery } from 'modules/core/customHooks'
import { SiderLayout } from 'modules/core/layouts'
import planning from 'modules/planning'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ReportDashboard, ReportDetail } from './components'

const REPORT_KEY = 'reporte'
const DASHBOARD_KEY = 'dashboard'

const ReportDetailHome = ({ periodList }) => {
  // Feature flag
  const enabled = useFeatureIsOn('feature-react-pivot-evolution')

  // Hooks
  const { t } = useTranslation()
  let { periodId } = useParams()
  let query = useQuery()

  // State
  const [selectedDates, setSelectedDates] = useState({})
  const [filters, setFilters] = useState({})
  const [activeTab, setActiveTab] = useState(REPORT_KEY)

  // Methods
  const periodDetail =
    periodList.length > 0 ? periodList.find((el) => el.id === parseInt(periodId)) : {}

  const auxKey = window.location.pathname.replaceAll('/', '-')
  const key = `${auxKey}?tab=${activeTab}`

  const renderHeader = () => {
    if (!enabled) {
      return (
        <DateAndDimensionFilters
          dataPeriod={periodDetail}
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}
          periodId={periodId}
          filters={filters}
          setFilters={setFilters}
          showButtonDeleteFilter={(show) => {}} // eslint-disable-line
        />
      )
    }
    return null
  }

  return (
    <SiderLayout extraKey={`?tab=${activeTab}`} header={renderHeader()}>
      <Tabs
        size="small"
        defaultActiveKey={query.get('tab') !== null ? query.get('tab') : REPORT_KEY}
        onChange={setActiveTab}
      >
        <Tabs.TabPane tab={t('LABEL_REPORTS')} key={REPORT_KEY}>
          {activeTab === REPORT_KEY && (
            <ReportDetail
              periodDetail={periodDetail}
              selectedDates={selectedDates}
              filters={filters}
              commentKey={key}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('LABEL_DASHBOARD')} key={DASHBOARD_KEY}>
          {activeTab === DASHBOARD_KEY && (
            <ReportDashboard selectedDates={selectedDates} filters={filters} commentKey={key} />
          )}
        </Tabs.TabPane>
      </Tabs>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  periodList: planning.selectors.getPeriodList(state),
})

export default connect(mapStateToProps, null)(ReportDetailHome)

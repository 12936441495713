import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import MonitorDetail from '../../../monitor/monitor-detail'

const MonitorWorkflow = () => {
  let { forecastId, step } = useParams()

  return (
    <MonitorDetail
      moduleWorkflow={'transactions_file_upload'}
      moduleExecution={'forecast'}
      keyExecution={`${forecastId}-${step}`}
    />
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MonitorWorkflow)

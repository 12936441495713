const home = {
  HOME_INITIAL_STEPS_LOAD_DATA_INFORMATIVE_TOOLTIP_TITLE:
    'You must import data through any of these options to continue',
  HOME_INITIAL_STEPS_SELECTED_DATA_INFORMATIVE_TOOLTIP_TITLE:
    'You must first map the information of each loaded file (dimensions and transactions) and associate it with the information that Plika needs to continue',
  HOME_INITIAL_STEPS_PREVIOUS_STEP_MODAL_TITLE: 'return to the previous step',
  HOME_INITIAL_STEPS_PREVIOUS_STEP_MODAL_BODY:
    'Are you sure you want to return to the previous step?',
  HOME_INITIAL_STEPS_LOAD_FILE: 'Import your data',
  HOME_INITIAL_STEPS_SELECT_DATA: 'Associate your origin data to Plika',
  HOME_INITIAL_SCREEN_FIRST_STEP: 'Prepare your .CSV file(s) with your historical information.',
  HOME_INITIAL_SCREEN_SECOND_STEP:
    'These files can contain transactional and dimensions information or only dimension information.',
  HOME_INITIAL_SCREEN_SECOND_STEP_LNK: 'See examples',
  HOME_INITIAL_SCREEN_THIRD_STEP: `You are ready to enter Plika's world!`,
  HOME_INITIAL_SCREEN_SUBTITLE: 'We are about to start configuring the tool, but before:',
  HOME_INITIAL_SCREEN_TUTORIAL: 'Did you have doubts? Check this',
  HOME_INITIAL_SCREEN_TUTORIAL_LINK: 'Plika-Tutorial',
  HOME_INITIAL_SCREEN_QUESTION: 'Are you ready for this adventure?',
  HOME_INITIAL_STEPS_LOAD_FILE_DESCRIPTION: 'Load your files in .CSV format',
  HOME_INITIAL_STEPS_SELECT_DATA_DESCRIPTION:
    'Choose and associate the data from your files with what Plika needs',

  HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_SUBTITLE: 'Transactions',
  HOME_INITIAL_STEPS_SELECT_DATA_COLUMNS_TRANSACTION_FILE_SELECT_TITLE: 'File column',
  HOME_INITIAL_STEPS_SELECT_DATA_DIMENSIONS_PLIKA_SELECT_TITLE: `Plika's dimensions`,
  HOME_INITIAL_STEPS_SELECT_DATA_ADD_NEW_SELECTION_ACTION: 'Add new selection',
  HOME_INITIAL_STEPS_SELECT_DATA_DELETE_SELECTION_ACTION: 'Remove selection',
  HOME_INITIAL_STEPS_SELECT_DATA_DELETE_UPLOAD_FILE_POPCONFIRM_TITLE:
    'Are you sure you want to delete this file? If you do, the data in this file will not be processed in this instance. Then you can load it from the actuals.',
  HOME_INITIAL_STEPS_PROCESSING_DATA_SCREEN_PROCESSING_TITLE: 'We are processing the information',
  HOME_INITIAL_STEPS_INFORMATIVE_SCREEN_PROCESSING_TITLE:
    'It will be loaded in parts, so when we have something you can visualize you can start navigating the tool..',
  HOME_INITIAL_STEPS_SELECT_DATA_FIELD_CODE_INFORMATIVE_TOOLTIP_TITLE:
    'Some column of type dimension may not be mapped with the Code Plika field',
  HOME_INITIAL_STEPS_SELECT_DATA_AMOUNT_DATE_INFORMATIVE_TOOLTIP_TITLE:
    'You must select the columns related to an amount or price and date to continue',
  HOME_INITIAL_STEPS_SELECT_FORMAT_DATE_SELECT_TITLE: 'Date format',

  HOME_INITIAL_STEPS_EDIT_CREDENTIALS_ACTION: 'Edit credentials',
  HOME_INITIAL_STEPS_CREDENTIAL_FEEDBACK_SUCCESS: 'Correct credential',
  HOME_INITIAL_STEPS_CREDENTIAL_FEEDBACK_FAIL: 'Credential failure',
  HOME_INITIAL_STEPS_UPLOAD_DATA_SPREADSHEETS_INFORMATIVE_ALERT_DESCRPTION:
    'Remember that you must first associate the Google Sheets file with the email account where it is hosted',
  HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_DIMENSION_NAME: 'Dimension - Name',
  HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_DIMENSION_CODE: 'Dimension - Code',
  HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_DIMENSION_DATE: 'Transaction - Date',
  HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_TRANSACTION_AMOUNT:
    'Transaction - Price',
  HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_TRANSACTION_QUANTITY:
    'Transaction - Cuantity',
  HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_TRANSACTION_CURRENCY:
    'Transaction - Currency',

  HOME_GENERAL_CONTACTS_CARD_TEXT: 'Contact and social networks',
  HOME_GENERAL_TRAINING_CARD_TEXT: 'Training and FAQs',
  HOME_GENERAL_MANUAL_LIST_DESCRIPTION:
    'Consult the manual to resolve doubts and optimize the use of our platform.',
  HOME_GENERAL_SITE_LIST_DESCRIPTION: 'Visit our training space to accompany your Plika learning',
  HOME_GENERAL_SITE_LIST_TITLE: 'Self-managed training site',
  HOME_GENERAL_QUESTIONS_LIST_DESCRIPTION: `Learn about Plika's most common concerns`,
  HOME_GENERAL_QUESTIONS_LIST_TEXT: 'Frequently asked questions',
}

export default home

import _ from 'lodash'
import React from 'react'
import { flatKey, formatter, normalizeName, PivotData, usFmt, usFmtPct } from '../../Utilities'

class TSVExport extends React.PureComponent {
  getBasePivotSettings() {
    const props = this.props
    const colAttrs = props.cols
    const rowAttrs = props.rows

    const tableOptions = Object.assign(
      {
        rowTotals: true,
        colTotals: true,
      },
      props.tableOptions,
    )

    const rowTotals = tableOptions.rowTotals || colAttrs.length === 0
    const colTotals = tableOptions.colTotals || rowAttrs.length === 0

    const subtotalOptions = Object.assign(
      {
        arrowCollapsed: '⨁',
        arrowExpanded: 'ⴱ',
      },
      props.subtotalOptions,
    )

    const colSubtotalDisplay = Object.assign(
      {
        displayOnTop: false,
        enabled: rowTotals,
        hideOnExpand: false,
      },
      subtotalOptions.colSubtotalDisplay,
    )

    const rowSubtotalDisplay = Object.assign(
      {
        displayOnTop: true,
        enabled: colTotals,
        hideOnExpand: false,
      },
      subtotalOptions.rowSubtotalDisplay,
    )

    const pivotData = new PivotData(props, {
      rowEnabled: rowSubtotalDisplay.enabled,
      colEnabled: colSubtotalDisplay.enabled,
      rowPartialOnTop: rowSubtotalDisplay.displayOnTop,
      colPartialOnTop: colSubtotalDisplay.displayOnTop,
    })

    const rowKeys = pivotData.getRowKeys()
    const colKeys = pivotData.getColKeys()
    // Also pre-calculate all the callbacks for cells, etc... This is nice to have to
    // avoid re-calculations of the call-backs on cell expansions, etc...
    const cellCallbacks = {}
    const rowTotalCallbacks = {}
    const colTotalCallbacks = {}
    let grandTotalCallback = null
    // Custom lines
    const linesOrder = !_.isEmpty(tableOptions?.linesOrder) ? tableOptions.linesOrder : []
    const linesTotal = !_.isEmpty(tableOptions?.linesTotal) ? tableOptions.linesTotal : []
    const linesRatio = !_.isEmpty(tableOptions?.linesRatio) ? tableOptions.linesRatio : []
    const linesRatioN = !_.isEmpty(tableOptions?.linesRatioN) ? tableOptions.linesRatioN : []
    const linesFinancial = !_.isEmpty(tableOptions?.linesFinancial)
      ? tableOptions.linesFinancial
      : null
    const lineVertical = !_.isEmpty(tableOptions?.lineVertical) ? tableOptions.lineVertical : null

    return Object.assign({
      pivotData,
      colAttrs,
      rowAttrs,
      colKeys,
      rowKeys,
      rowTotals,
      colTotals,
      arrowCollapsed: subtotalOptions.arrowCollapsed,
      arrowExpanded: subtotalOptions.arrowExpanded,
      colSubtotalDisplay,
      rowSubtotalDisplay,
      cellCallbacks,
      rowTotalCallbacks,
      colTotalCallbacks,
      grandTotalCallback,
      linesOrder,
      linesTotal,
      linesRatio,
      linesRatioN,
      linesFinancial,
      lineVertical,
    })
  }

  getAggregator(pivotData, rowKey, colKey) {
    try {
      const agg = pivotData.getAggregator(rowKey, colKey)
      return agg.value()
    } catch (e) {
      return 0
    }
  }

  getFormatter(pivotData) {
    let formatterFunction = usFmt
    if (formatter.hasOwnProperty(pivotData.props.aggregatorName)) {
      formatterFunction = formatter[pivotData.props.aggregatorName]
    }
    return formatterFunction
  }

  renderLinesRow(rowKey, rowIdx, pivotSettings, visibleRowKeys) {
    const { linesOrder, linesTotal, linesRatio, linesRatioN } = pivotSettings
    let isTree = false
    let flatRowKey = flatKey(rowKey)

    if (rowKey.length > 1) {
      flatRowKey = rowKey[0]
      isTree = true
    }
    const line = linesOrder?.find((it) => normalizeName(it.name) === flatRowKey)
    const lineTotal = linesTotal?.find((it) => normalizeName(it.name) === flatRowKey)
    const lineRatio = linesRatio?.find((it) => normalizeName(it.name) === flatRowKey)
    const lineRatioN = linesRatioN?.find((it) => normalizeName(it.name) === flatRowKey)
    const originalRowIdx = visibleRowKeys.findIndex((entry) => flatKey(entry) === flatKey(rowKey))

    if (lineTotal) {
      if (!isTree) {
        return this.renderTableTotalRow(rowKey, rowIdx, pivotSettings, lineTotal, line)
      }
    } else if (lineRatio) {
      if (!isTree) {
        return this.renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatio, usFmtPct, line)
      }
    } else if (lineRatioN) {
      if (!isTree) {
        return this.renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatioN, usFmt, line)
      }
    } else if (originalRowIdx < 0) {
      return this.renderTableLineZeroRow(rowKey, rowIdx, pivotSettings, line)
    } else {
      return this.renderTableRow(rowKey, originalRowIdx, pivotSettings)
    }
  }

  renderTableTotalRow(rowKey, rowIdx, pivotSettings, lineTotal, line) {
    const { visibleColKeys, pivotData } = pivotSettings
    const formatter = this.getFormatter(pivotData)

    const lines = lineTotal.lines.reduce((mergedLines, it) => {
      return mergedLines.concat(it.lines)
    }, [])

    const attrValueCells = rowKey.map((r) => r)
    const rowLength = pivotData.props.rows.length - 1
    for (let i = 0; i < rowLength; i++) {
      attrValueCells.push('')
    }

    const valueCells = visibleColKeys.map((colKey) => {
      const accumulator = lines.reduce((acc, it) => {
        const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
        return acc + amount
      }, 0)
      return formatter(accumulator)
    })

    const accumulator = lines.reduce((acc, it) => {
      const amount = this.getAggregator(pivotData, [normalizeName(it)], [])
      return acc + amount
    }, 0)

    let totalCell = formatter(accumulator)
    return [...attrValueCells, ...valueCells, totalCell]
  }

  renderTableRatioRow(rowKey, rowIdx, pivotSettings, lineRatio, formatter = usFmt, line) {
    const { visibleColKeys, pivotData } = pivotSettings
    let lines1 = []
    let lines2 = []
    if (!_.isEmpty(lineRatio.lines)) {
      lines1 = lineRatio.lines[0]
      lines2 = lineRatio.lines[1]
    }

    const attrValueCells = rowKey.map((r) => r)
    const rowLength = pivotData.props.rows.length - 1
    for (let i = 0; i < rowLength; i++) {
      attrValueCells.push('')
    }

    const valueCells = visibleColKeys.map((colKey) => {
      let result = 0

      const accumulator1 = lines1.lines.reduce((acc, it) => {
        const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
        return acc + amount
      }, 0)

      const accumulator2 = lines2.lines.reduce((acc, it) => {
        const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
        return acc + amount
      }, 0)

      if (accumulator2 !== 0.0) {
        result = accumulator1 / accumulator2
      }

      return formatter(result)
    })

    let result = 0
    const accumulator1 = lines1.lines.reduce((acc, it) => {
      const amount = this.getAggregator(pivotData, [normalizeName(it)], [])
      return acc + amount
    }, 0)

    const accumulator2 = lines2.lines.reduce((acc, it) => {
      const amount = this.getAggregator(pivotData, [normalizeName(it)], [])
      return acc + amount
    }, 0)

    if (accumulator2 !== 0.0) {
      result = accumulator1 / accumulator2
    }

    const totalCell = formatter(result)

    return [...attrValueCells, ...valueCells, totalCell]
  }

  renderTableLineZeroRow(rowKey, rowIdx, pivotSettings, line) {
    const { visibleColKeys, pivotData } = pivotSettings
    const formatter = this.getFormatter(pivotData)

    const attrValueCells = rowKey.map((r) => r)
    const rowLength = pivotData.props.rows.length - 1
    for (let i = 0; i < rowLength; i++) {
      attrValueCells.push('')
    }

    const valueCells = visibleColKeys.map((_) => formatter(0))
    const totalCell = formatter(0)

    return [...attrValueCells, ...valueCells, totalCell]
  }

  renderTableRow(rowKey, rowIdx, pivotSettings) {
    const { visibleColKeys, pivotData } = pivotSettings
    const attrValueCells = rowKey.map((r) => r)

    let valueCells = visibleColKeys.map((colKey) => {
      const agg = pivotData.getAggregator(rowKey, colKey)
      const aggValue = agg.value()

      return agg.format(aggValue)
    })

    const agg = pivotData.getAggregator(rowKey, [])
    const aggValue = agg.value()

    const totalCell = agg.format(aggValue)

    return [...attrValueCells, ...valueCells, totalCell]
  }

  renderStartFinancial(pivotSettings, lineTotal) {
    const { visibleColKeys, pivotData, rowTotals, linesFinancial } = pivotSettings
    const formatterFunction = this.getFormatter(pivotData)

    const lines = lineTotal.lines.reduce((mergedLines, it) => {
      return mergedLines.concat(it.lines)
    }, [])

    const financialLabelCell = 'SALDO INICIAL'

    let valueCells = []
    let count = 0
    let lastAmount = 0
    let lastAccumulator = 0
    visibleColKeys.map((colKey) => {
      const flatColKey = flatKey(colKey)
      let amount = 0

      const accumulator = lines.reduce((acc, it) => {
        const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
        return acc + amount
      }, 0)

      const lineFinancial = linesFinancial[flatColKey]
      const exchangeRate = lineFinancial?.exchange_rate || 1

      if (count === 0) {
        amount = (lineFinancial?.initial_amount || 0) * exchangeRate
        lastAmount = amount
        lastAccumulator = accumulator
      } else {
        amount = lastAccumulator + lastAmount
        lastAmount = amount
        lastAccumulator = accumulator
      }

      count += 1

      valueCells.push(formatterFunction(amount))
    })

    let totalCell = []
    if (rowTotals) {
      totalCell.push('-')
    }

    return [financialLabelCell, ...valueCells, ...totalCell]
  }

  renderEndFinancial(pivotSettings, lineTotal) {
    const { visibleColKeys, pivotData, rowTotals, linesFinancial } = pivotSettings
    const formatterFunction = this.getFormatter(pivotData)

    const lines = lineTotal.lines.reduce((mergedLines, it) => {
      return mergedLines.concat(it.lines)
    }, [])

    const financialLabelCell = 'SALDO FINAL'

    let valueCells = []
    let count = 0
    let lastAmount = 0
    visibleColKeys.map((colKey) => {
      const flatColKey = flatKey(colKey)
      let amount = 0

      const accumulator = lines.reduce((acc, it) => {
        const amount = this.getAggregator(pivotData, [normalizeName(it)], colKey)
        return acc + amount
      }, 0)

      const lineFinancial = linesFinancial[flatColKey]
      const exchangeRate = lineFinancial?.exchange_rate || 1

      if (count === 0) {
        amount = accumulator + (lineFinancial?.initial_amount || 0) * exchangeRate
        lastAmount = amount
      } else {
        amount = accumulator + lastAmount
        lastAmount = amount
      }

      count += 1

      valueCells.push(formatterFunction(amount))
    })

    let totalCell = []
    if (rowTotals) {
      totalCell.push('-')
    }

    return [financialLabelCell, ...valueCells, ...totalCell]
  }

  render() {
    if (this.cachedProps !== this.props) {
      this.cachedProps = this.props
      this.cachedBasePivotSettings = this.getBasePivotSettings()
    }
    const { rowKeys, colKeys, linesOrder, pivotData, linesFinancial, linesTotal } =
      this.cachedBasePivotSettings
    const pivotSettings = Object.assign(
      {
        visibleRowKeys: rowKeys,
        visibleColKeys: colKeys,
      },
      this.cachedBasePivotSettings,
    )

    if (rowKeys.length === 0) {
      rowKeys.push([])
    }
    if (colKeys.length === 0) {
      colKeys.push([])
    }

    const baseRow = _.cloneDeep(pivotData.props.rows)
    const headerRow = baseRow.map((r) => r)
    if (colKeys.length === 1 && colKeys[0].length === 0) {
      headerRow.push(this.props.aggregatorName)
    } else {
      colKeys.map((c) => headerRow.push(c.join('-')))
    }
    headerRow.push('Total')

    let visibleRowKeys = []
    linesOrder.forEach((it) => {
      if (it.type === 'grouper') {
        const listFilter = rowKeys.filter((entry) => it.name === entry[0])
        if (listFilter.length > 0) {
          const lastIndex = listFilter.length - 1
          const firstItem = listFilter[lastIndex].length
          const sortedFilter = listFilter.filter((entry) => entry.length === firstItem)
          visibleRowKeys.push(...sortedFilter)
        } else {
          visibleRowKeys.push([it.name])
        }
      } else {
        visibleRowKeys.push([it.name])
      }
    })

    let lastLineTotal = {}
    if (!_.isEmpty(linesOrder)) {
      const linesTotalFilter = linesOrder.filter((it) => it.type === 'totalizer')
      if (!_.isEmpty(linesTotalFilter)) {
        const findLastTotal = _.orderBy(linesTotalFilter, 'order', 'desc')[0]
        lastLineTotal = linesTotal.find((it) => it.name === findLastTotal.name)
      }
    }

    let enabledFinancial = false
    if (!_.isEmpty(this.props.cols) && this.props.cols.length === 1) {
      enabledFinancial = this.props.cols[0] === 'Fecha'
    }

    const result = visibleRowKeys.map((r, i) => {
      return this.renderLinesRow(r, i, pivotSettings, rowKeys)
    })

    if (enabledFinancial && linesFinancial) {
      result.unshift(this.renderStartFinancial(pivotSettings, lastLineTotal))
    }
    if (enabledFinancial && linesFinancial) {
      result.push(this.renderEndFinancial(pivotSettings, lastLineTotal))
    }

    result.unshift(headerRow)

    return (
      <textarea
        value={result.map((r) => r.join('\t')).join('\n')}
        style={{ width: '100%', height: window.innerHeight / 2 }}
        readOnly={true}
      />
    )
  }
}

TSVExport.defaultProps = PivotData.defaultProps
TSVExport.propTypes = PivotData.propTypes

export default TSVExport

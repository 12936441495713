const adjustments = {
  DISTRIBUTION_MANAGMENT_LABEL: 'Distribuciones',
  ADJUSTMENT_MANAGMENT_LABEL: 'Ajustes de gestión',
  ADJUSTMENT_EMPTY_STATE_ALERT_TITLE:
    'Todavía no tenemos todos los datos que necesitamos para que puedas crear tus ajustes de gestión',
  ADJUSTMENT_EMPTY_STATE_ALERT_TEXT:
    'Para que puedas crear ajustes debemos tener datos reales publicados. Una vez que lo hagas, podrás crear todos los ajustes que consideres necesarios.',
  ADJUSTMENT_EMPTY_STATE_TITLE: '¡Creá tu primer ajuste!',
  ADJUSTMENT_EMPTY_STATE_TEXT:
    'Realizá registros que te permitan ajustar tus datos e imputar correctamente todas tus ventas y tus gastos',
  ADJUSTMENT_CREATE_ACTION: 'Crear ajuste',
  ADJUSTMENT_DATE_FIELD: 'Año/mes ajustado',
  ADJUSTMENT_TYPE_FIELD: 'Tipo de ajuste',
  ADJUSTMENT_CREATION_TYPE: 'Tipo de creación',
  ADJUSTMENT_CREATE_FORM_TITLE: 'Creación de ajuste',
  ADJUSTMENT_TYPE_EXTENSION: 'Ampliación',
  ADJUSTMENT_TYPE_RECLASSIFICATION: 'Reclasificación',
  ADJUSTMENT_TYPE_REDUCTION: 'Reducción',
  ADJUSTMENT_ADD_MOVEMENT_ACTION: 'Añadir movimiento',
  ADJUSTMENT_CREATE_CONFIRM_TITLE: 'Confirmación de creación de ajuste',
  ADJUSTMENT_CREATE_CONFIRM_DESCRIPTION:
    'Estás por crear una {{type}} en {{añoMes}}. Recordá que una vez que la hagas, quedará registrada, podrás anularla pero no eliminarla.',
  ADJUSTMENT_CREATE_SUCCESS_FEEDBACK_TITLE: 'Ajuste registrado',
  ADJUSTMENT_SUCCESS_FEEDBACK_DESCRIPTION:
    'Ya podés ver el impacto de la misma en Reales o análisis.',
  ADJUSTMENT_DETAIL_TITLE: 'Detalle de ajuste',
  ADJUSTMENT_DOWNLOAD_MOVEMENT_ACTION_TITLE: 'Descargar movimientos',

  ADJUSTMENT_EMPTY_STATE_APPROVE_DATA_TOOLTIP: 'No hay datos reales publicados',
  ADJUSTMENT_VIEW_ACTION_TITLE: 'Ver ajuste',
  ADJUSTMENT_CREATE_FAIL_FEEDBACK_DESCRIPTION: 'El ajuste no se pudo crear',
  ADJUSTMENT_LOAD_MOVEMENT_ACTION_TITLE: 'Cargar movimientos',
  ADJUSTMENT_DELETE_MOVEMENT_ACTION_TITLE: 'Eliminar movimiento',
  ADJUSTMENT_MOVEMENT_SUCCESS_FEEDBACK_TITLE: 'Los movimientos se cargaron correctamente',
  ADJUSTMENT_MOVEMENT_SUCCESS_FEEDBACK_DESCRIPTION: 'Ya podes trabajar con ellos',

  ADJUSTMENT_DATA_TABLE_FILE_NAME: 'Plika - Ajustes de gestión - {{periodName}} (listado)',
  ADJUSTMENT_LIST_DEFAULT_FILE_NAME: 'Plika - Ajustes de gestión (listado)',
  ADJUSTMENT_DEFAULT_FILE_NAME: 'Plika - Ajustes de gestión',
  ADJUSTMENT_DEFAULT_TEMPLATE_NAME: 'Plantilla Plika - Ajustes de gestión ({{date}})',
  ADJUSTMENT_MOVEMENT_FILE_NAME: 'Plantilla Plika - Ajuste de gestión Movimientos.xlsx',

  ADJUSTEMNT_TYPE_DISTRIBUTION_LABEL_ORIGIN: 'Base distribuida',
  ADJUSTEMNT_TYPE_DISTRIBUTION_LABEL_DESTINATION: 'Base a distribuir',
  ADJUSTEMNT_UPLOAD_DATA_INFORMATIVE_ALERT_DESCRIPTION:
    'En Plika consideramos a las distribuciones como un tipo de ajuste, pero como el formato del archivo y los datos a ingresar son distintos, necesitamos diferenciarlos. Por eso, elige una opción para poder obtener la plantilla correcta para cada tipo.',
  ADJUSTEMNT_DOWNLOAD_TEMPLATE_INFORMATIVE_TOOLTIP_TITLE:
    'Debes elegir un tipo de presupuesto para poder descargar',
}

export default adjustments

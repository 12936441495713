import { Input, Select, Space } from 'antd'
import { Button, Typography } from 'modules/core/components'
import { generateSelectOptions } from 'modules/core/utils'
import { useState } from 'react'
import { UpSquareOutlined, DownSquareOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const { Search } = Input

const Filters = ({ setSearchValue, setSelectTags, tagsList }) => {
  const [showFilters, setShowFilters] = useState(false)
  const { t } = useTranslation()

  return (
    <Space direction="vertical">
      <Space direction="horizontal">
        <Button.Icon
          icon={
            <Typography.Icon level={2} icon={showFilters ? UpSquareOutlined : DownSquareOutlined} />
          }
          onClick={() => setShowFilters(!showFilters)}
        />
        <Typography.Body>{t('FIELD_FILTERS')}</Typography.Body>
      </Space>
      {showFilters && (
        <Space direction="horizontal">
          <Space direction="vertical">
            <Typography.Body level={3}>{t('FIELD_NAME')}</Typography.Body>
            <Search
              className="society-search"
              placeholder={t('ACTION_SEARCH')}
              allowClear={true}
              onSearch={setSearchValue}
            />
          </Space>
          <Space direction="vertical">
            <Typography.Body level={3}>{t('FIELD_TAGS')}</Typography.Body>
            <Select
              mode="multiple"
              allowClear
              showArrow
              showSearch
              maxTagCount={1}
              style={{ width: 200 }}
              placeholder={t('ACTION_SELECT')}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={(val) => setSelectTags(val)}
              options={generateSelectOptions({ options: tagsList })}
            />
          </Space>
        </Space>
      )}
    </Space>
  )
}

export default Filters

import { COLUMNS, ROWS } from 'modules/core/constants'
import { NAME } from './constants'
import { createSelector } from 'reselect'
import i18n from 'i18next'
import moment from 'moment'
import _ from 'lodash'

const AUX_NAME_LEGENDS = {
  forecast: 'LABEL_FORECAST',
  planning: 'LABEL_PLANNING',
  real: 'LABEL_REAL',
  previous_real: 'LABERL_PREVIUS_REAL',
}

export const getForecasts = (state) => state[NAME].forecasts
export const getCutDates = (state) => state[NAME].cutDates
export const getForecastKpis = (state) => state[NAME].forecastKPIs
export const getForecastCreatable = (state) => state[NAME].forecastCreatable
export const getForecastProjections = (state) => state[NAME].projections
export const getForecastInstanceListDefault = (state) => state[NAME].forecastInstanceList
export const getForecastInstanceDetail = (state) => state[NAME].forecastInstanceDetail
export const getDataNestedTablePhase = (state) => state[NAME].dataNestedTablePhase
export const getGlobalForecastPublishApprove = (state) => state[NAME].globalForecastPublishApprove
export const getGlobalForecastEvolutionTableDefault = (state) =>
  state[NAME].globalForecastEvolutionTable
export const getGlobalForecastList = (state) => state[NAME].globalForecastList
export const getGlobalForecastComparisonTable = (state) => state[NAME].globalForecastComparisonTable
export const getGlobalForecastFilterList = (state) => state[NAME].globalForecastFilterList

export const getForecastInstanceList = createSelector(
  getForecastInstanceListDefault,
  (forecastInstanceList) => {
    return {
      ...forecastInstanceList,
      values: forecastInstanceList?.values.map((el) => {
        return {
          ...el,
          line_chart: (el.line_chart || []).map((item) => {
            return {
              id: i18n.t(AUX_NAME_LEGENDS[item.id]),
              data: item.data.map((op) => {
                return {
                  y: op.y,
                  x: moment(op.x, 'YYYY-M').format('MMM'),
                }
              }),
            }
          }),
          ranking_up_chart: (el.ranking_up_chart || []).map((el) => {
            const key = _.keys(el).filter((f) => f !== 'dimension')[0]
            return {
              dimension: key.length <= 10 ? key : `${key.slice(0, -key.length + 10)}...`,
              [key]: Math.round(el[key] * 100),
            }
          }),
        }
      }),
    }
  },
)

export const getGlobalForecastEvolutionTable = createSelector(
  getGlobalForecastEvolutionTableDefault,
  (globalBudgetEvolutionTable) => {
    const columns = globalBudgetEvolutionTable.columns.map((column) => {
      return {
        ...column,
        ...(!column.is_transaction && {
          width:
            column.data_index === COLUMNS.TOTAL_DATA_INDEX
              ? COLUMNS.WIDTH.TRANSACTION
              : COLUMNS.WIDTH.EXPANDABLE,
        }),
        ...(column.is_transaction && {
          width: COLUMNS.WIDTH.TRANSACTION,
        }),
      }
    })

    const data = {
      ...globalBudgetEvolutionTable,
      columns,
    }

    return data
  },
)

export const getGlobalForecastChartDefault = (state) => state[NAME].globalForecastChart

export const getGlobalForecastChart = createSelector(
  getGlobalForecastChartDefault,
  (globalForecastChart) => {
    const horizontalAxis = globalForecastChart.columns
      .filter((column) => column.is_transaction && column.title !== 'AV')
      .map((column) => column.title)

    const maxReportElement = !globalForecastChart.lineId
      ? globalForecastChart.rows
          .filter((elem) => elem.type === ROWS.TOTALIZER)
          .reduce((prev, current) => (prev.y > current.y ? prev : current), {})
      : globalForecastChart.rows.find((data) => data.id === globalForecastChart.lineId)

    const valuesAxisY = horizontalAxis.map((period) => {
      const yValue = maxReportElement[period]
      return {
        x: moment(period, 'YYYY-M').format('MMM'),
        y: yValue ? yValue : '0',
      }
    })

    return {
      id: i18n.t('LABEL_REAL'),
      data: valuesAxisY.asMutable({ deep: true }),
    }
  },
)

export const getGlobalForecastDetail = (state) => state[NAME].globalForecastDetail
export const getDateBlockingByForecast = (state) => state[NAME].dateBlocking

// TODO: NEW ENDPOINTS FOR REVAMP TABLE
export const getDataEvolutionForecastReport = (state) => state[NAME].dataEvolutionForecastReport
export const getDataComparativeForecastReport = (state) => state[NAME].dataComparativeForecastReport
export const getDataRdnPercentageForecast = (state) => state[NAME].dataRdnPercentageForecast
export const getForecastFilterDates = (state) => state[NAME].forecastFilterDates

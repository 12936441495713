import { DropdownMenu, LineChart, PieChart, Typography } from 'modules/core/components'
import { CHARTS } from 'modules/core/constants'
import { executeExportAsImage } from 'modules/core/utils'
import { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ExportOutlined } from '@ant-design/icons'
import { Card, Col, Space } from 'antd'
import numeral from 'numeral'

const ChartsSac = ({ dataLineChartSac, dataPieChartSac }) => {
  const lineChartRef = useRef(null)
  const pieChartRef = useRef(null)
  const { t } = useTranslation()

  const onExportChart = useCallback((props) => {
    executeExportAsImage({ ...props })
  }, [])

  const renderExtraActions = (chartRef, title) => {
    return (
      <DropdownMenu
        title={t('ACTION_MORE')}
        placement="bottomRight"
        menu={[
          {
            title: t('ACTION_EXPORT'),
            icon: <ExportOutlined />,
            submenu: CHARTS.EXPORT_FORMAT_TYPE_OPTIONS.map((formatType) => {
              return {
                title: formatType.name,
                key: formatType.id,
                onClick: () =>
                  onExportChart({
                    chartRef: chartRef.current,
                    format: formatType.id,
                    onExport: formatType.onAction,
                    props: formatType.props,
                    title: t(title),
                  }),
              }
            }),
          },
        ]}
      />
    )
  }

  return (
    <>
      <Col span={24}>
        <div ref={lineChartRef}>
          <Card
            title={t('PLANNING_SAC_LINE_CHART_TITLE')}
            headStyle={{ height: 56 }}
            extra={renderExtraActions(lineChartRef, 'PLANNING_SAC_LINE_CHART_TITLE')}
          >
            <LineChart
              containerHeight={300}
              data={dataLineChartSac.asMutable({ deep: true })}
              colors={CHARTS.COLORS}
              axisLeft={{
                format: (value) => `$${numeral(value).format('0,0[.]0 a')}`,
              }}
              value={dataLineChartSac.map((val) => val)}
            />
          </Card>
        </div>
      </Col>
      <Col span={12}>
        <div ref={pieChartRef}>
          <Card
            title={t('PLANNING_SAC_PIE_CHART_TITLE')}
            headStyle={{ height: 56 }}
            extra={renderExtraActions(pieChartRef, 'PLANNING_SAC_PIE_CHART_TITLE')}
          >
            <PieChart
              containerHeight={300}
              legends={{}}
              data={dataPieChartSac.asMutable({ deep: true })}
              tooltip={(data) => {
                return (
                  <div
                    style={{
                      width: 200,
                      backgroundColor: 'white',
                      padding: 10,
                      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
                    }}
                  >
                    <Space direction="vertical">
                      <div
                        style={{ width: 12, height: 12, backgroundColor: data.datum.color }}
                      ></div>
                      <Typography.Body>{`${t('LABEL_AREA')}: ${data.datum.label}`}</Typography.Body>
                      <Typography.Body>{`${t('FIELD_COUNT')}: ${
                        data.datum.value
                      }`}</Typography.Body>
                      <Typography.Body>{`${t('FIELD_AMOUNT')}: $${numeral(
                        data.datum.data.amount,
                      ).format('0,0[.]0 a')}`}</Typography.Body>
                      <Typography.Body>
                        {`${t('FIELD_PERCENTAGE')}: ${numeral(data.datum.data.percentage).format(
                          '0,0.00',
                        )}%`}{' '}
                      </Typography.Body>
                    </Space>
                  </div>
                )
              }}
            />
          </Card>
        </div>
      </Col>
    </>
  )
}

export default ChartsSac

import { Col, InputNumber, Slider } from 'antd'
import { Typography } from 'modules/core/components'

const SlidersInputs = ({ sliderValue, setSliderValue, name, type, disabled }) => {
  return (
    <>
      <Col span={16}>
        <Typography.Body level={3}>{name}</Typography.Body>
        <Slider
          min={-100}
          max={100}
          style={{ width: '100%' }}
          disabled={disabled}
          onChange={(val) =>
            setSliderValue((prevState) => {
              return { ...prevState, [type]: val }
            })
          }
          value={sliderValue[type]}
        />
      </Col>
      <Col span={8}>
        <InputNumber
          min={-100}
          max={100}
          style={{ margin: '0 16px' }}
          value={sliderValue[type]}
          formatter={(value) => `${value}%`}
          disabled={disabled}
          onChange={(val) =>
            setSliderValue((prevState) => {
              return { ...prevState, [type]: val }
            })
          }
        />
      </Col>
    </>
  )
}

export default SlidersInputs

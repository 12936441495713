import { Input, List, Modal, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import Button from '../../Button/Button'
import Typography from '../../Typography/Typography'
import { handleSavePrimary } from 'modules/control/containers/ControlDetail/components/Evolution/utils'
import _ from 'lodash'
import {
  DeleteOutlined,
  EditOutlined,
  PushpinFilled,
  PushpinOutlined,
  CloseOutlined,
  SaveOutlined,
} from '@ant-design/icons'

const FavouriteEditModal = ({
  visible,
  onClose,
  dataSource,
  viewSelect,
  setIsSaving,
  onSavePrimary,
  onRefetch,
  handleDelete,
  handleEditName,
  showEdit,
  setShowEdit,
  rowId,
  setRowId,
  newName,
  setNewName,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      title={t('FAVOURITE_LIST_MODAL_TITLE')}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      visible={visible}
      onCancel={onClose}
      footer={<Button.Primary title="ACTION_RETURN" onClick={() => onClose()} />}
    >
      <List
        dataSource={dataSource}
        renderItem={(item, index) => {
          return (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {showEdit && rowId === index ? (
                      <Input
                        defaultValue={item.name}
                        placeholder={t('ACTION_ENTER')}
                        style={{ marginRight: 10 }}
                        onChange={(val) => setNewName(val.target.value)}
                      />
                    ) : (
                      item.name
                    )}
                    <Space direction="horizontal">
                      {(!showEdit || rowId !== index) && (
                        <Button.Icon
                          title={
                            !_.isEmpty(viewSelect) && item.primary
                              ? t('FIELD_PREDETERMINED')
                              : t('ACTION_PREDETERMINED_PIN_UP')
                          }
                          icon={
                            <Typography.Icon
                              icon={
                                !_.isEmpty(viewSelect) && item.primary
                                  ? PushpinFilled
                                  : PushpinOutlined
                              }
                            />
                          }
                          style={
                            !_.isEmpty(viewSelect) && item.primary ? { cursor: 'default' } : {}
                          }
                          onClick={() =>
                            !_.isEmpty(viewSelect) &&
                            !item.primary &&
                            handleSavePrimary({
                              setIsLoading: setIsSaving,
                              createLayout: () => onSavePrimary(item),
                              onRefetch,
                              onClose: () => setIsSaving(false),
                            })
                          }
                        />
                      )}
                      {showEdit && rowId === index ? (
                        <>
                          <Button.Icon
                            title="ACTION_CANCEL"
                            icon={<Typography.Icon icon={CloseOutlined} />}
                            onClick={() => {
                              setShowEdit(false)
                              setRowId(null)
                            }}
                          />
                          <Button.Icon
                            title="ACTION_SAVE"
                            icon={<Typography.Icon icon={SaveOutlined} />}
                            onClick={() => handleEditName(item)}
                            disabled={!newName || dataSource.find((el) => el.name === newName)}
                            tooltipTitle={
                              dataSource.find((el) => el.name === newName)
                                ? t('ALREADY_EXISTS_NAME_TOOLTIP_TITLE')
                                : ''
                            }
                          />
                        </>
                      ) : (
                        <Button.Icon
                          title="ACTION_EDIT"
                          icon={<Typography.Icon icon={EditOutlined} />}
                          onClick={() => {
                            setShowEdit(true)
                            setRowId(index)
                          }}
                        />
                      )}
                      {(!showEdit || rowId !== index) && (
                        <Button.Icon
                          title="ACTION_DELETE"
                          icon={<Typography.Icon icon={DeleteOutlined} />}
                          onClick={() => handleDelete(item)}
                        />
                      )}
                    </Space>
                  </div>
                }
              />
            </List.Item>
          )
        }}
      />
    </Modal>
  )
}

export default FavouriteEditModal

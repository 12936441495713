import { Button, ButtonActionBar, DeleteSelectionButton, Typography } from 'modules/core/components'
import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Popconfirm, Space, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'

const TableActions = ({
  dataSelection,
  onConfirmDelete,
  disabled,
  isDeleting,
  onClickDownload,
  onClickMap,
  buttonLoading,
  disableButton,
  onConfirmApply,
  onCancelApply,
  visible,
}) => {
  const { t } = useTranslation()
  return (
    <ButtonActionBar position={dataSelection.length > 0 ? 'space-between' : 'end'}>
      {dataSelection.length > 0 && (
        <DeleteSelectionButton
          onConfirm={onConfirmDelete}
          disabled={disabled}
          isDeletingAll={isDeleting}
        />
      )}
      <Space direction="horizontal">
        <Tooltip title={t('CONFIG_DIMENSIONS_VALUES_MAP_APPLY_INFORMATIVE_TOOLTIP_TITLE')}>
          <Typography.Icon icon={InfoCircleOutlined} />
        </Tooltip>
        <Popconfirm
          placement="bottomRight"
          title={
            <div style={{ width: 300 }}>
              {t('CONFIG_DIMENSIONS_VALUES_MAP_CONFIRM_APPLY_POPCONFIRM_TITLE')}
            </div>
          }
          okText={t('YES')}
          onConfirm={onConfirmApply}
          cancelText={t('NO')}
          onCancel={onCancelApply}
          visible={visible}
        >
          <Button.Default
            size="small"
            loading={buttonLoading}
            disabled={disableButton}
            title="CONFIG_DIMENSIONS_VALUES_MAP_APPLY_ACTION_BUTTON_TITLE"
            onClick={onClickMap}
            tooltipTitle={
              disableButton
                ? t('CONFIG_DIMENSIONS_VALUES_APPLY_INFORMATIVE_DISABLED_TOOLTIP_TITLE')
                : ''
            }
          />
        </Popconfirm>
        <Button.Icon
          title="ACTION_DOWNLOAD_TABLE"
          icon={<Typography.Icon icon={DownloadOutlined} />}
          onClick={onClickDownload}
        />
      </Space>
    </ButtonActionBar>
  )
}

export default TableActions

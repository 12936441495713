import React from 'react'
import { ReportLineChart } from 'modules/reports/components'
import { ChartTitle } from './components'
import { setChartTitle, setReportLineName } from './utils'

const ReportChart = ({
  rows,
  lineId,
  selectedDates,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  commentKey,
  currencyData,
  dataChart,
  props = {},
}) => {
  return (
    <ReportLineChart
      title={<ChartTitle rows={rows} lineId={lineId} selectedDates={selectedDates} />}
      chartTitle={`${setChartTitle(selectedDates)} ${setReportLineName({ rows, lineId })}`}
      data={dataChart}
      setDataComments={setDataComments}
      dataComments={dataComments}
      onRefetchComments={onRefetchComments}
      loadingComments={loadingComments}
      commentKey={commentKey}
      currencyData={currencyData}
      props={props}
      lineId={lineId}
    />
  )
}

export default ReportChart

import { Navigator } from 'modules/core/components'
import { SiderLayout } from 'modules/core/layouts'
import { useState } from 'react'
import Skeleton from '../configuration/containers/Configuration/components/Skeleton'

export const FRONT_URL = process.env.REACT_APP_FRONT_URL || 'https://next.stg.getplika.com'

const MonitorScreen = () => {
  const [loadingIframe, setLoadingIframe] = useState(false)

  const handleLoad = () => {
    console.log('Iframe loaded')
    setLoadingIframe(false)
  }

  return (
    <SiderLayout className={'dashboard-home-iframe'}>
      <Navigator
        floated={false}
        size="big"
        items={[{ name: 'Monitor' }]}
        style={{ marginLeft: 25, marginTop: 20, marginBottom: 10 }}
      />
      {loadingIframe && <Skeleton active loading={loadingIframe} paragraph={{ rows: 10 }} />}
      <iframe
        id="workflow"
        title="Workflow"
        src={`${FRONT_URL}/iframe/workflow/monitor/`}
        width="100%"
        height="100%"
        style={{
          border: 'none',
          padding: '0 0',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
        onLoad={handleLoad}
      ></iframe>
    </SiderLayout>
  )
}

export default MonitorScreen

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { List } from 'antd'
import { ContainerButtonFix, ProjectionCard } from './components'
import { useTranslation } from 'react-i18next'
import { isApproved } from 'modules/core/utils'
import { onSetDefaultProjection } from './utils'
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import { DragOverlay } from '@dnd-kit/core'
import { useState } from 'react'

const isGlobal = (type) => type === 'globalBudget' || type === 'globalForecast'

const ProjectionCardList = ({
  dataSource,
  type = 'global',
  conceptId,
  role,
  onOpen,
  onOpenActivities = null,
  reloadList,
  deleteProjection,
  editProjectionName,
  setDefaultProjection,
  duplicateCard,
  activeId,
  loading,
  allDimensionsList,
  setDimensionId,
  changeCardColor,
  module,
  conceptName,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
}) => {
  const enabledRevampCard = useFeatureIsOn('feature-revamp-card')
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()

  const findCard = activeId && dataSource.find((el) => el.id === activeId)

  const renderFixGlobalBudget = ({ isFixed, setFixed, status }) => (
    <ContainerButtonFix isFixed={isFixed} setFixed={setFixed} status={status} />
  )

  const getNumberCard = () => {
    if (isGlobal(type)) {
      return 3
    }
    if (enabledRevampCard) {
      return 1
    }
    return 3
  }

  return (
    <>
      <SortableContext items={dataSource} strategy={rectSortingStrategy}>
        <List
          style={{ paddingLeft: 6 }}
          grid={{
            gutter: 24,
            xs: 1,
            sm: 1,
            md: 1,
            lg: 1,
            xl: getNumberCard(type),
            xxl: getNumberCard(type),
          }}
          dataSource={dataSource}
          loading={loading || isSaving}
          renderItem={(projecton) => {
            const {
              id: projectionId,
              name,
              step,
              is_closing = false,
              predefined,
              status,
              amount,
              dimension,
              line_chart,
              ranking_up_chart,
              color,
              budgets_ids,
            } = projecton
            return (
              <List.Item key={projectionId}>
                <ProjectionCard
                  budgetId={projectionId}
                  title={t(name)}
                  conceptId={conceptId}
                  kpi={amount}
                  type={type}
                  role={role}
                  status={step || status}
                  onOpen={() => onOpen(projectionId)}
                  onOpenActivities={
                    onOpenActivities ? () => onOpenActivities(projectionId, step) : null
                  }
                  reloadList={reloadList}
                  is_closing={is_closing}
                  onDeleteBudget={deleteProjection}
                  onEditName={editProjectionName}
                  extraFooter={
                    isGlobal(type)
                      ? renderFixGlobalBudget({
                          isFixed: predefined,
                          setFixed:
                            dataSource.filter((globalBudget) => isApproved(globalBudget?.status))
                              .length === 0
                              ? () =>
                                  onSetDefaultProjection({
                                    setDefaultProjection: () => setDefaultProjection(projectionId),
                                    onRefetch: reloadList,
                                  })
                              : null,
                          status,
                        })
                      : null
                  }
                  duplicateCard={duplicateCard}
                  allDimensionsList={allDimensionsList}
                  dataLineChart={!isGlobal(type) ? line_chart.asMutable({ deep: true }) : []}
                  dataRankingUp={!isGlobal(type) ? ranking_up_chart : []}
                  dimension={dimension}
                  setDimensionId={setDimensionId}
                  defaultColor={color}
                  changeCardColor={changeCardColor}
                  module={module}
                  budgets_ids={budgets_ids}
                  conceptName={conceptName}
                  setDataComments={setDataComments}
                  dataComments={dataComments}
                  onRefetchComments={onRefetchComments}
                  loadingComments={loadingComments}
                  setIsSaving={setIsSaving}
                />
              </List.Item>
            )
          }}
        />
      </SortableContext>
      <DragOverlay adjustScale>
        {activeId ? (
          <ProjectionCard
            budgetId={findCard.id}
            title={t(findCard.name)}
            conceptId={conceptId}
            kpi={findCard.amount}
            type={type}
            role={role}
            status={findCard.step || findCard.status}
            onOpen={() => onOpen(findCard.id)}
            onOpenActivities={() => onOpenActivities(findCard.id, findCard.step)}
            reloadList={reloadList}
            is_closing={findCard.is_closing}
            onDeleteBudget={deleteProjection}
            onEditName={editProjectionName}
            extraFooter={
              isGlobal(type)
                ? renderFixGlobalBudget({
                    isFixed: findCard.predefined,
                    setFixed:
                      dataSource.filter((globalBudget) => isApproved(globalBudget?.status))
                        .length === 0
                        ? () =>
                            onSetDefaultProjection({
                              setDefaultProjection: () => setDefaultProjection(findCard.id),
                              onRefetch: reloadList,
                            })
                        : null,
                    status: findCard.status,
                  })
                : null
            }
            duplicateCard={duplicateCard}
            allDimensionsList={allDimensionsList}
            dataLineChart={!isGlobal(type) ? findCard.line_chart.asMutable({ deep: true }) : []}
            dataRankingUp={!isGlobal(type) ? findCard.ranking_up_chart : []}
            dimension={findCard.dimension}
            setDimensionId={setDimensionId}
            defaultColor={findCard.color}
            changeCardColor={changeCardColor}
            module={module}
            budgets_ids={findCard.budgets_ids}
            conceptName={conceptName}
            setDataComments={setDataComments}
            dataComments={dataComments}
            onRefetchComments={onRefetchComments}
            loadingComments={loadingComments}
            setIsSaving={setIsSaving}
          />
        ) : null}
      </DragOverlay>
    </>
  )
}

export default ProjectionCardList

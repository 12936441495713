import DropdownMenu from 'modules/core/components/DropdownMenu/DropdownMenu'
import { TABLES } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'

const DowloadTemplate = ({ type, onClick, onClickSpreadsheets }) => {
  const { t } = useTranslation()

  const dowload = {
    spreadsheets: onClickSpreadsheets,
    file: onClick,
  }

  return (
    <div className="upload-moda-help">
      {t('INFO_UPLOAD_FILE_MODAL_DESCRIPTION')}{' '}
      <DropdownMenu
        title={t('ACTION_DOWNLOAD_TABLE')}
        placement="bottomRight"
        menu={TABLES.OPTIONS_DOWNLOAD.map((formatType) => {
          return {
            title: formatType.name,
            key: formatType.id,
            onClick: dowload[formatType.id],
          }
        }).filter((el) => (type === 'PATRIMONIAL' ? el.key === 'file' : el))}
      >
        <span>{t('INFO_UPLOAD_FILE_DOWNLOAD_ACTION')}</span>
      </DropdownMenu>
    </div>
  )
}

export default DowloadTemplate

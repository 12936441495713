import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Spin, Row, Col, Space, Image } from 'antd'
import { Navigator, Typography } from 'modules/core/components'
import { SiderLayout } from 'modules/core/layouts'
import { ConfigUserDrawer, TabsData, UploadImgModal } from './components'
import barChartImage from 'assets/images/compositions/barChart.svg'
import configuration from 'modules/configuration'
import login from 'modules/login'

import './UserDetail.scss'
import _ from 'lodash'

const UserDetail = ({
  loggedUser: {
    password_confirmed_google,
    password_confirmed_azure,
    totp_enabled,
    society_id,
    pkid,
  },
  fetchAreasList,
  areaList,
  rolesList,
  fetchRolesList,
  fetchUserProfile,
  userProfile,
  userDetail,
  detailUser,
}) => {
  const [isScreenLoading, setIsScreenLoading] = useState(true)
  const [showDrawer, setShowDrawer] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()
  let { userId, myProfile } = useParams()

  useEffect(() => {
    fetchUserProfile()
  }, [fetchUserProfile])

  useEffect(() => {
    fetchAreasList()
  }, [fetchAreasList])

  useEffect(() => {
    fetchRolesList({ society: society_id })
  }, [fetchRolesList, society_id])

  useEffect(() => {
    if (userId) {
      userDetail(userId).then(() => setIsScreenLoading(false))
    }
  }, [userDetail, userId])

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={
        myProfile
          ? [{ name: t('LABEL_PROFILE') }]
          : [
              {
                name: t('LABEL_CONFIG'),
                url: `/configuracion`,
              },
              {
                name: t('LABEL_USERS'),
                url: '/configuracion/seguridad/?tab=users',
              },
              {
                name: t('LABEL_DETAIL'),
              },
            ]
      }
    />
  )

  const onRefetch = () => {
    Promise.all([fetchUserProfile(), userDetail(userId)])
  }

  return (
    <SiderLayout className="padding">
      <Spin spinning={isScreenLoading} />
      {!isScreenLoading && !_.isEmpty(detailUser) && (
        <>
          <div className="container-top">
            {renderHeader()}
            <div className="container-elements">
              <Space direction="horizontal">
                <div className="container-global-img">
                  {userProfile.photo && pkid === detailUser.id ? (
                    <Image className="photo" src={userProfile?.photo} />
                  ) : (
                    <div className="container-img">
                      {detailUser?.last_name[0]}
                      {detailUser?.first_name[0]}
                    </div>
                  )}
                </div>
                <Space direction="vertical">
                  <Typography.Headline level={4} type="primary">
                    {`${detailUser?.first_name} ${detailUser?.last_name}`}
                  </Typography.Headline>
                  <Typography.Body>{detailUser?.email}</Typography.Body>
                </Space>
              </Space>
              <img width={240} src={barChartImage} alt="imagen grafico barras" />
            </div>
          </div>
          <Row gutter={[4, 4]}>
            <Col span={24}>
              <TabsData
                pkid={pkid}
                userDetail={detailUser}
                rolesList={rolesList}
                areaList={areaList}
                userProfile={userProfile}
                setShowUploadModal={setShowUploadModal}
                society_id={society_id}
                onRefetch={onRefetch}
                setShowDrawer={setShowDrawer}
                setIsSaving={setIsSaving}
                isSaving={isSaving}
              />
            </Col>
          </Row>
          <ConfigUserDrawer
            visible={showDrawer}
            onClose={() => setShowDrawer(false)}
            is_configured_google={password_confirmed_google}
            is_configured_azure={password_confirmed_azure}
            is_configured_qrCode={totp_enabled}
          />
          <UploadImgModal
            visible={showUploadModal}
            onClose={() => setShowUploadModal(false)}
            onRefetch={onRefetch}
          />
        </>
      )}
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
  usersList: configuration.selectors.getUsersList(state),
  areaList: configuration.selectors.getAreaList(state),
  rolesList: configuration.selectors.getRolesList(state),
  userProfile: configuration.selectors.getUserProfile(state),
  detailUser: configuration.selectors.getUserDetail(state),
})

const mapDispatchToProps = {
  fetchUsersList: configuration.actions.fetchUsersList,
  fetchAreasList: configuration.actions.fetchAreasList,
  fetchRolesList: configuration.actions.fetchRolesList,
  fetchUserProfile: configuration.actions.fetchUserProfile,
  editProfile: configuration.actions.editProfile,
  editUser: configuration.actions.editUser,
  assignRole: configuration.actions.assignRole,
  userDetail: configuration.actions.userDetail,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail)

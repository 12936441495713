const configuration = {
  //xls names
  CONFIG_USERS_TEMPLATE: 'Plantilla Plika - Configuración Usuarios.xlsx',
  CONFIG_CONCEPTS_TEMPLATE: 'Plantilla Plika - Configuración Conceptos.xlsx',
  CONFIG_DIMENSIONS_VALUES_TEMPLATE_NAME_DEFAULT:
    'Plantilla Plika - Configuración Dimension (valores) {{date}}.xlsx',
  CONFIG_ACCOUNTS_STRUCTURE_FILE_NAME: 'Plika - Configuración Plan de cuentas (estructura).xlsx',
  CONFIG_DIMENSIONS_VALUES_FILE_NAME: 'Plika - Configuración dimensión (valores) ({{date}}).xlsx',
  CONFIG_USERS_FILE_NAME: 'Plika - Configuración Usuarios (listado).xlsx',
  CONFIG_ACCOUNT_CONCEPT_ASSOCIATION_TEMPLATE_NAME: 'Plantilla Plika - asociación cuenta-concepto',
  CONFIG_CONCEPTS_FILE_NAME:
    'Plika - Configuración Conceptos {{conceptType}} ({{conceptName}}).xlsx',
  CONFIG_DIMENSIONS_FILE_NAME: 'Plika - Configuración Dimensiones ({{date}}).xlsx',
  CONFIG_USER_LIST_FILE_NAME: 'Plika - Configuración Lista de Usuarios - ({{date}})',
  CONFIG_REPORT_STRUCTURE_FILE_NAME: 'Plika - Configuración Estructura de Reportes - ({{date}})',

  // General
  CONFIG_CARD_EMPTY_STATE_TEXT: 'Ingresá y comenzá a crear o cargar {{config}}',
  CONFIG_SETTINGS_MODAL_TITLE: 'Preferencias',
  CONFIG_SETTINGS_MODAL_TEXT: '¿Cómo deseas que tu equipo visualice la dimensión {{dimension}}?',
  CONFIG_SETTINGS_MODAL_ALERT:
    '¡Importante! Te contamos que, lo que definas acá como preferencia impactará en la visualización de todo el producto.',
  CONFIG_SETTINGS_OPTION_BOTH: 'Por código y descripción.',
  CONFIG_SETTINGS_OPTION_CODE: 'Por código solamente.',
  CONFIG_SETTINGS_OPTION_NAME: 'Por descripción solamente.',
  CONFIG_CARD_DISABLED_INFORMATIVE_ICON_TOOLTIP_TITLE_1:
    'Primero debes cargar un archivo para poder ver informacion. Dirigete al Home',
  CONFIG_CARD_DISABLED_INFORMATIVE_ICON_TOOLTIP_TITLE_2: 'para cargarlo',

  //Conceptos
  CONFIG_CONCEPTS_UPLOAD_MODAL_TITLE: 'Cargar conceptos',
  CONFIG_CONCEPTS_EMPTY_DIENSIONS_TITLE: 'Aún no hay dimensiones asociadas',
  CONFIG_CONCEPT_ASSOCIATE_DIMENSION_ACTION: 'Asociar dimensión',
  CONFIG_CONCEPT_ASSOCIATE_DIMENSION_MODAL_TITLE: 'Asociar dimensión a concepto',
  CONFIG_CONCEPT_ASSOCIATE_TO_DIMENSION_FIELD: 'Dimensión/es a asociar',
  CONFIG_CONCEPT_ASSOCIATE_MODAL_SELECT_LABEL:
    'Seleccioná la/las dimensiones que deseas utilizar para presupuestar',
  CONFIG_CONCEPT_ASSOCIATE_REMOVE_TITLE: '¿Estás seguro que deseas quitar la dimensión "{{name}}"?',
  CONFIG_FIELD_ECONOMIC_CONCEPTS: 'Conceptos económicos',
  CONFIG_FIELD_FINANCIAL_CONCEPTS: 'Conceptos financieros',
  CONFIG_CONCEPT_ADD_ACTION: 'Añadir otro concepto',
  CONFIG_CONCEPT_POPCONFIRM_DELETE_TITLE: '¿Estás seguro que deseas eliminar este concepto?',
  CONFIG_CONCEPT_EMPTY_STATE_CREATE_ACTION: 'Crear concepto',

  //Dimensiones
  CONFIG_DIMENSION_VALUES_UPLOAD_MODAL_TITLE: 'Cargar valores de dimensión',
  CONFIG_DIMENSIONS_EMPTY_STATE_CREATE_ACTION: 'Crear dimensión',
  CONFIG_DIMENSIONS_TOOLTIP_INFO_TITLE:
    'Las acciones que realices sobre las dimensiones asociadas no impactarán sobre los períodos anteriores al vigente',
  CONFIG_DIMENSIONS_POPCONFIRM_DELETE_DIMENSION_TITLE:
    '¿Estás seguro que deseas eliminar esta dimensión?',
  CONFIG_DIMENSIONS_INPUT_NAME_PLACEHOLDER: 'Ej. Sucursal',
  CONFIG_DIMENSIONS_DELETE_ACTION: 'Eliminar dimensión',
  CONFIG_DIMENSIONS_ADD_NEW_ACTION: 'Añadir otra dimensión',
  CONFIG_DIMENSIONS_VALUE_ADD_NEW_ACTION: 'Añadir otro valor de dimensión',
  CONFIG_DIMENSIONS_VALUES_POPCONFIRM_DELETE_VALUE_TITLE:
    '¿Estás seguro que deseas eliminar este valor de dimensión?',
  CONFIG_DIMENSIONS_VALUES_MODAL_CREATE_NEW_VALUE_TITLE: 'Crear valor de dimensión',
  CONFIG_DIMENSIONS_VALUES_INPUT_CODE_PLACEHOLDER: 'Ej. LP',
  CONFIG_DIMENSIONS_VALUES_INPUT_NAME_PLACEHOLDER: 'Ej. La Plata',
  CONFIG_DIMENSIONS_PROCESS_DATA_EMPTY_SCREEN_DESCRIPTION:
    'La información aún se está procesando.Una vez finalizado podras ver en esta pantalla tus datos cargados.',
  CONFIG_DIMENSIONS_VALUES_MANAGEMENT_INFORMATIVE_ICON_TOOLTIP_TITLE:
    'Aqui verás/asignarás el nombre que tomará tu información en Plika. Tranquilo!, Esto no modificará el nombre que viene de origen.',
  CONFIG_DIMENSIONS_GROUP_DATA_MODAL_TITLE: 'Agrupar datos',
  CONFIG_DIMENSIONS_VALUES_SELECTED_FROM_SELECT_LABEL: 'Valores seleccionados',
  CONFIG_DIMENSIONS_TYPE_CREATION_NEW_SELECT_OPTION: 'Nueva dimensión',
  CONFIG_DIMENSIONS_CHOOSE_TYPE_CREATION_FORM_RADIO_LABEL: 'Elige que dimensión queres crear',
  CONFIG_DIMENSIONS_VALUES_MAP_DATA_MODAL_TITLE: 'Mapear datos',
  CONFIG_DIMENSIONS_VALUES_SELECT_FROM_SELECT_LABEL: 'Seleccioná los valores de dimensión a mapear',
  CONFIG_DIMENSIONS_VALUES_SUBTITLE_1_MAP_DATA_MODAL_TEXT: 'Crea un nuevo dato de {{type}}',
  CONFIG_DIMENSIONS_VALUES_SUBTITLE_2_MAP_DATA_MODAL_TEXT: 'Selecciona un dato {{type}} ya creado',
  CONFIG_DIMENSIONS_VALUES_SELECT_DESTINATION_VALUES_FROM_SELECT_LABEL:
    'Seleccioná datos de {{type}}',
  CONFIG_DIMENSIONS_VALUES_DESTINATION_KPI_LABEL: 'Valores gestión',
  CONFIG_DIMENSIONS_VALUES_MANAGEMENT_LABEL: 'Gestión',
  CONFIG_DIMENSIONS_VALUES_ORIGIN_KPI_LABEL: 'Valores origen',
  CONFIG_DIMENSIONS_VALUES_MAP_KPI_LABEL: 'Valores sin mapear',
  CONFIG_DIMENSIONS_VALUES_MAP_FINISH_ACTION_BUTTON_TITLE: 'Finalizar mapeo',
  CONFIG_DIMENSION_VALUES_INFORMATIVE_MAP_ALL_VALUES_TOOLTIP_TITLE:
    'Debes mapear todos los datos para poder finalizar la acción',
  CONFIG_DIMENSION_VALUES_INFORMATIVE_MAP_ALL_VALUES_ALERT_DESCRIPTION:
    'Para poder visualiza la información en la tabla de seguimiento debes mapear todos los valores de dimensión',
  CONFIG_DIMENSIONS_VALUES_MAP_APPLY_ACTION_BUTTON_TITLE: 'Aplicar mapeo',
  CONFIG_DIMENSIONS_VALUES_MAP_START_ACTION_BUTTON_TITLE: 'Iniciar mapeo',
  CONFIG_DIMENSIONS_VALUES_MAP_APPLY_INFORMATIVE_TOOLTIP_TITLE:
    'Una vez termines el mapeo de tu datos debes aplicar estos cambios para que los puedas visualizar en la herramienta',
  CONFIG_DIMENSIONS_VALUES_MAP_START_INFORMATIVE_TOOLTIP_TITLE:
    'Al clickear este boton habilitas la posibilidad de mapear tus datos',
  CONFIG_DIMENSIONS_VALUES_MAP_CONFIRM_APPLY_POPCONFIRM_TITLE:
    'Estas por aplicar las modificaciones realizadas. Una vez que lo hagas se disparará un proceso que hará que puedas ver dichos cambios en la tabla de seguimiento. No te preocupes, podras modificar estos datos nuevamente. ¿Estás seguro que deseas aplicarlo ahora?',
  CONFIG_DIMENSIONS_VALUES_APPLY_INFORMATIVE_DISABLED_TOOLTIP_TITLE:
    'Es necesario mapear todos los valores de dimensión antes de aplicar. Además, si se ejecuta una aplicación y no se realiza un nuevo mapeo de datos, quedará deshabilitado',

  CONFIG_DIMENSIONS_FIELD_ORIGIN_CODE: 'Código origen',
  CONFIG_DIMENSIONS_FIELD_DESTINATION_CODE: 'Código Plika',
  CONFIG_DIMENSIONS_FIELD_ORIGIN_NAME: 'Nombre origen',
  CONFIG_DIMENSIONS_FIELD_DESTINATION_NAME: 'Nombre Plika',
  CONFIG_DIMENSIONS_EDIT_VALUES: 'Editar dimensiones',
  CONFIG_DIMENSIONS_TOTAL_DIMENSIONS: 'Total dimensiones',
  CONFIG_DIMENSIONS_TOTAL_VALUES: 'Total valores',

  //Reportes
  CONFIG_REPORTS_EMPTY_STATE_TITLE: '¡Creá tu primer reporte!',
  CONFIG_REPORTS_GROUPING_FIELD: 'Líneas agrupadoras',
  CONFIG_REPORTS_TOTALIZING_FILED: 'Líneas totalizadoras',
  CONFIG_REPORTS_TYPE: 'Tipo de reporte',
  CONFIG_REPORT_STRUCTURE_FIELD: 'Estructura',
  CONFIG_REPORT_DELETE_TITLE: '¿Estás seguro que deseas eliminar este reporte?',
  CONFIG_REPORT_CREATE_ACTION: 'Crear reporte',
  CONFIG_REPORT_CREATE_MODAL_NAME_LABEL: 'Ingresá un nombre para el reporte que deseas crear',
  CONFIG_REPORT_CREATE_MODAL_NAME_PLACEHOLDER: 'Ej. EERR - Reporte Directorio',
  CONFIG_REPORT_DATA_UPLOAD_MODAL_TITLE: 'Cargar datos reporte',
  CONFIG_REPORT_TOTAL_REPORTS: 'Total reportes',
  CONFIG_REPORT_TOTAL_ECONOMIC: 'Reportes económicos',
  CONFIG_REPORT_TOTAL_FINANCIAL: 'Reportes financieros',
  CONFIG_REPORT_ECONOMIC: 'Económico',
  CONFIG_REPORT_FINANCIAL: 'Financiero',
  CONFIG_REPORT_PATRIMONIAL: 'Patrimonial',
  CONFIG_REPORT_TOTAL_PATRIMONIAL: 'Total patrimonial',

  //otros reportes
  CONFIG_OTHER_REPORT_CREATE_MODAL_NAME_PLACEHOLDER: 'Ej. Ventas por oficina',
  CONFIG_OTHER_REPORT_SELECT_REPORT_LABEL: 'Elegí un reporte',
  CONFIG_OTHER_REPORT_SELECT_DIMENSION_LABEL:
    'Elegí la dimensión por la que deseas visualizar tu reporte',

  //Usuarios
  CONFIG_USERS_EMPTY_STATE_TITLE: 'Comenzá la creación de los usuarios',
  CONFIG_USER_COMPANY_POSITION_FIELD: 'Cargo en la empresa',
  CONFIG_USERS_TOOLTIP_INFO_TITLE:
    'Las acciones que realices sobre los usuarios no impactarán sobre los períodos anteriores al vigente',
  CONFIG_USERS_POPCOFIRM_DELETE_USER_TITLE: '¿Estás seguro que deseas eliminar este usuario?',
  CONFIG_USERS_POPCONFIRM_RESET_PASSWORD_TITLE:
    'La contraseña de este usuario va a cambiar a la que tenemos por defecto: plika123, ¿Estás seguro que deseas hacerlo?',
  CONFIG_USERS_MODAL_CREATE_USER_TITLE: 'Crear usuario',
  CONFIG_USERS_CONFIRM_PASSWORD_TYPOGRAPHY_TEXT:
    'Para confirmar esta acción necesitamos que ingreses tu contraseña',
  CONFIG_USERS_DRAWER_TITLE: 'Configuración usuario',
  CONFIG_USERS_SELECTION: 'Selección usuarios',

  //Variables
  CONFIG_VAR_LIST_DRAWER_TITLE: 'Listado variables',
  CONFIG_VAR_CREATE_MODAL_TITLE: 'Crear variable',
  CONFIG_VAR_CREATE_MODAL_NAME_PLACEHOLDER: 'Ej. Inflación',
  CONFIG_VAR_UPLOAD_MODAL_TITLE: 'Cargar variables',
  CONFIG_VAR_UPLOAD_VALUES_MODAL_TITLE: 'Cargar valores de variables',
  CONFIG_VAR_DELETE_CONFIRM_TOOLTIP: '¿Estás seguro que deseas eliminar la variable {{name}}?',
  CONFIG_VAR_ACCOUNTS_NOT_SELECTED_PLACEHOLDER: 'No hay cuentas seleccionadas',

  // Reglas de negocio
  CONFIG_FORMULAS_EMPTY_SCREEN_TITLE: 'Comenzá la carga de tus reglas de negocio',
  CONFIG_FORMULAS_EMPTY_SCREEN_DESCRIPTION:
    'Crea tus reglas de negocio para obtener importes con los que calcular tus presupuestos',
  CONFIG_FACTOR_EMPTY_SCREEN_TITLE: '¡Aún no tienes factores cargados en esta regla!',
  CONFIG_FACTOR_EMPTY_SCREEN_DESCRIPTION:
    'Crea factores con sus comportamientos para esta regla de negocios ',
  CONFIG_FORMULAS_CREATE_ACTION: 'Crear regla de negocio',
  CONFIG_FORMULAS_CONFIRM_ACTION_POPCONFIRM_TITLE:
    '¿Desea borrar los datos calculados actualmente? ¿O prefiere conservarlos?',
  CONFIG_FACTOR_CONFIRM_ACTION_MODAL_DESCRIPTION:
    'Estás a punto de eliminar el factor {{factorName}}. Ten en cuenta que esto impactará en el cálculo y sus resultados.',
  CONFIG_FACTOR_CREATE_MODAL_PLACEHOLDER: 'Ej. Cantidad Inicial',
  CONFIG_FACTOR_CREATE_ACTION: 'Crear factor',
  CONFIG_FACTOR_POPCONFIRM_DELETE_TITLE: '¿Estás seguro que deseas eliminar este factor?',
  CONFIG_FACTOR_DELETE_FEEDBACK_SUCCESS: 'El factor se eliminó correctamente',
  CONFIG_FORMULAS_CREATE_MODAL_PLACEHOLDER: 'Ej: Ingreso por Ventas',
  CONFIG_FORMULAS_POPCONFIRM_DELETE_TITLE:
    '¿Estás seguro que deseas eliminar esta regla de negocio?',
  CONFIG_VAR_DIMENSIONS_NOT_SELECTED_PLACEHOLDER: 'No hay dimensiones seleccionadas',
  CONFIG_RULE_EDIT_FORMULA_MODAL_TITLE: 'Editar fórmula',
  CONFIG_RULES_APPLY_BUTTON_ACTION: 'Aplicar reglas',
  CONFIG_RULES_ORIGIN_VALUES_STEP_ORIGIN_SUBTITLE: 'Conjunto de valores para tu origen',
  CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_SUBTITLE: 'Eligí la fórmula y su comportamiento',
  CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_INDIVIDUAL_RADIO_BUTTON_OPTION: 'Individual',
  CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_ACCUMULATIVE_RADIO_BUTTON_OPTION: 'Acumulativa',
  CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_ORIGIN: 'Origen',
  CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_DESTINATION: 'Destino',
  CONFIG_RULES_DESTINATION_VALUES_STEP_DESTINATION_SUBTITLE: 'Conjunto de valores para tu destino',
  CONFIG_RULES_DESTINATION_VALUES_STEP_DESTINATION_TAKE_ORIGIN_RADIO_BUTTON_OPTION:
    'Tomar el origen',
  CONFIG_RULES_DESTINATION_VALUES_STEP_DESTINATION_TDIFFERENT_RADIO_BUTTON_OPTION:
    'Destino diferente',
  CONFIG_RULES_SWITCH_FORM_LABEL_REPLACE_VALUES: 'Reemplazar valores de la regla',
  CONFIG_RULES_SWITCH_FORM_INFORMATIVE_TOOLTIP_TITLE:
    'Si activas esta opción, la regla reemplazará los valores anteriores del destino',
  CONFIG_RULES_VIEW_VARIABLES_TITLE: 'Ver variables',
  CONFIG_RULES_INFORMATIVE_MODAL_BODY_1:
    'Establecer el comportamiento de tus reglas de negocio comprende tres partes:',
  CONFIG_RULES_INFORMATIVE_MODAL_BODY_2:
    '1- Origen: conjunto de valores de dimensiones que será nuestra base para la generación del cálculo',
  CONFIG_RULES_INFORMATIVE_MODAL_BODY_3: '2- Fórmula que se va a calcular sobre el origen.',
  CONFIG_RULES_INFORMATIVE_MODAL_BODY_4:
    '3- Destino: conjunto de valores de dimensiones pudiendo ser el mismo que el origen o uno distinto. En este destino se verá reflejado el resultado del cálculo.',
  CONFIG_RULES_NOT_CREATE_INFORMATIVE_TOOLTIP_TITLE:
    'Debes crear al menos una variable para poder crear una regla',
  CONFIG_RULES_FORMULA_SELECT_VARIABLE_TITLE: 'Variables que componen la fórmula',
  CONFIG_RULES_FORMULA_SELECT_VARIABLE_PLACEHOLDER: 'Selecciona una formula',
  CONFIG_RULE_CALCULATE_FORM_LABEL: 'Calcular sobre',
  CONFIG_RULE_NAME_WITHOUT_NUMBERS_INFORMATIVE_TOOLTIP:
    'El nombre de la regla solo puede contener letras, números y espacios.',
  CONFIG_RULES_TOTAL_RULES: 'Total reglas',

  //Roles
  CONFIG_ROLES_EMPTY_STATE_DESCRIPTION:
    'Aún no tenes cargado roles para tus usuarios, comienza a configurarlos',
  CONFIG_ROLES_CREATE_ACTION: 'Crear rol',
  CONFIG_ROLES_DUPLICATE_ACTION: 'Duplicar',
  CONFIG_ROLES_VIEW_TITLE: 'Ver rol',
  CONFIG_ROLES_MODAL_CREATE_ROLE_TITLE: 'Crear rol nuevo',
  CONFIG_ROLES_MODAL_DUPLICATE_ROLE_TITLE: 'Duplicar rol',
  CONFIG_ROLES_DRAWER_ROLE_OPERATIONS_TITLE: 'Seguridad del rol: {{name}}',
  CONFIG_ROLES_POPCONFIRM_DELETE_ROLE_TITLE:
    '¿Estás seguro que deseas eliminar este rol? Si lo haces, se eliminará también del usuario asociado, pudiendo quedar el mismo sin ningún rol.',
  CONFIG_ROLES_ACTION_MODAL_NAME_PLACEHOLDER: 'Ej: Responsable Ventas',
  CONFIG_ROLES_ACTION_MODAL_CODE_PLACEHOLDER: 'Ej: RV',
  CONFIG_ROLES_LABEL_COPY: 'copia',
  CONFIG_ROLES_DRAWER_FEATURES_TAB_LABEL: 'Funcionalidades',
  CONFIG_ROLES_SAVE_FEATURES_BUTTON_DRAWER: 'Guardar funcionalidades',
  CONFIG_ROLES_SAVE_DATA_BUTTON_DRAWER: 'Guardar datos',

  //onboarding
  CONFIG_ONBOARDING_STEP_DESCRIPTION_SECURITY:
    'Crea roles y permisos para luego asignarlos a los usuarios que crees para tu equipo.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_PLAN_ACCOUNT:
    'Crea y carga información para tu plan de cuentas, niveles para la estructuta y cuentas para ser anidadas en los disntitnos niveles.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_CONCEPT:
    ' Acá encontrás conceptos de ventas y gastos para organizar y agrupar tu información.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_DIMENSIONS:
    'Crea de manera personalizada dimensiones y sus valores para clasificar, ordenar o agrupar información, las mismas serán asoaciadas a los conceptos.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_PROJECTIONS_ELEMENTS:
    'Crea variables y reglas de negocios para cargar datos, aplicar cálculos y simular escenarios en el módulo de planificación.',
  CONFIG_ONBOARDING_STEP_DESCRIPTION_REPORT:
    'Visualiza y crea tus reportes. Crea la estructura de tu estado de resultados y arma reportes personalizados aun más detallados.',
  CONFIG_ONBOARDING_SKIP_TOOLTIP_TITLE:
    'Si omitis podrás volver a ver el recorrido haciendo click en el signo ? de ayuda -> Ver onboarding',

  //moneda
  CONFIG_CURRENCY_EMPTY_SCREEN_TITLE: '¡Aún no tienes monedas creadas!',
  CONFIG_CURRENCY_EMPTY_SCREEN_DESCRIPTION:
    'Crea Monedas de Origen y Funcionales. Las monedas de origen serán en las que puedas cargar tus valores en Plika, las monedas funcionales en las que verás reflejados los mismos.',
  CONFIG_CURRENCY_HEADER_FUNCTIONAL: 'Moneda funcional',
  CONFIG_CURRENCY_HEADER_ORIGIN: 'Moneda de origen',
  CONFIG_CURRENCY_ACTIVATE: 'Activar moneda',
  CONFIG_CURRENCY_DEACTIVATE: 'Desactivar moneda',
  CONFIG_CURRENCY_DEACTIVATE_CURRENCY_POPCONFIRM_TEXT: '¿Estás seguro de {{action}} esta moneda?',
  CONFIG_CURRENCY_DELETE_ACTION: 'Eliminar moneda',
  CONFIG_CURRENCY_ADD_ACTION: 'Añadir otra moneda',
  CONFIG_CURRENCY_CREATE_ACTION: 'Crear Moneda',
  CONFIG_CURRENCY_CREATE_MODAL_TITLE: 'Crear moneda nueva',
  CONFIG_CURRENCY_CREATE_FEEDBACK_FAIL_DESCRIPTION: `Los siguientes codigos no se crearon porque ya existen: {{currencies}}`,
  CONFIG_CURRENCY_FIXBUTTON_TITLE: 'Fijar moneda',
  CONFIG_CURRENCY_POPCONFIRM_OKTEXT: 'Fijar',
  CONFIG_CURRENCY_FEEDBACK_SUCCESS: 'Moneda {{action}} correctamente',
  CONFIG_CURRENCY_FEEDBACK_FAIL: 'No se pudo {{action}} la moneda',
  CONFIG_CURRENCY_FEEDBACK_ERROR: 'Error',
  CONFIG_CURRENCY_ACTION_ACTIVATED: 'Activada',
  CONFIG_CURRENCY_ACTION_DEACTIVATED: 'Desactivada',
  CONFIG_CURRENCY_ACTION_FIXED: 'Fijada',
  CONFIG_CURRENCY_WARNING_MESSAGE_MODAL_CONFIRM_DESCRIPTION_1:
    'Estás por fijar la moneda con código {{code}} como moneda funcional de toda la herramienta de forma definitiva. Esto quiere decir que si lo haces, no vas a poder deshacer la acción.',
  CONFIG_CURRENCY_WARNING_MESSAGE_MODAL_CONFIRM_DESCRIPTION_2:
    ' ¿Estás seguro que deseas fijar esta moneda?',
  CONFIG_CURRENCY_EXCHANGE_RATE_DRAWER_SUBTITLE:
    'Ingresá el tipo de cambio de cada una de las monedas con las cuales operas.',
  CONFIG_CURRENCY_EXCHANGE_RATE_DEFAULT_CURRENCY_DRAWER: 'Moneda por defecto:',
  CONFIG_CURRENCY_MODAL_CREATE_SYMBOL_FIELD_PLACEHOLDER: 'Por ej. $',
  CONFIG_CURRENCY_EXCHANGE_RATE_NOT_PREDEFINED_CURRENCY_DRAWER: 'No hay moneda fijada',
  CONFIG_CURRENCY_EXCHANGE_RATE_NOT_CURRENCIES:
    'Debes crear más monedas para poder ver información',
  CONFIG_CURRENCY_EXAMPLE_TEXT_DRAWER_2: 'Moneda default = Pesos',
  CONFIG_CURRENCY_EXAMPLE_TEXT_DRAWER_1: 'Moneda por defecto * tipo de cambio = moneda actual',
  CONFIG_CURRENCY_EXAMPLE_TEXT_DRAWER_3: 'Tipo de cambio = float',
  CONFIG_CURRENCY_EXAMPLE_TEXT_DRAWER_4:
    'Moneda actual = moneda a la que le estoy cargando el tipo de cambio',

  //mockData listado monedas
  CONFIG_CURRENCY_USD: 'Dólar Estadounidense',
  CONFIG_CURRENCY_EUR: 'Euro',
  CONFIG_CURRENCY_ARS: 'Peso Argentino',
  CONFIG_CURRENCY_CLP: 'Peso Chileno',
  CONFIG_CURRENCY_COP: 'Peso Colombiano',
  CONFIG_CURRENCY_MXN: 'Peso Mexicano',
  CONFIG_CURRENCY_UYU: 'Peso Uruguayo',
  CONFIG_CURRENCY_PEN: 'Sol Peruano',

  CONFIG_TEXT_FINANCIAL: 'Financiero',
  CONFIG_TEXT_ECONOMIC: 'Económico',

  CONFIG_BUDGET_TYPE_INFORMATIVE_TOOLTIP_TITLE:
    'Son los conceptos en los que se estructura la información a lo largo de todos los módulos. Estos permiten segregar y organizar la carga y análisis de datos. Se permite crear hasta 6 Tipos de Presupuestos.',
  CONFIG_DIMENSIONS_INFORMATIVE_TOOLTIP_TITLE:
    'Constituyen la capa analítica de Plika, los filtros que se le brindan a la herramienta. Las dimensiones impactarán en los datos de los cuales tengamos que nutrir a Plika a futuro.',
  CONFIG_RULES_INFORMATIVE_TOOLTIP_TITLE:
    'Esta funcionalidad de Plika permite generar cálculos y aplicar fórmulas determinadas, dentro de la misma herramienta.',
  CONFIG_REPORTS_INFORMATIVE_TOOLTIP_TITLE:
    'Dentro de este espacio se configurarán los reportes económicos y financieros que luego impactarán en el módulo de Análisis',
  CONFIG_ROLE_INFORMATIVE_TOOLTIP_TITLE:
    'El rol creado se puede asignar a uno o varios usuarios dados de alta en la herramienta',
  CONFIG_SECURITY_INFORMATIVE_TOOLTIP_TITLE:
    'El rol determina el tipo de acceso que se tendrá dentro de la herramienta. Por su parte, el usuario hace referencia a la persona en sí misma.',
  CONFIG_PREFERENCES_DIMENSIONS_INFORMATIVE_TOOLTIP_TITLE:
    'Las preferencias permiten modificar la visualización de la información dentro de cada módulo de la herramienta.',
  CONFIG_CURRENCY_INFORMATIVE_TOOLTIP_TITLE:
    'Se podrán configurar las distintas monedas en las que se quiere visualizar la información posteriormente.',

  CONFIG_VIDEO_DESCRIPTION_1: 'Veremos cómo configurar los diferentes tipos de presupuestos.',
  CONFIG_VIDEO_DESCRIPTION_2:
    'Profundizaremos en la configuración de las dimensiones que componen la capa analítica de Plika y determinan el nivel de detalle de tus datos.',
  CONFIG_VIDEO_DESCRIPTION_3: 'Veremos cómo configurar las reglas de negocio.',
  CONFIG_VIDEO_DESCRIPTION_4: 'Veremos cómo configurar los diferentes reportes.',
  CONFIG_VIDEO_DESCRIPTION_5: 'Veremos cómo configurar los diferentes roles y usuarios.',
  CONFIG_VIDEO_DESCRIPTION_6: 'Te explicaremos cómo recuperar tu contraseña de usuario Plika.',
  CONFIG_VIDEO_DESCRIPTION_7:
    'Te mostraremos cómo usar la funcionalidad Origen-Gestión para consolidar datos de diferentes fuentes (como múltiples ERPs) en Plika. Así, podrás unificar toda la información en una única fuente de verdad.',

  CONFIG_PROFILE_PHOTO_TITLE: 'Foto de perfil',
  CONFIG_PROFILE_SELECT_MODIFY_PHOTO_SUBTITLE: 'Selecciona o modifica tu foto de perfil',
  CONFIG_PROFILE_PERSONAL_DATA_TAB: 'Datos personales',
  CONFIG_PROFILE_MODIFY_PHOTO_MODAL_TITLE: 'Modificar foto de perfil',
  CONFIG_PROFILE_IMG_FORMAT_TYPOGRAPHY_TEXT: 'Formato: .jpg, .png',
}

export default configuration

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Col, Space, Spin } from 'antd'
import FilterSelect from './FilterSelect'
import reports from 'modules/reports'

import './FiltersContainer.scss'

const FiltersContainer = ({
  filter,
  analysisFilters,
  fetchAnalysisFilters,
  isRefresh = false,
  setIsRefresh = null,
  showButtonDeleteFilter = null,
  setPagination = null,
  // selectionToFilter,
  setSelectionToFilter,
}) => {
  useEffect(() => {
    fetchAnalysisFilters()
      .then(() => isRefresh && setIsRefresh())
      .catch(() => {})
    // eslint-disable-next-line
  }, [fetchAnalysisFilters, isRefresh])

  const onSelectItems = (values, idDimension) =>
    setSelectionToFilter((prevState) => {
      return { ...prevState, [idDimension]: values }
    })

  return (
    <Spin size="small" spinning={isRefresh}>
      <Space className="filter-space">
        {analysisFilters
          .filter((data) => data.values.length !== 0)
          .map((dimension, index) => (
            <Col span={4} key={index} style={{ padding: '10px 0px' }}>
              <FilterSelect
                key={dimension.id_dimension}
                label={dimension.name}
                dimensionId={dimension.id_dimension}
                onChangeSelection={onSelectItems}
                selection={filter}
                options={dimension.values}
                showButtonDeleteFilter={showButtonDeleteFilter}
                setPagination={setPagination}
              />
            </Col>
          ))}
      </Space>
    </Spin>
  )
}

const mapStateToProps = (state) => ({
  analysisFilters: reports.selectors.getAnalysisFilters(state),
})

const mapDispatchToProps = {
  fetchAnalysisFilters: reports.actions.fetchAnalysisFilters,
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersContainer)

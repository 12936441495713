import { Button, ButtonActionBar, DeleteSelectionButton, Typography } from 'modules/core/components'
import { DownloadOutlined } from '@ant-design/icons'
import { Space } from 'antd'

const TableActions = ({
  dataSelection,
  onConfirmDelete,
  disabled,
  isDeleting,
  onClickGroup,
  onClickDownload,
}) => {
  return (
    <ButtonActionBar position={dataSelection.length > 0 ? 'space-between' : 'end'}>
      <Space direction="horizontal">
        {dataSelection.length > 0 && (
          <DeleteSelectionButton
            onConfirm={onConfirmDelete}
            disabled={disabled}
            isDeletingAll={isDeleting}
          />
        )}
      </Space>
      <Space direction="horizontal">
        <Button.Link title="ACTION_MAP" onClick={onClickGroup} disabled={disabled} />
        <Button.Icon
          title="ACTION_DOWNLOAD_TABLE"
          icon={<Typography.Icon icon={DownloadOutlined} />}
          onClick={onClickDownload}
        />
      </Space>
    </ButtonActionBar>
  )
}

export default TableActions

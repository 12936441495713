const report = {
  REPORT_TEMPLATE_FILE_NAME: 'Plika Template - Report {{name}} - {{period}} ({{date}})',
  REPORT_CARD_TITLE_NO_DATA:
    'When the economic budget is under review or approved, here we will present a summary of the most important indicators of your report.',
  REPORT_GRAPHIC_TITLE: 'Monthly evolution and comparison',
  REPORT_STRUCTURE_FILE_NAME: 'Plika - Settings  Profit & Loss (structure)',
  REPORT_EVOLUTION_FILE_NAME_DEFAULT: 'Plika - Settings  Profit & Loss',
  REPORT_EVOLUTION_FILE_NAME:
    'Plika - Analysis  Profit & Loss - {{periodName}} (Monthly evolution) ({{date}})',
  REPORT_COMPARISON_FILE_NAME:
    'Plika - Analysis Profit & Loss - {{periodName}} (Comparisons) ({{date}})',
  REPORT_OTHER_CHECKBOX_TEXT: 'Convert sign in selected line of P&L',
  REPORT_OTHER_CHECKBOX_TEXT_TOOTIP:
    'By converting the sign you will see negative values to positive reflected in your table.',

  REPORT_DIMENSION_SHOW_CANT_LABEL: 'Number of visible dimensions',

  REPORT_PLIKA_CARD_TITLE: 'Plika reports',
  REPORT_PPT_FILE_NAME: 'Plika - Reports {{reportName}} - {{periodName}} ({{date}})',

  REPORT_PLIKA_CARD_BODY_TITLE: 'Analyze your most relevant metrics',
  REPORT_PLIKA_CARD_BODY_DESCRIPTION:
    'Access your reports, to visualize the most relevant metrics of your data, evolution graphics and comparisons.',

  //reporte self-service
  REPORT_SELF_SERVICE_FIELD: 'Plika BI',
  REPORT_SELF_SERVICE_CARD_BODY_TITLE: 'Create reports with the information you want',
  REPORT_SELF_SERVICE_ENTER_PERIOD_DESCRIPTION:
    'Visualize and organize the information you want, accompanied by charts, to analyze your data easily and quickly.',

  REPORT_VALUES_RATIO: 'Ratio values',

  //financiero
  REPORT_FINANCIAL_NO_DATA_INFORMATIVE_TOOLTIP_TITLE:
    'There are no data loaded from the previous period to visualize this section',
  REPORT_DETAIL_FAIL_FEEDBACK_DESCRIPTION:
    'You must send all the required parameters to view the report.',
  REPORT_COMPARATIVE_ACCUMULATIVE: 'Accumulated',
  REPORT_COMPARATIVE_MONTHLY: 'Monthly',

  REPORT_DASHBOARD_CREATE_MODAL_TITLE: '{{action}} elements',
  REPORT_DASHBOARD_EMPTY_AREA_DESCRIPTION:
    'Create your elements and choose the options that best fit your needs',
  REPORT_PLACEHOLDER_VERTICAL_ANALYSIS: 'Select a line for vertical analysis.',

  REPORT_SELECT_PROYECTION_INFORMATIVE_TOOLTIP_TITLE:
    'Select what information you want to see in the report',
  REPORT_VARIANT_INFORMATIVE_ALERT_DESCRIPTION:
    'The variation in $ reflects how much of the change in the total amount is attributable to price versus quantity. Similarly, the variation in % indicates how the total 100% change (amount) is distributed between price and quantity.',

  REPORT_VIDEOS_DESCRIPTION_1:
    'We will explain how to generate deeper insights from the information available in the tool.',
  REPORT_VIDEOS_DESCRIPTION_2:
    'We will explain how to use the comparisons functionality to analyze deviations and generate insights from them.',
  REPORT_VIDEOS_DESCRIPTION_3:
    'We will explain how the analysis of variations between price and quantity works.',
}

export default report

import React from 'react'
import { connect } from 'react-redux'
import { SiderLayout } from 'modules/core/layouts'
import { Button, ButtonActionBar, EmptyScreen } from 'modules/core/components'
import { Row, Col, Card } from 'antd'
import { isUserAllowed, getPeriodOperationsKeys, getOperationsByKeys } from 'modules/core/utils'
import { HOME, ROLES } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { DashboardHeader, DashboardSubtitle, ListInformation } from './components'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import login from 'modules/login'

import './DashboardGeneral.scss'

const { SETTINGS } = ROLES

const DashboardGeneral = ({ loggedUser: { firstName }, hasDataConfig }) => {
  let history = useHistory()
  const { t } = useTranslation()

  const canGoToPeriods = isUserAllowed(getPeriodOperationsKeys())
  const canGoToConfig = isUserAllowed(getOperationsByKeys([SETTINGS]))

  const renderVideo = () => (
    <iframe
      width="100%"
      height={300}
      src="https://www.youtube.com/embed/6qs_OnQGMaQ?list=PLJo0bl0cWTAO6fHJ5mGSYXs-Om2zbrorq"
      title="#QuickDemos | Funcionalidad Moneda -  Análisis"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>
  )

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('DASHBOARD_WELLCOME')}
      description={t('DASHBOARD_EMPTY_STATE_DESCRIPTION')}
      footer={
        <ButtonActionBar>
          {canGoToConfig && (
            <Button.Primary
              onClick={() => history.push(`/configuracion`)}
              title="DASHBOARD_CONFIG_ACTION"
            />
          )}
          {canGoToPeriods && (
            <Button.Primary
              onClick={() => history.push(`/periodos`)}
              title="DASHBOARD_PERIODS_ENTER_ACTION"
            />
          )}
        </ButtonActionBar>
      }
    />
  )

  return (
    <SiderLayout>
      {hasDataConfig && (
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <DashboardHeader firstName={firstName} />
          </Col>
          <Col span={24}>
            <Card style={{ height: 400, boxShadow: `0px -3px 0px #6CA4E0` }}>
              <DashboardSubtitle name="DASHBOARD_TUTORIALS" />
              {renderVideo()}
            </Card>
          </Col>
          <Col span={10}>
            <Card style={{ boxShadow: `0px -3px 0px #6CA4E0` }}>
              <DashboardSubtitle name="HOME_GENERAL_CONTACTS_CARD_TEXT" />
              <ListInformation dataSource={HOME.LIST_DATA_DASHBOARD.CONTACTS} />
            </Card>
          </Col>
          <Col span={14}>
            <Card style={{ boxShadow: `0px -3px 0px #6CA4E0` }}>
              <DashboardSubtitle name="HOME_GENERAL_TRAINING_CARD_TEXT" />
              <ListInformation dataSource={HOME.LIST_DATA_DASHBOARD.TRAINING} />
            </Card>
          </Col>
        </Row>
      )}
      {!hasDataConfig && renderNoDataScreen()}
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
})

export default connect(mapStateToProps, null)(DashboardGeneral)

import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { usePagination } from 'modules/core/customHooks'
import { Row, Col, Spin, Card, message, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { Navigator, EmptyScreen, Typography } from 'modules/core/components'
import { CreatDimensionModal, DimensionsTable, TableActions, UserActions } from './components'
import { SiderLayout } from 'modules/core/layouts'
import { generateMessageError, isUserAllowed } from 'modules/core/utils'
import { ROLES } from 'modules/core/constants'
import { handleDelete, handleDeleteAll } from './utils'
import configuration from 'modules/configuration'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import moment from 'moment'

import './DimensionsList.scss'
import { useHistory } from 'react-router-dom'

const { SETTINGS__DIMENSIONS__ABM } = ROLES

const DimensionsList = ({
  fetchDimensionsList,
  dimensionsList,
  deleteDimensionsSelections,
  deleteAllDimensions,
  downloadTableFile,
  fetchBudgetTypeList,
  budgetTypeList,
  applyMap,
  checkIsMap,
  isMap,
}) => {
  const [isTableLoading, setIsTableLoading] = useState(false)
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [tablePagination, setTablePagination] = usePagination()
  const [searchValue, setSearchValue] = useState('')
  const [dataSelection, setDataSelection] = useState([])
  const [isDeleting, setIsDeleting] = useState(false)
  const [isDeletingAll, setIsDeletingAll] = useState(false)
  const [showPopConfirm, setShowPopConfirm] = useState(false)
  const [showOtherPopConfirm, setShowOtherPopConfirm] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()
  let history = useHistory()

  useEffect(() => {
    checkIsMap()
  }, [checkIsMap])

  useEffect(() => {
    setIsTableLoading(true)
    fetchDimensionsList({
      search: searchValue,
      ...tablePagination,
    }).then(() => setIsTableLoading(false))
  }, [fetchDimensionsList, searchValue, tablePagination])

  useEffect(() => {
    fetchBudgetTypeList()
  }, [fetchBudgetTypeList])

  const userCanCreate = isUserAllowed(SETTINGS__DIMENSIONS__ABM)

  const renderNoDataScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('LABEL_DIMENSIONS')}
      description={t('CONFIG_DIMENSIONS_PROCESS_DATA_EMPTY_SCREEN_DESCRIPTION')}
    />
  )

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('LABEL_CONFIG'),
          url: `/configuracion`,
        },
        { name: t('LABEL_DIMENSIONS') },
      ]}
    />
  )

  const oncloseDeleteAll = () => {
    setIsDeletingAll(false)
    setShowPopConfirm(false)
  }

  const renderUserActions = () => (
    <UserActions
      setSearchValue={setSearchValue}
      onClick={() => setShowCreateModal(true)}
      disabled={!userCanCreate}
      onClickDeleteAll={() => setShowPopConfirm(true)}
      loading={isDeletingAll}
      onConfirmPopConfirm={() =>
        handleDeleteAll({
          setIsLoading: setIsDeletingAll,
          deleteAllDimensions,
          onRefetch: () => fetchDimensionsList({ search: searchValue, ...tablePagination }),
          onClose: oncloseDeleteAll,
        })
      }
      onCancelPopConfirm={() => setShowPopConfirm(false)}
      visible={showPopConfirm}
      onClickActivity={() => {
        history.push('/configuracion/actividad?type=dimension&module=configDimension')
      }}
    />
  )

  const renderTable = () => (
    <DimensionsTable
      dataSource={dimensionsList.values}
      count={dimensionsList.count}
      onChange={setTablePagination}
      tableLoading={isTableLoading}
      pagination={tablePagination}
      onFetchDimensionList={() => fetchDimensionsList({ search: searchValue, ...tablePagination })}
      dataSelection={dataSelection}
      setDataSelection={setDataSelection}
      canDelete={userCanCreate}
      budgetTypeList={budgetTypeList?.values}
    />
  )

  const onCloseDelete = () => {
    setIsDeleting(false)
    setDataSelection([])
  }

  const handleApply = () => {
    setIsSaving(true)
    applyMap()
      .then(() => {
        message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        setIsSaving(false)
        setShowOtherPopConfirm(false)
      })
      .catch((error) => {
        setIsSaving(false)
        setShowOtherPopConfirm(false)
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        })
      })
  }

  const renderTableActions = () => (
    <TableActions
      dataSelection={dataSelection}
      onConfirmDelete={() =>
        handleDelete({
          setIsLoading: setIsDeleting,
          deleteDimensionsSelections: () =>
            deleteDimensionsSelections({ dimensions: dataSelection }),
          onRefetch: () => fetchDimensionsList({ search: searchValue, ...tablePagination }),
          onClose: onCloseDelete,
        })
      }
      disabled={!userCanCreate}
      isDeleting={isDeleting}
      onClickDownload={() =>
        downloadTableFile(t('CONFIG_DIMENSIONS_FILE_NAME', { date: moment().format('lll') }))
      }
      onClickMap={() => setShowOtherPopConfirm(true)}
      buttonLoading={isSaving}
      disableButton={!isMap}
      onConfirmApply={() => handleApply()}
      onCancelApply={() => setShowOtherPopConfirm(false)}
      visible={showOtherPopConfirm}
    />
  )

  const renderKpis = () => (
    <Row gutter={[24, 24]}>
      <>
        {isTableLoading &&
          [1].map((index) => (
            <Col span={4} key={index}>
              <Card loading={true} />
            </Col>
          ))}
        {!isTableLoading && (
          <Col span={4}>
            <Card bodyStyle={{ backgroundColor: '#e1effa' }}>
              <div style={{ textAlign: 'center' }}>
                <Typography.Headline level={3}>{dimensionsList.count}</Typography.Headline>
              </div>
              <div style={{ textAlign: 'center' }}>
                <Typography.Body level={3} type="secondary">
                  {t('CONFIG_DIMENSIONS_TOTAL_DIMENSIONS')}
                </Typography.Body>
              </div>
            </Card>
          </Col>
        )}
      </>
    </Row>
  )

  return (
    <SiderLayout>
      <div className="dimensions-list-container">
        <Row gutter={[12, 12]}>
          <Col span={24}>{renderHeader()}</Col>
          <Spin spinning={isTableLoading} />
          {dimensionsList.count <= 0 && !searchValue && !isTableLoading && (
            <Col span={24}>{renderNoDataScreen()}</Col>
          )}
          {(dimensionsList.count > 0 || (searchValue && dimensionsList.count === 0)) && (
            <>
              <Col span={24}>{renderUserActions()}</Col>
              <Col span={24}>{renderKpis()}</Col>
              <Col span={24}>{renderTableActions()}</Col>
              <Col span={24}>{renderTable()}</Col>
            </>
          )}
          <CreatDimensionModal
            visible={showCreateModal}
            onCancel={() => setShowCreateModal(false)}
            onFetchDimensionList={() =>
              fetchDimensionsList({ search: searchValue, ...tablePagination })
            }
            dimensionsList={dimensionsList?.values}
            budgetTypeList={budgetTypeList?.values}
          />
        </Row>
      </div>
    </SiderLayout>
  )
}

const mapStateToProps = (state) => ({
  dimensionsList: configuration.selectors.getDimensionsList(state),
  budgetTypeList: configuration.selectors.getBudgetTypeList(state),
  isMap: configuration.selectors.getIsMap(state),
})

const mapDispatchToProps = {
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
  deleteDimensionsSelections: configuration.actions.deleteDimensionsSelections,
  deleteAllDimensions: configuration.actions.deleteAllDimensions,
  downloadTableFile: configuration.actions.downloadTableFile,
  fetchBudgetTypeList: configuration.actions.fetchBudgetTypeList,
  applyMap: configuration.actions.applyMap,
  checkIsMap: configuration.actions.checkIsMap,
}

export default connect(mapStateToProps, mapDispatchToProps)(DimensionsList)

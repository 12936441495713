import React from 'react'
import { connect } from 'react-redux'

import { WorkflowScreen } from '../../../core/components'
import MonitorDetail from '../../../monitor/monitor-detail'
import { useParams } from 'react-router-dom'

const MonitorWorkflow = () => {
  let { periodId, conceptName, projectionId } = useParams()

  console.log('MonitorWorkflow', periodId, conceptName, projectionId)

  return (
    <MonitorDetail
      moduleWorkflow={'transactions_file_upload'}
      moduleExecution={'real'}
      keyExecution={`${periodId}-${projectionId}`}
    />
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(MonitorWorkflow)

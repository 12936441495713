import { NAME } from './constants'
import { createSelector } from 'reselect'
import { COLUMNS, PROJECTION, ROWS } from 'modules/core/constants'
import moment from 'moment'
import i18n from 'i18next'
import _ from 'lodash'

const AUX_NAME_LEGENDS = {
  forecast: 'LABEL_FORECAST',
  planning: 'LABEL_PLANNING',
  real: 'LABEL_REAL',
  previous_real: 'LABERL_PREVIUS_REAL',
}

export const getPeriodList = (state) => state[NAME].periodList
export const getPeriodDetail = (state) => state[NAME].periodDetail
export const getBudgetInstanceListDefault = (state) => state[NAME].budgetInstanceList
export const getDataPhaseBudget = (state) => state[NAME].dataPhaseBudget
export const getBudgetInstanceDetail = (state) => state[NAME].budgetInstanceDetail
export const getDataNestedTablePhase = (state) => state[NAME].dataNestedTablePhase
export const getGlobalBudgetList = (state) => state[NAME].globalBudgetList
export const getGlobalBudgetDetail = (state) => state[NAME].globalBudgetDetail
export const getGlobalBudgetComparisonTable = (state) => state[NAME].globalBudgetComparisonTable
export const getGlobalBudgetEvolutionTableDefault = (state) =>
  state[NAME].globalBudgetEvolutionTable
export const getGlobalBudgetChartDefault = (state) => state[NAME].globalBudgetChart
export const getGlobalBudgetKpi = (state) => state[NAME].globalBudgetKpi

export const getBudgetInstanceList = createSelector(
  getBudgetInstanceListDefault,
  (budgetInstanceList) => {
    return {
      ...budgetInstanceList,
      values: budgetInstanceList?.values.map((el) => {
        return {
          ...el,
          line_chart: (el.line_chart || []).map((item) => {
            return {
              id: i18n.t(AUX_NAME_LEGENDS[item.id]),
              data: item.data.map((op) => {
                return {
                  y: op.y,
                  x: moment(op.x, 'YYYY-M').format('MMM'),
                }
              }),
            }
          }),
          ranking_up_chart: (el.ranking_up_chart || []).map((el) => {
            const key = _.keys(el).filter((f) => f !== 'dimension')[0]
            return {
              dimension: key.length <= 10 ? key : `${key.slice(0, -key.length + 10)}...`,
              [key]: Math.round(el[key] * 100),
            }
          }),
        }
      }),
    }
  },
)

export const getGlobalBudgetChart = createSelector(
  getGlobalBudgetChartDefault,
  (globalBudgetChart) => {
    const horizontalAxis = globalBudgetChart.columns
      .filter((column) => column.is_transaction && column.title !== 'AV')
      .map((column) => column.title)

    const maxReportElement = !globalBudgetChart.lineId
      ? globalBudgetChart.rows
          .filter((elem) => elem.type === ROWS.TOTALIZER)
          .reduce((prev, current) => (prev.y > current.y ? prev : current), {})
      : globalBudgetChart.rows.find((data) => data.id === globalBudgetChart.lineId)

    const valuesAxisY = horizontalAxis.map((period) => {
      const yValue = maxReportElement[period]
      return {
        x: moment(period, 'YYYY-M').format('MMM'),
        y: yValue ? yValue : '0',
      }
    })

    return {
      id: i18n.t('LABEL_REAL'),
      data: valuesAxisY.asMutable({ deep: true }),
    }
  },
)

export const getGlobalBudgetEvolutionTable = createSelector(
  getGlobalBudgetEvolutionTableDefault,
  (globalBudgetEvolutionTable) => {
    const columns = globalBudgetEvolutionTable.columns.map((column) => {
      return {
        ...column,
        ...(!column.is_transaction && {
          width:
            column.data_index === COLUMNS.TOTAL_DATA_INDEX
              ? COLUMNS.WIDTH.TRANSACTION
              : COLUMNS.WIDTH.EXPANDABLE,
        }),
        ...(column.is_transaction && {
          width: COLUMNS.WIDTH.TRANSACTION,
        }),
      }
    })

    const data = {
      ...globalBudgetEvolutionTable,
      columns,
    }

    return data
  },
)

export const getActiveOptionPeriodTable = createSelector(getPeriodDetail, (periodDetail) => {
  return {
    value: PROJECTION.REAL,
    id: null,
  }
  // return periodDetail.conso_budget_predef
  //   ? {
  //       value: PROJECTION.BUDGET,
  //       id: periodDetail.conso_budget_predef.id,
  //     }
  //   : {
  //       value: PROJECTION.REAL,
  //       id: null,
  //     };
})

export const getBudgetKpis = (state) => state[NAME].budgetKPIS
export const getMlList = (state) => state[NAME].mlList

// TODO: NEW ENDPOINTS FOR REVAMP TABLE
export const getDataEvolutionBudgetReport = (state) => state[NAME].dataEvolutionBudgetReport
export const getDataComparativeBudgetReport = (state) => state[NAME].dataComparativeBudgetReport
export const getDataRdnPercentagePlanning = (state) => state[NAME].dataRdnPercentagePlanning
export const getPlanningFilterDates = (state) => state[NAME].planningFilterDates

export const getAdjustmentsList = (state) => state[NAME].adjustmentsList
export const getStatusApplicationList = (state) => state[NAME].statusApplicationList
export const getApplicationDetail = (state) => state[NAME].applicationDetail
export const getAdjustmentsKpis = (state) => state[NAME].adjustmentsKpis

export const getDataLineChartSacDefault = (state) => state[NAME].dataLineChartSac

export const getDataLineChartSac = createSelector(
  getDataLineChartSacDefault,
  (dataLineChartSac) => {
    return dataLineChartSac.map((data) => {
      return {
        ...data,
        data: data.data
          .map((el) => {
            return {
              ...el,
              x: moment(el.x, 'YYYY-M').format('MMM'),
              y: el.y,
            }
          })
          .asMutable({ deep: true }),
      }
    })
  },
)

export const getDataPieChartSac = (state) => state[NAME].dataPieChartSac

export const getDataTablaAreasSac = (state) => state[NAME].dataTablaAreasSac

import { Col, Space } from 'antd'
import _ from 'lodash'
import { RangePicker } from 'modules/core/components'
import FilterSelect from 'modules/core/components/FiltersContainer/FilterSelect'
import moment from 'moment'

const Filters = ({
  dataPeriod,
  selectedDates,
  setSelectedDates,
  periodId,
  filters,
  setFilters,
  dataDimensions,
  currencyList,
  disabled,
}) => {
  const onSelectItems = (values, dimensionName) =>
    setFilters((prevState) => {
      return { ...prevState, [dimensionName]: values }
    })

  return (
    <Space size="small" className="style-detail-space">
      {!_.isEmpty(dataPeriod) && (
        <RangePicker
          onSetRange={(val) => {
            const dates = {
              start_date: val['start-date'],
              end_date: val['end-date'],
            }
            setSelectedDates(dates)
          }}
          disabled={disabled}
          periodDate={{
            startDate: dataPeriod?.start_date,
            endDate: dataPeriod?.end_date,
          }}
          defaultPickerValue={
            !_.isEmpty(dataPeriod)
              ? [moment(dataPeriod?.start_date), moment(dataPeriod?.end_date)]
              : null
          }
          key={
            !_.isEmpty(selectedDates) && [
              moment(selectedDates['start_date'], 'YYYY-MM-01'),
              moment(selectedDates['end_date'], 'YYYY-MM-01'),
            ]
          }
          defaultValue={
            !_.isEmpty(selectedDates) && [
              moment(selectedDates['start_date'], 'YYYY-MM-01'),
              moment(selectedDates['end_date'], 'YYYY-MM-01'),
            ]
          }
        />
      )}
      <Space style={{ flexWrap: 'wrap', overflowY: 'auto', maxHeight: 152 }}>
        {[
          ...dataDimensions,
          { id: 'currency', name_destination: 'Moneda', name: 'currency_id', values: currencyList },
        ].map((dimension, index) => (
          <Col span={4} key={index} style={{ paddingRight: 0, paddingLeft: 0 }}>
            <FilterSelect
              key={dimension.id}
              label={dimension.name_destination}
              dimensionId={dimension.name}
              onChangeSelection={onSelectItems}
              selection={filters}
              options={dimension.values}
              props={dimension.id !== 'currency' ? { mode: 'multiple', disabled } : { disabled }}
            />
          </Col>
        ))}
      </Space>
    </Space>
  )
}

export default Filters

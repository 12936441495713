import { Col, message, Modal, notification, Radio, Row, Select, Space, Upload } from 'antd'
import { Button, Typography } from 'modules/core/components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UploadOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import planning from 'modules/planning'
import { useParams } from 'react-router-dom'
import { generateMessageError, generateSelectOptions } from 'modules/core/utils'
import configuration from 'modules/configuration'
import moment from 'moment'

const CREATE_OPTIONS = [
  {
    id: 'file',
    name: 'ACTION_UPLOAD_FILE',
  },
  {
    id: 'manual',
    name: 'ACTION_MANUAL_CREATE',
  },
]

const ADJUSTMENTS_TYPE = [
  {
    id: 'EXPANSION',
    name: 'LABEL_EXPANSION',
  },
  {
    id: 'REDUCTION',
    name: 'LABEL_REDUCTION',
  },
  {
    id: 'RECLASSIFICATION',
    name: 'LABEL_RECLASSIFICATION',
  },
]

const CreateApplicationModal = ({
  visible,
  onClose,
  onRedirect,
  onRefetch,
  downloadTemplateAdjustment,
  fetchCurrencyList,
  currencyList,
  uploadAdjustmentXLS,
  fetchAreasList,
  areaList,
}) => {
  const [dataType, setDataType] = useState('file')
  const [fileList, setFileList] = useState([])
  const [adjustmentType, setAdjustmentType] = useState(null)
  const [currency, setCurrency] = useState(null)
  const [area, setArea] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const { t } = useTranslation()
  let { periodId, globalBudgetId } = useParams()

  useEffect(() => {
    fetchCurrencyList()
  }, [fetchCurrencyList])

  useEffect(() => {
    fetchAreasList()
  }, [fetchAreasList])

  const onAddUploadFile = (file) => {
    setFileList([file])
    return false
  }

  const uploadProps = {
    name: 'file',
    accept: '.xls, .xlsx',
    showUploadList: { showPreviewIcon: false },
    beforeUpload: onAddUploadFile,
    onRemove: (file) => setFileList([]),
    fileList: fileList,
    disabled: !adjustmentType || !currency || !area,
  }

  const onCancel = () => {
    onClose()
    setCurrency(null)
    setAdjustmentType(null)
    setFileList([])
    setDataType('file')
    setArea(null)
  }

  const onConfirm = () => {
    if (dataType === 'file') {
      setIsSaving(true)
      uploadAdjustmentXLS(fileList, {
        period_id: periodId,
        currency,
        area,
        adjustment_type: adjustmentType,
        module: 'OVERALL_PLANNING',
        projection: globalBudgetId,
      })
        .then(() => {
          onRefetch()
          setIsSaving(false)
          setCurrency(null)
          setAdjustmentType(null)
          setFileList([])
          setDataType('file')
          setArea(null)
          onClose()
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        })
        .catch((error) => {
          setIsSaving(false)
          setCurrency(null)
          setAdjustmentType(null)
          setFileList([])
          setDataType('file')
          setArea(null)
          onClose()
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: generateMessageError(error),
            duration: 0,
          })
        })
    } else {
      onRedirect()
      setDataType('file')
      onClose()
    }
  }

  return (
    <Modal
      title={t('PLANNING_SAC_CREATE_NEW_ACTION_BUTTON_NAME')}
      visible={visible}
      onCancel={onCancel}
      okText={dataType === 'file' ? t('ACTION_UPLOAD') : t('ACTION_GO')}
      cancelText={t('ACTION_CANCEL')}
      onOk={onConfirm}
      centered
      forceRender
      destroyOnClose
      okButtonProps={{ loading: isSaving }}
      cancelButtonProps={{ disabled: isSaving }}
    >
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        <Radio.Group
          defaultValue={dataType}
          key={dataType}
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        >
          {CREATE_OPTIONS.map((option) => (
            <Radio
              value={option.id}
              key={option.id}
              onChange={(val) => setDataType(val.target.value)}
            >
              {t(option.name)}
            </Radio>
          ))}
        </Radio.Group>
        {dataType === 'file' && (
          <Row gutter={[24, 24]} type="flex" justify="center">
            <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
              <Space direction="vertical">
                <Space direction="horizontal">
                  <Typography.Body level={2}>{`${t('ADJUSTMENT_TYPE_FIELD')}:`}</Typography.Body>
                  <Select
                    placeholder={t('ACTION_SELECT')}
                    style={{ width: 150 }}
                    bordered={false}
                    key={adjustmentType}
                    defaultValue={adjustmentType}
                    onChange={(val) => setAdjustmentType(val)}
                    options={generateSelectOptions({
                      options: ADJUSTMENTS_TYPE.map((el) => {
                        return { ...el, name: t(el.name) }
                      }),
                    })}
                  />
                </Space>
                <Space>
                  <Typography.Body level={2}>{`${t('LABEL_CURRENCY')}:`}</Typography.Body>
                  <Select
                    placeholder={t('ACTION_SELECT')}
                    style={{ width: 150 }}
                    bordered={false}
                    key={currency}
                    defaultValue={currency}
                    onChange={(val) => setCurrency(val)}
                    options={generateSelectOptions({ options: currencyList.data })}
                  />
                </Space>
                <Space>
                  <Typography.Body level={2}>{`${t('LABEL_AREA')}:`}</Typography.Body>
                  <Select
                    placeholder={t('ACTION_SELECT')}
                    style={{ width: 150 }}
                    bordered={false}
                    key={area}
                    defaultValue={area}
                    onChange={(val) => setArea(val)}
                    options={generateSelectOptions({ options: areaList })}
                  />
                </Space>
              </Space>
            </Col>
            <Col>
              <Upload {...uploadProps}>
                {uploadProps.fileList.length <= 0 && (
                  <Button.DefaultWhitILeftIcon
                    icon={<UploadOutlined />}
                    title="UPLOAD_FILE_PLACEHOLDER"
                    disabled={!adjustmentType || !currency || !area}
                    tooltipTitle={
                      !adjustmentType || !currency || !area
                        ? t('PLANNING_SAC_INFORMATIVE_CREATION_TOOLTIP_TITLE')
                        : ''
                    }
                  />
                )}
              </Upload>
            </Col>
            <Col>
              <div className="upload-moda-help">
                {t('INFO_UPLOAD_FILE_MODAL_DESCRIPTION')}{' '}
                <span
                  onClick={() =>
                    downloadTemplateAdjustment(
                      t('PLANNING_SAC_TEMPLATE_NAME', { date: moment().format('lll') }),
                      { period_id: periodId },
                    )
                  }
                >
                  {t('INFO_UPLOAD_FILE_DOWNLOAD_ACTION')}
                </span>
              </div>
            </Col>
          </Row>
        )}
      </Space>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  currencyList: configuration.selectors.getCurrencyList(state),
  areaList: configuration.selectors.getAreaList(state),
})

const mapDispatchToProps = {
  downloadTemplateAdjustment: planning.actions.downloadTemplateAdjustment,
  fetchCurrencyList: configuration.actions.fetchCurrencyList,
  uploadAdjustmentXLS: planning.actions.uploadAdjustmentXLS,
  fetchAreasList: configuration.actions.fetchAreasList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateApplicationModal)

import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Route, Switch, useHistory } from 'react-router-dom'
import { ConfigProvider, message, notification, Select, Space } from 'antd'
import {
  GlobalOutlined,
  BellOutlined,
  CommentOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import { /*GA,*/ generateMessageError, generateSelectOptions } from 'modules/core/utils'
import { Home } from 'modules/home/containers'
import { Login, ResetPasswordForm } from 'modules/login/containers'
import { Home as HomeSocieties } from 'modules/societies/containers'
import { Home as HomeUploads3 } from 'modules/uploadS3/containers'
import { Home as HomeConfigurations } from 'modules/configuration/containers'
import { Home as HomePeriods } from 'modules/periods/containers'
import { Home as HomePlanning } from 'modules/planning/containers'
import { Home as HomeReports } from 'modules/reports/containers'
import { Home as HomeControl } from 'modules/control/containers'
import { Home as HomeForecast } from 'modules/forecast/containers'
import { Home as HomeProcesses } from 'modules/processes/containers'
import { Home as HomeChatbot } from 'modules/chatbot/containers'
import { Home as HomeQuestion } from 'modules/question/containers'
import { VideosHome } from 'modules/videos'
import { NotFoundScreen, Typography } from 'modules/core/components'
import { PolicyAndTermsHome } from 'modules/SecurityPolicy/containers'
import { setSentryUser } from '../../../libs/sentry'
import SessionChecker from '../../../libs/session/checker'
import { tokenutils } from '../../login/actions'
import PrivateRoute from './PrivateRoute'
import login from 'modules/login'

// i18n
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { translationES, translationEN } from 'modules/core/i18n'

// Formatters
import numeral from 'numeral'

// LocalProvider configuration
import esES from 'antd/es/locale/es_ES'
import enUS from 'antd/es/locale/en_US'
import moment from 'moment'
import 'moment/locale/es'

// GrowthBook
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'

import configuration from 'modules/configuration'
import { isEmpty } from 'lodash'
import MonitorScreen from '../../monitor/Monitor'

try {
  moment.locale('es')
} catch (e) {
  console.warn('TypeError: es locale already registered.')
}

// Config i18n
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      es: { translation: translationES },
      en: { translation: translationEN },
    },
    lng: 'es',
    fallbackLng: 'es',

    interpolation: {
      escapeValue: false,
    },
  })

// Config numeral
// load a locale
try {
  numeral.register('locale', 'es', {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'M',
      billion: 'B',
      trillion: 'T',
    },
    ordinal: function (number) {
      var b = number % 10
      return b === 1 || b === 3
        ? 'er'
        : b === 2
        ? 'do'
        : b === 7 || b === 0
        ? 'mo'
        : b === 8
        ? 'vo'
        : b === 9
        ? 'no'
        : 'to'
    },
    currency: {
      symbol: '$',
    },
  })
} catch (e) {
  console.warn('TypeError: es locale already registered.')
}

// switch between locales
try {
  numeral.locale('es')
} catch (e) {
  console.warn('TypeError: es locale already registered.')
}

const LANGUAJE_OPTIONS = [
  {
    id: 'es',
    name: i18n.t('ES'),
  },
  {
    id: 'en',
    name: i18n.t('EN'),
  },
]

// TODO:
const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.REACT_APP_GROWTHBOOK_API_KEY || 'sdk-gjVbusSKuE1zNpt',
  enableDevMode: false,
})

// todo: analizar cuando cambia de lenguaje en el login.
const App = ({
  isAuthenticating,
  isAuthenticated,
  whoAmI,
  loggedUser: { userId, email, lang, society, formatNumber, roles },
  setNewNotification,
  setHasComment,
  markReaded,
  fetchNotificationsList,
  setCounter,
  // setLang,
}) => {
  const langCookie = Cookies.get('plika_lng') || i18n.language
  console.log('langCookie', langCookie)
  const [locale, setLocale] = useState(langCookie === 'es' ? esES : enUS)
  const [isLoading, setIsLoading] = useState(true)
  let history = useHistory()

  const WEBSOCKET = process.env.REACT_APP_WEBSOCKET || `wss://stg.getplika.com`

  const NOTIFICATIONS_TYPE = {
    comment: CommentOutlined,
    mention: CheckCircleOutlined,
    send_notification: BellOutlined,
  }

  useEffect(() => {
    if (isAuthenticated) {
      const socket = new WebSocket(
        `${WEBSOCKET}/ws/notifications-socket/?token=${tokenutils.getAccessToken()}`,
      )

      socket.onopen = () => {
        console.log('WebSocket conectado')
      }

      const handleOnClick = (key, module, idNotification, type, url = null) => {
        let urlRedirect = '/'
        let data = {}

        if (type === 'send_notification' && url) {
          urlRedirect = url
        } else {
          const phases = ['-base', '-top_down', '-bottom_up', '-closing']
          const identifyData = key.split(module)[1]
          const type = identifyData.split('-')[1]
          const id = identifyData.split('-')[2]
          let auxModule = ''
          let phase = ''
          let includePhase = false
          phases.forEach((el) => {
            if (module.includes(el)) {
              auxModule = module.split(el)[0]
              phase = el.split('-')[1]
              includePhase = true
            }
          })

          data = {
            type,
            data: true,
            ...(type !== 'table' && {
              key: module,
              id,
            }),
          }

          const aux = includePhase ? `${auxModule}?phase=${phase}` : module

          urlRedirect = aux.replaceAll('-', '/')
        }
        markReaded({ notifications: [idNotification] })
          .then(() => {
            fetchNotificationsList({ page: 1 }).then(() => {
              if (!isEmpty(data)) {
                setHasComment(data)
              }
              history.push(urlRedirect)
            })
          })
          .catch((error) =>
            message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8),
          )
      }

      socket.onmessage = (event) => {
        const dataNotification = JSON.parse(event.data)
        const value = JSON.parse(dataNotification.value)
        notification.info({
          message: i18n.t('INFORMATIVE_NEW_NOTIFICATION_TITLE'),
          icon: <BellOutlined style={{ color: '#0047ba' }} />,
          description: (
            <Space direction="horizontal" style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Typography.Icon level={2} icon={NOTIFICATIONS_TYPE[value.type]} />
              <div>
                <span>{value.streamline}. </span>
                <span
                  onClick={() =>
                    handleOnClick(value.key, value.module, value.id, value.type, value.url)
                  }
                  style={{ color: '#0047ba', cursor: 'pointer' }}
                >
                  {i18n.t('ACTION_SEE')}
                </span>
              </div>
            </Space>
          ),
          duration: 10,
        })
        setNewNotification(value)
      }
    }

    // return () => {
    //   socket.onclose = (event) => {
    //     console.log('Conexión WebSocket cerrada:', event.reason);
    // }
  }, [isAuthenticated])

  useEffect(() => {
    if (isAuthenticating) whoAmI()
  }, [whoAmI, isAuthenticating])

  useEffect(() => {
    if (userId && society) {
      if (formatNumber?.format_enable) {
        setIsLoading(true)
        buildLocaleNumeral(society, formatNumber)
        numeral.locale(society)
      } else {
        numeral.locale(langCookie)
      }

      setIsLoading(false)
    }
  }, [userId, society])

  useEffect(() => {
    if (userId && society && roles && growthbook) {
      console.log('set user in sentry')
      setSentryUser({
        id: userId,
        email: email,
        company: society,
      })
      console.log('set user in growthbook')
      growthbook.setAttributes({
        id: userId,
        email: email,
        company: society,
        roles: roles.map((it) => it.code),
      })
    }
  }, [userId, society, email, roles])

  useEffect(() => {
    growthbook.init({ streaming: true })
    console.log('init feature flags')
  }, [])

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://wizbot.chat/api/chatbot-script/8fe02b79-6eb4-4812-aa41-59c5ce9b7b41.js'
    script.async = true

    document.head.appendChild(script)
  }, [])

  //Google Analytics
  // useEffect(() => {
  //   if (userId) GA.init(userId);
  // }, [userId]);

  const changeLang = (lng) => {
    Cookies.set('plika_lng', lng)
    i18n.changeLanguage(lng)
    numeral.locale(lng)
    moment.locale(lng)
    setLocale(lng === 'es' ? esES : enUS)
    // if (userId && lang !== lng) setLang(userId, lng);
  }

  useEffect(() => {
    const langCookie1 = Cookies.get('plika_lng') || i18n.language
    changeLang(langCookie1)
  }, [])

  if (isAuthenticating) {
    return <>{i18n.t('FEEDBACK_LOADING')}</>
  }

  const buildLocaleNumeral = (society, settings = {}) => {
    try {
      if (numeral.locales.hasOwnProperty(society)) {
        delete numeral.locales[society]
      }
      numeral.register('locale', society, {
        delimiters: {
          thousands: settings?.delimiter_thousand,
          decimal: settings?.delimiter_decimal,
        },
        abbreviations: {
          thousand: settings?.abbreviation_thousand,
          million: settings?.abbreviation_million,
          billion: settings?.abbreviation_billion,
          trillion: settings?.abbreviation_trillion,
        },
        ordinal: function (number) {
          let b = number % 10
          return b === 1 || b === 3
            ? 'er'
            : b === 2
            ? 'do'
            : b === 7 || b === 0
            ? 'mo'
            : b === 8
            ? 'vo'
            : b === 9
            ? 'no'
            : 'to'
        },
        currency: {
          symbol: '$',
        },
      })
    } catch (e) {
      console.warn('TypeError: es locale already registered.')
    }
  }

  return (
    <ConfigProvider locale={locale}>
      <GrowthBookProvider growthbook={growthbook}>
        <Select
          style={{
            position: 'fixed',
            zIndex: 4,
            top: 13,
            right: 10,
            width: 100,
          }}
          suffixIcon={<GlobalOutlined />}
          defaultValue={langCookie}
          onChange={(lng) => changeLang(lng)}
          loading={isLoading}
          options={generateSelectOptions({ options: LANGUAJE_OPTIONS })}
        />
        <SessionChecker />
        <Switch>
          <PrivateRoute exact path="/login" component={Login} />
          <PrivateRoute exact path="/restablecercontrasenia" component={ResetPasswordForm} />
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute path="/empresas" component={HomeSocieties} />
          <PrivateRoute path="/cargaarchivoss3" component={HomeUploads3} />
          <PrivateRoute path="/procesosdecarga" component={HomeProcesses} />
          <PrivateRoute path="/configuracion" component={HomeConfigurations} />
          <PrivateRoute path="/periodos" component={HomePeriods} />
          <PrivateRoute path="/planificacion" component={HomePlanning} />
          <PrivateRoute path="/seguimiento" component={HomeControl} />
          <PrivateRoute path="/forecast" component={HomeForecast} />
          <PrivateRoute path="/analisis" component={HomeReports} />
          <PrivateRoute path="/chatbot" component={HomeChatbot} />
          <PrivateRoute path="/preguntas" component={HomeQuestion} />
          <PrivateRoute exact path="/politicayterminos" component={PolicyAndTermsHome} />
          <PrivateRoute exact path="/videosplika" component={VideosHome} />
          <PrivateRoute exact path="/monitor-logs" component={MonitorScreen} />
          {/* Paginas de estados / errores  */}
          <Route component={NotFoundScreen} />
        </Switch>
      </GrowthBookProvider>
    </ConfigProvider>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticating: login.selectors.getIsAuthenticating(state),
  isAuthenticated: login.selectors.getIsAuthenticated(state),
  loggedUser: login.selectors.getWhoAmI(state),
})

const mapDispatchToProps = {
  whoAmI: login.actions.whoAmI,
  setNewNotification: configuration.actions.setNewNotification,
  setHasComment: configuration.actions.setHasComment,
  markReaded: configuration.actions.markReaded,
  fetchNotificationsList: configuration.actions.fetchNotificationsList,
  setCounter: configuration.actions.setCounter,
  // setLang: login.actions.editUserLang,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

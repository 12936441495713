import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleDelete = ({ setIsLoading, onDeleteBudget, onRefetch, onClose }) => {
  setIsLoading(true)
  onDeleteBudget()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('PLANNING_DELETE_BUDGET_SUCCESS_FEEDBACK'), 8)
        onClose()
      }),
    )
    .catch((error) => {
      message.error(generateMessageError(error, 'PLANNING_DELETE_BUDGET_ERROR_FEEDBACK'), 8)
      onClose()
    })
}

export const handleEdit = ({
  value,
  titleValue,
  setIsLoading,
  onEditName,
  onClose,
  setTitleValue,
}) => {
  if (value !== titleValue) {
    setIsLoading(true)
    onEditName()
      .then(() => {
        message.success(i18n.t('PLANNING_EDIT_NAME_BUDGET_SUCCESS_FEEDBACK'), 8)
        onClose()
        setTitleValue(value)
      })
      .catch((error) => {
        message.error(generateMessageError(error, 'PLANNING_EDIT_NAME_BUDGET_ERROR_FEEDBACK'), 8)
        onClose()
      })
  } else {
    onClose()
  }
}

export const handleDuplicate = ({ setIsLoading, duplicateCard, onRefetch, onClose }) => {
  setIsLoading(true)
  duplicateCard()
    .then(() =>
      // Si la duplicación es exitosa, recargar la lista
      setTimeout(() => {
        onRefetch().then(() => {
          message.success(i18n.t('FEEDBACK_DUPLICATE_SUCCESS'))
          onClose()
        })
      }, 1000),
    )
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_DUPLICATE_ERROR'), 8)
      onClose()
    })
}

export const handleOnChange = ({ changeCardColor, onConfirm, onClose }) => {
  changeCardColor()
    .then(() => {
      message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
      onConfirm()
    })
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'), 8)
      onClose()
    })
}

import { useState } from 'react'
import { Skeleton } from 'antd'
import { SiderLayout } from '../core/layouts'

export const FRONT_URL = process.env.REACT_APP_FRONT_URL || 'https://next.stg.getplika.com'

const MonitorDetail = ({ moduleWorkflow, moduleExecution, keyExecution }) => {
  const [loadingIframe, setLoadingIframe] = useState(false)

  const handleLoad = () => {
    console.log('Iframe loaded')
    setLoadingIframe(false)
  }

  return (
    <SiderLayout className={'dashboard-home-iframe'}>
      {loadingIframe && <Skeleton active loading={loadingIframe} paragraph={{ rows: 10 }} />}
      <iframe
        id="workflow"
        title="Workflow"
        src={`${FRONT_URL}/iframe/workflow/monitor/${moduleWorkflow}-wk-${moduleExecution}-wk-${keyExecution}?enabled=false`}
        width="100%"
        height="100%"
        style={{
          border: 'none',
          padding: '0 0',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
        onLoad={handleLoad}
      ></iframe>
    </SiderLayout>
  )
}

export default MonitorDetail

import { isEmpty } from 'lodash'
import React, { useState, useEffect } from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Layout,
  Space,
  Dropdown,
  Modal,
  Card,
  List,
  Avatar,
  Badge,
  Spin,
  message,
  Tooltip,
} from 'antd'
import { Typography, ButtonActionBar, Button } from 'modules/core/components'
import {
  isUserAllowed,
  getOperationsByKeys,
  getPeriodOperationsKeys,
  generateMessageError,
  // GA,
} from 'modules/core/utils'
import {
  UserOutlined,
  CalendarOutlined,
  SettingOutlined,
  FileSyncOutlined,
  CommentOutlined,
  CheckCircleOutlined,
  BellOutlined,
  AuditOutlined,
} from '@ant-design/icons'
import { ROLES } from 'modules/core/constants'
import { connect } from 'react-redux'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import configuration from 'modules/configuration'
import planning from 'modules/planning'
import moment from 'moment'
import logoBlack from 'assets/images/logo/logo-black.svg'
import logoCompleto from 'assets/images/logo/logo.svg'

import './AppLayout.scss'

const { Header } = Layout
const { SETTINGS, PLANNING, CONTROL, FORECAST, ANALYSIS } = ROLES

const AppLayout = ({
  children,
  history,
  onLogout,
  loggedUser,
  showIcons = true,
  fetchDimensionsList,
  dimensionsList,
  periodList,
  fetchNotificationsList,
  notificationsList,
  markReaded,
  setShowAllComments,
  showAllComments,
  setHasComment,
  fetchOnlineUsers,
  onlineUsers,
}) => {
  const enableOnlineUsers = useFeatureIsOn('ab_applayout_onlineUsers')
  const abMonitor = useFeatureIsOn('ab-monitor')
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState(600000)
  const { t } = useTranslation()

  useEffect(() => {
    if (enableOnlineUsers) {
      fetchOnlineUsers().then(() => setValue(600000))
    }
  }, [fetchOnlineUsers, value, enableOnlineUsers])

  useEffect(() => {
    if (enableOnlineUsers) {
      setTimeout(() => {
        setValue(0)
      }, value)
    }
  }, [value, enableOnlineUsers])

  useEffect(() => {
    setIsLoading(true)
    fetchNotificationsList({ page }).then((response) => {
      setIsLoading(false)
    })
  }, [fetchNotificationsList, page])

  useEffect(() => {
    fetchDimensionsList()
  }, [fetchDimensionsList])

  const exit = () =>
    onLogout().then(() => {
      history.push('/login')
    })

  const pathLogoComplete = ['/']
  const pathname = window.location.pathname

  const headerMenus = !loggedUser.link_upload_file
    ? [
        {
          ...(dimensionsList.count > 0 && periodList.length > 0
            ? {
                title: t('LABEL_PERIODS'),
                url: '/periodos',
                exact: true,
                icon: <CalendarOutlined />,
                allowedFor: getPeriodOperationsKeys(),
              }
            : {}),
        },
        {
          title: t('LABEL_CONFIG'),
          url: '/configuracion',
          exact: false,
          icon: <SettingOutlined />,
          allowedFor: [SETTINGS],
        },
        {
          title: t('LABEL_COMMENTS'),
          url: '',
          icon: <CommentOutlined />,
          allowedFor: [SETTINGS, PLANNING, CONTROL, FORECAST, ANALYSIS],
        },
        {
          ...(abMonitor
            ? {
                title: 'Monitor',
                url: '/monitor-logs',
                exact: false,
                icon: <AuditOutlined />,
                allowedFor: [SETTINGS, PLANNING, CONTROL, FORECAST, ANALYSIS],
              }
            : {}),
        },
        {
          title: t('LABEL_PROCESS_UPLOAD'),
          url: '/procesosdecarga',
          exact: false,
          icon: <FileSyncOutlined />,
          allowedFor: [SETTINGS, PLANNING, CONTROL, FORECAST, ANALYSIS],
        },
      ]
    : []

  // useEffect(() => {
  //   GA.pageView();
  // });

  const renderLogoBlack = () => (
    <div className="app-brand-logo" onClick={() => history.push('/')}>
      <img alt="brand-logo" src={logoBlack} />
    </div>
  )

  const renderLogoComplete = () => (
    <div className="app-brand-logo-complete" onClick={() => history.push('/')}>
      <img alt="brand-logo" src={logoCompleto} />
    </div>
  )

  const notificationType = (type) => {
    switch (type) {
      case 'comment':
        return CommentOutlined
      case 'mention':
      case 'share':
        return CheckCircleOutlined
      case 'send_notification':
        return BellOutlined
      default:
        return CheckCircleOutlined
    }
  }

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target
    if (scrollTop + clientHeight >= scrollHeight && notificationsList.next) {
      setPage((prevstate) => prevstate + 1)
    }
  }

  const handleClick = (notification) => {
    const { module, key, id: idNotification, type: typeNotify, url: urlNotify } = notification
    let urlRedirect = '/'
    let data = {}

    if (typeNotify === 'share') {
      urlRedirect = `/${urlNotify}`
    } else if (typeNotify === 'send_notification' && urlNotify) {
      urlRedirect = urlNotify
    } else {
      const phases = ['base', 'top_down', 'bottom_up', 'closing']
      const identifyData = key.split(module)[1]
      const type = identifyData.split('-')[1]
      const id = identifyData.split('-')[2]

      let includePhase = false
      let url = ''
      let phase = ''

      phases.forEach((el) => {
        if (module.includes(el)) {
          includePhase = true
          url = module.split(el)[0]
          phase = el
        }
      })

      data = {
        type,
        data: true,
        ...(type !== 'table' && {
          key: module,
          id,
        }),
      }

      urlRedirect = (includePhase ? `${url.slice(0, -1)}?phase=${phase}` : module).replaceAll(
        '-',
        '/',
      )
    }

    markReaded({ notifications: [idNotification] })
      .then(() => {
        setPage(1)
        fetchNotificationsList({ page }).then(() => {
          if (!isEmpty(data)) {
            setHasComment(data)
          }
          history.push(urlRedirect)
        })
      })
      .catch((error) =>
        message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'), 8),
      )
  }

  return (
    <Layout className="app-layout">
      <Header className="app-layout-header">
        {pathLogoComplete.includes(pathname)
          ? dimensionsList.count === 0
            ? renderLogoComplete()
            : renderLogoBlack()
          : renderLogoBlack()}
        {showIcons && (
          <Space className="app-layout-actions" align="center" size="small">
            <Avatar.Group
              maxCount={4}
              maxStyle={{
                color: '#0047ba',
                backgroundColor: '#e1effa',
              }}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              {enableOnlineUsers &&
                onlineUsers
                  .filter((el) => el.pkid !== loggedUser.pkid)
                  .map((el) => (
                    <Tooltip title={el.full_name}>
                      <Avatar
                        size="default"
                        key={el.pkid}
                        src={el.image}
                        style={{
                          backgroundColor: el.color,
                        }}
                      >
                        {`${el?.last_name[0].toUpperCase()}${el?.first_name[0].toUpperCase()}`}
                      </Avatar>
                    </Tooltip>
                  ))}
            </Avatar.Group>
            <Dropdown
              trigger={['click']}
              overlay={
                <div style={{ padding: 0 }}>
                  <Card
                    style={{ width: 400 }}
                    bodyStyle={notificationsList.values.length > 0 ? { padding: 0 } : {}}
                    title={<Typography.Body level={2}>{loggedUser.email}</Typography.Body>}
                    headStyle={{ height: 56 }}
                    actions={[
                      <ButtonActionBar position="start">
                        <Button.Link onClick={() => setShowLogoutModal(true)} title="SIGN_OFF" />
                      </ButtonActionBar>,
                    ]}
                    extra={
                      <Button.Link
                        onClick={() =>
                          history.push(
                            `/configuracion/usuarios/${loggedUser.pkid}/perfil`, //TODO: Reemplazar por ruta de perfil cuando se desarrolle
                          )
                        }
                        title="PROFILE"
                      />
                    }
                  >
                    {notificationsList.values.length > 0 ? (
                      <>
                        <div style={{ overflowY: 'auto', maxHeight: 300 }} onScroll={handleScroll}>
                          <List
                            dataSource={notificationsList.values}
                            renderItem={(notification) => (
                              <List.Item
                                style={{
                                  ...(!notification.is_read && { backgroundColor: '#e1effa' }),
                                  cursor: 'pointer',
                                }}
                                onClick={() => handleClick(notification)}
                                key={notification.id}
                              >
                                <List.Item.Meta
                                  style={{ padding: 5 }}
                                  avatar={
                                    <Space direction="horizontal">
                                      {!notification.is_read ? (
                                        <div
                                          style={{
                                            backgroundColor: '#0047ba',
                                            borderRadius: '50%',
                                            width: 5,
                                            height: 5,
                                          }}
                                        ></div>
                                      ) : (
                                        <div style={{ paddingLeft: 5 }}></div>
                                      )}
                                      <Avatar style={{ backgroundColor: '#0047ba' }}>
                                        {typeof notification.actor === 'object'
                                          ? notification.actor.first_name[0]
                                          : notification.actor[0]}
                                      </Avatar>
                                    </Space>
                                  }
                                  title={
                                    <Space direction="horizontal">
                                      <Typography.Body>
                                        {typeof notification.actor === 'object'
                                          ? notification.actor.full_name
                                          : notification.actor}
                                      </Typography.Body>
                                      <Typography.Body level={3}>
                                        {moment(notification.created_at).calendar()}
                                      </Typography.Body>
                                    </Space>
                                  }
                                  description={
                                    <Space
                                      direction="horizontal"
                                      style={{ display: 'flex', alignItems: 'flex-start' }}
                                    >
                                      <Typography.Icon
                                        level={2}
                                        icon={notificationType(notification.type)}
                                      />
                                      {notification.streamline}
                                    </Space>
                                  }
                                />
                              </List.Item>
                            )}
                          />
                        </div>
                        {isLoading && (
                          <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Spin spinning={isLoading} />
                          </div>
                        )}
                      </>
                    ) : (
                      <Typography.Body level={2}>{t('NO_NOTIFICATIONS_MESSAGE')}</Typography.Body>
                    )}
                  </Card>
                </div>
              }
            >
              <Badge size="small" count={notificationsList.unread_count}>
                <Button.DefaultUser
                  className="app-layout-user-section"
                  title={loggedUser.firstName + ' ' + loggedUser.lastName} //title={t('SESSION')}
                  icon={<UserOutlined />}
                />
              </Badge>
            </Dropdown>
            {headerMenus
              .filter((navItem) => isUserAllowed(getOperationsByKeys(navItem.allowedFor)))
              .map((item, index) => {
                const { icon, url, title, exact } = item
                return url ? (
                  <NavLink
                    exact={exact}
                    to={url}
                    className="header-icon"
                    activeClassName="selected"
                    title={title}
                    key={index}
                  >
                    {icon}
                  </NavLink>
                ) : (
                  <Button.Icon
                    title="LABEL_COMMENTS"
                    icon={
                      <Typography.Icon
                        style={{ color: showAllComments && '#0047ba' }}
                        title={title}
                        level={2}
                        icon={CommentOutlined}
                      />
                    }
                    onClick={() => setShowAllComments(!showAllComments)}
                  />
                )
              })}
          </Space>
        )}
      </Header>
      <Layout className="app-layout-body">{children}</Layout>
      <Modal
        visible={showLogoutModal}
        onCancel={() => setShowLogoutModal(false)}
        onOk={exit}
        cancelText={t('ACTION_CANCEL')}
        okText={t('SIGN_OFF')}
        title={t('SIGN_OFF')}
      >
        {t('SIGN_OFF_CONFIRM_MESSAGE')}
      </Modal>
    </Layout>
  )
}

const mapStateToProps = (state) => ({
  dimensionsList: configuration.selectors.getDimensionsList(state),
  periodList: planning.selectors.getPeriodList(state),
  notificationsList: configuration.selectors.getNotificationsList(state),
  showAllComments: configuration.selectors.getShowAllComments(state),
  onlineUsers: configuration.selectors.getOnlineUsers(state),
})

const mapDispatchToProps = {
  fetchDimensionsList: configuration.actions.fetchDimensionsList,
  fetchNotificationsList: configuration.actions.fetchNotificationsList,
  markReaded: configuration.actions.markReaded,
  setShowAllComments: configuration.actions.setShowAllComments,
  setHasComment: configuration.actions.setHasComment,
  fetchOnlineUsers: configuration.actions.fetchOnlineUsers,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppLayout))

import { Drawer, message, notification, Popconfirm, Popover, Space, Table, Tag } from 'antd'
import {
  Button,
  ButtonActionBar,
  DeleteAndUpdate,
  DropdownMenu,
  InputCell,
  Typography,
} from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { EditOutlined, DeleteOutlined, BgColorsOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { connect } from 'react-redux'
import { generateMessageError } from 'modules/core/utils'
import { CreateTagModal } from './components'
import societies from 'modules/societies'
import { ChromePicker } from 'react-color'

const TagsDrawer = ({ visible, onClose, tagsList, onRefetch, editTag, deleteTag }) => {
  const [editRow, setEditRow] = useState(null)
  const [dataCellEdit, setDataCellEdit] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [showCreateTagModal, setShowCreateTagModal] = useState(false)
  const [deleteTagId, setDeleteTagId] = useState(null)
  const [showPopover, setShowPopover] = useState(false)
  const [color, setColor] = useState('')
  const { t } = useTranslation()

  const onCellEdit = (name, value) => setDataCellEdit({ ...dataCellEdit, [`${name}`]: value })

  const onCancel = () => {
    setEditRow(null)
    setShowPopover(false)
  }

  const onConfirm = () => {
    setEditRow(null)
    setDataCellEdit({})
    setIsSaving(false)
    setShowPopover(false)
  }

  const handleSave = () => {
    const dataTag = tagsList.find((el) => el.id === editRow)
    setIsSaving(true)
    editTag(editRow, { ...dataTag, ...dataCellEdit })
      .then(() => {
        onRefetch().then(() => {
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
          onConfirm()
        })
      })
      .catch((error) => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        })
        onConfirm()
      })
  }

  const handleDelete = () => {
    setIsSaving(true)
    deleteTag(deleteTagId)
      .then(() => {
        onRefetch().then(() => {
          message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
          setDeleteTagId(null)
          setIsSaving(false)
        })
      })
      .catch((error) => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        })
        setDeleteTagId(null)
        setIsSaving(false)
      })
  }

  const columns = [
    {
      title: t('FIELD_TAGS'),
      dataIndex: 'name',
      render: (name, row) => {
        if (editRow && editRow === row.id) {
          return (
            <InputCell
              initialValue={name}
              onCellUpdate={onCellEdit}
              name="name"
              style={{ width: '100%' }}
            />
          )
        }
        return name
      },
    },
    {
      title: 'Color',
      dataIndex: 'color',
      render: (dataColor, row) => {
        if (editRow && editRow === row.id) {
          return (
            <Popover
              placement="bottom"
              trigger="click"
              visible={showPopover}
              content={
                <Space direction="vertical">
                  <ChromePicker
                    disableAlpha
                    color={color}
                    onChangeComplete={(col) => setColor(col.hex)}
                  />
                  <ButtonActionBar position="space-between">
                    <Button.Default title="ACTION_CANCEL" onClick={() => setShowPopover(false)} />
                    <Button.Primary
                      title="ACTION_SAVE"
                      onClick={() => {
                        onCellEdit('color', color)
                        setShowPopover(false)
                      }}
                    />
                  </ButtonActionBar>
                </Space>
              }
            >
              <Button.Icon
                title={t('ACTION_CHANGE_COLOR')}
                icon={<Typography.Icon icon={BgColorsOutlined} />}
                onClick={() => setShowPopover(true)}
              />
            </Popover>
          )
        }
        return <Tag color={row.color}>{row.name}</Tag>
      },
    },
    {
      title: t('FIELD_ACTIONS'),
      dataIndex: 'id',
      align: 'right',
      render: (id) => {
        return id !== editRow ? (
          <Popconfirm
            title={t('SOCIETY_DELETE_TAG_POPCONFIRM_TEXT')}
            okText={t('YES')}
            onConfirm={() => handleDelete()}
            okButtonProps={{ loading: isSaving }}
            cancelText={t('NO')}
            onCancel={() => setDeleteTagId(null)}
            visible={deleteTagId === id}
          >
            <DropdownMenu
              title={t('ACTION_MORE')}
              menu={[
                {
                  title: t('ACTION_EDIT'),
                  icon: <EditOutlined />,
                  onClick: () => {
                    setEditRow(id)
                  },
                },
                {
                  title: t('ACTION_DELETE'),
                  icon: <DeleteOutlined />,
                  onClick: () => setDeleteTagId(id),
                },
              ]}
            />
          </Popconfirm>
        ) : (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onConfirm}
            onSave={() => handleSave()}
            dataCellEdit={dataCellEdit}
            loading={isSaving}
          />
        )
      },
    },
  ]

  return (
    <Drawer title={t('FIELD_TAGS')} visible={visible} onClose={onClose} size="large">
      <Space direction="vertical" style={{ width: '100%' }}>
        <ButtonActionBar>
          <Button.Primary title="ACTION_CREATE_NEW" onClick={() => setShowCreateTagModal(true)} />
        </ButtonActionBar>
        <Table size="small" columns={columns} dataSource={tagsList} loading={isSaving} />
      </Space>
      <CreateTagModal
        visible={showCreateTagModal}
        onClose={() => setShowCreateTagModal(false)}
        isSaving={isSaving}
        setIsSaving={setIsSaving}
        onRefetch={onRefetch}
      />
    </Drawer>
  )
}

const mapDispatchToProps = {
  fetchSocietiesList: societies.actions.fetchSocietiesList,
  downloadASocietiesXLS: societies.actions.downloadASocietiesXLS,
  fetchTagsList: societies.actions.fetchTagsList,
  editTag: societies.actions.editTag,
  deleteTag: societies.actions.deleteTag,
  createTag: societies.actions.createTag,
}

export default connect(null, mapDispatchToProps)(TagsDrawer)

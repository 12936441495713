import imgEbook from 'assets/images/compositions/ebook.svg'
import imgArticle from 'assets/images/compositions/articulo.svg'
import imgMetrics from 'assets/images/compositions/metricas.svg'
import imgNewFunctions from 'assets/images/compositions/nuevas_funciones.svg'

const COLUMN_TYPE_LIST = [
  {
    id: 'dimension-name',
    name: 'HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_DIMENSION_NAME',
  },
  {
    id: 'dimension-code',
    name: 'HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_DIMENSION_CODE',
  },
  {
    id: 'transaction-date',
    name: 'HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_DIMENSION_DATE',
  },
  {
    id: 'transaction-amount',
    name: 'HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_TRANSACTION_AMOUNT',
  },
  {
    id: 'transaction-price',
    name: 'HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_TRANSACTION_PRICE',
  },
  {
    id: 'transaction-quantity',
    name: 'HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_TRANSACTION_QUANTITY',
  },
  {
    id: 'transaction-currency',
    name: 'HOME_INITIAL_STEPS_SELECT_DATA_TRANSACTION_FILE_TYPE_COLUMN_TRANSACTION_CURRENCY',
  },
]

const FORMAT_DATE = [
  {
    id: 'dd/mm/yyyy',
    name: 'dd/mm/yyyy',
  },
  {
    id: 'mm/dd/yyyy',
    name: 'mm/dd/yyyy',
  },
]

const CONTACTS = [
  {
    id: 'linkedin',
    title: 'Linkedin',
    description: 'https://ar.linkedin.com/company/plika',
    link: 'https://ar.linkedin.com/company/plika',
  },
  {
    id: 'whatsapp',
    title: 'WhatsApp',
    description: '+54 9 2213997986',
    link: 'https://wa.me/5492213997986',
  },
  {
    id: 'email',
    title: 'Email',
    description: 'plika.servicios@bluedraft.com.ar',
    link: 'mailto:plika.servicios@bluedraft.com.ar',
  },
]

const TRAINING = [
  {
    id: 'usermanual',
    title: 'LABEL_USER_MANUAL',
    description: 'HOME_GENERAL_MANUAL_LIST_DESCRIPTION',
    link: 'https://drive.google.com/drive/folders/1XfPXOzTSb9Lb1fsejaAXZ3kr_3cBfEfE',
  },
  {
    id: 'site',
    title: 'HOME_GENERAL_SITE_LIST_TITLE',
    description: 'HOME_GENERAL_SITE_LIST_DESCRIPTION',
    link: 'https://sites.google.com/bluedraft.com.ar/plikacapacitacionautog/inicio',
  },
  {
    id: 'faqs',
    title: 'HOME_GENERAL_QUESTIONS_LIST_TEXT',
    description: 'HOME_GENERAL_QUESTIONS_LIST_DESCRIPTION',
    link: 'https://sites.google.com/bluedraft.com.ar/plikacapacitacionautog/herramientas-adicionales/faqs',
  },
]

const CARDS_NEWS = [
  {
    id: 1,
    name: 'Ebook',
    description: 'DASHBOARD_EBOOK_DESCRIPTION',
    link: 'https://getplika.com/wp-content/uploads/2022/06/Plika-E-book-Junio.pdf',
    img: imgEbook,
    textoBoton: 'DASHBOARD_GO_EBOOK_LINK',
  },
  {
    id: 2,
    name: 'DASHBOARD_LABEL_ARTICLES',
    description: 'DASHBOARD_ARTICLE_DESCRIPTION',
    link: 'https://getplika.com/blog/',
    img: imgArticle,
    textoBoton: 'DASHBOARD_GO_BLOG_LINK',
  },
  {
    id: 3,
    name: 'DASHBOARD_LABEL_DICTIONARY',
    description: 'DASHBOARD_METRICS_DESCRIPTION',
    link: 'https://getplika.com/diccionarios/',
    img: imgMetrics,
    textoBoton: 'DASHBOARD_GO_METRICS_LINK',
  },
  {
    id: 4,
    name: 'DASHBOARD_LABEL_NEW_FUNCTIONS',
    description: 'DASHBOARD_NEW_FUNCTIONS_DESCRIPTION',
    link: 'https://www.youtube.com/playlist?list=PLJo0bl0cWTANbbnIJAswNCWHOzZ08yGwm',
    img: imgNewFunctions,
    textoBoton: 'DASHBOARD_GO_NEW_FUNCTIONS_LINK',
  },
]

const MAPPING_DATA = {
  COLUMN_TYPE_LIST,
  FORMAT_DATE,
}

const LIST_DATA_DASHBOARD = {
  CONTACTS,
  CARDS_NEWS,
  TRAINING,
}

const HOME = { MAPPING_DATA, LIST_DATA_DASHBOARD }

export default HOME

import React, { useState, useEffect } from 'react'
import { Row, Col, Spin, Input } from 'antd'
import { Button, ButtonActionBar, EmptyScreen, Navigator } from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { AdminLayout } from 'modules/core/layouts'
import {
  Filters,
  SocietyCreateModal,
  SocietyTable,
  TagsDrawer,
  TopTableActions,
} from './components'
import { usePagination } from 'modules/core/customHooks'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import emptyStateImage from 'assets/images/compositions/empty-big.svg'
import societies from 'modules/societies'
import moment from 'moment'

import './SocietiesList.scss'

const { Search } = Input

const SocietiesList = ({
  societiesList,
  fetchSocietiesList,
  downloadASocietiesXLS,
  fetchTagsList,
  tagsList,
}) => {
  const enableTags = useFeatureIsOn('ab_superadmin_tags')
  const [searchValue, setSearchValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [tablePagination, setTablePagination] = usePagination()
  const [showDrawer, setShowDrawer] = useState(false)
  const [selectTags, setSelectTags] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    fetchTagsList()
  }, [fetchTagsList])

  useEffect(() => {
    setIsLoading(true)
    fetchSocietiesList({
      ...(searchValue && {
        search: searchValue,
      }),
      ...tablePagination,
      tags: selectTags,
    }).then(() => setIsLoading(false))
    return () => {
      setIsLoading(false)
    }
  }, [fetchSocietiesList, tablePagination, searchValue, selectTags])

  const handleOnChangeTable = (pagination, filters, sorter) => {
    setTablePagination(pagination)
  }

  const renderHeader = () => (
    <Navigator
      floated={false}
      size="big"
      items={[
        {
          name: t('SOCIETY_LABEL'),
          url: `/empresas`,
        },
      ]}
    />
  )

  const renderEmptyScreen = () => (
    <EmptyScreen
      image={emptyStateImage}
      title={t('SOCIETY_LABEL')}
      description={t('SOCIETY_EMPTY_SCREEN_DESCRIPTION')}
      footer={
        <SocietyCreateModal
          onRefetchSocietiesList={() =>
            fetchSocietiesList({
              ...(searchValue && {
                search: searchValue,
              }),
              ...tablePagination,
            })
          }
        />
      }
    />
  )

  const renderUserActions = () => (
    <ButtonActionBar>
      {!enableTags && (
        <Search
          className="society-search"
          placeholder={t('ACTION_SEARCH')}
          allowClear={true}
          onSearch={setSearchValue}
        />
      )}
      <SocietyCreateModal
        onRefetchSocietiesList={() =>
          fetchSocietiesList({
            ...(searchValue && {
              search: searchValue,
            }),
            ...tablePagination,
          })
        }
      />
      {enableTags && <Button.Default title={t('FIELD_TAGS')} onClick={() => setShowDrawer(true)} />}
    </ButtonActionBar>
  )

  const renderTopTableActions = () => (
    <TopTableActions
      onClick={() =>
        downloadASocietiesXLS(t('SOCIETY_FILE_NAME', { date: moment().format('lll') }))
      }
    />
  )

  return (
    <AdminLayout>
      <div className="societies-list-container">
        <Spin spinning={isLoading} />
        <Row gutter={[24, 8]}>
          <Col span={24}>{renderHeader()}</Col>
          {societiesList.count === 0 && !searchValue && !isLoading && (
            <Col span={24}>{renderEmptyScreen()}</Col>
          )}
          {(societiesList.count > 0 || (searchValue && societiesList.count === 0)) && (
            <>
              <Col span={24}>{renderUserActions()}</Col>
              {enableTags && (
                <Col span={24}>
                  <Filters
                    setSearchValue={setSearchValue}
                    setSelectTags={setSelectTags}
                    tagsList={tagsList}
                  />
                </Col>
              )}
              <Col span={24}>{renderTopTableActions()}</Col>
              <Col span={24}>
                <SocietyTable
                  onFetchSocietiesList={() =>
                    fetchSocietiesList({
                      ...(searchValue && {
                        search: searchValue,
                      }),
                      ...tablePagination,
                    })
                  }
                  dataSource={societiesList.values}
                  loading={isLoading}
                  onChange={handleOnChangeTable}
                  pagination={tablePagination}
                  count={societiesList.count}
                  tagsList={tagsList}
                />
              </Col>
            </>
          )}
        </Row>
        <TagsDrawer
          visible={showDrawer}
          onClose={() => setShowDrawer(false)}
          tagsList={tagsList}
          onRefetch={() => fetchTagsList()}
        />
      </div>
    </AdminLayout>
  )
}

const mapStateToProps = (state) => ({
  societiesList: societies.selectors.getSocietiesList(state),
  tagsList: societies.selectors.getTagsList(state),
})

const mapDispatchToProps = {
  fetchSocietiesList: societies.actions.fetchSocietiesList,
  downloadASocietiesXLS: societies.actions.downloadASocietiesXLS,
  fetchTagsList: societies.actions.fetchTagsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(SocietiesList)

import React from 'react'
import { Carousel } from 'antd'
import { ReportDetailKPICard } from './components'
import { CarrouselArrow } from 'modules/core/components'

const KpiCarrousel = ({
  dataKPI,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  commentKey,
  currencyData,
}) => {
  return (
    <Carousel
      className="report-kpi-carousel"
      style={{ marginLeft: 30, marginRight: 30 }}
      slidesToShow={4}
      slidesToScroll={1}
      initialSlide={0}
      infinite={false}
      dots={false}
      arrows={true}
      nextArrow={<CarrouselArrow.Next />}
      prevArrow={<CarrouselArrow.Previus />}
      responsive={[
        {
          breakpoint: 1580,
          settings: { slidesToShow: 4, slidesToScroll: 1 },
        },
        {
          breakpoint: 1350,
          settings: { slidesToShow: 3, slidesToScroll: 1 },
        },
        {
          breakpoint: 1100,
          settings: { slidesToShow: 2, slidesToScroll: 1 },
        },
        {
          breakpoint: 850,
          settings: { slidesToShow: 1, slidesToScroll: 1 },
        },
      ]}
    >
      {dataKPI.map((kpi, index) => {
        const { id, title, value, prefix, name, children } = kpi
        return (
          <ReportDetailKPICard
            key={index}
            title={title}
            value={value}
            prefix={prefix}
            name={name}
            id={id}
            subkpis={children}
            setDataComments={setDataComments}
            dataComments={dataComments}
            onRefetchComments={onRefetchComments}
            loadingComments={loadingComments}
            commentKey={commentKey}
            currencyData={currencyData}
          />
        )
      })}
    </Carousel>
  )
}

export default KpiCarrousel

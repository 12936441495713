import React from 'react'
import { DownloadOutlined, CommentOutlined, DollarOutlined } from '@ant-design/icons'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { Space } from 'antd'
import {
  ButtonActionBar,
  Comments,
  DropdownMenu,
  FavouriteIcon,
  FavouriteSelect,
  References,
  TableSettingsModal,
  Typography,
} from 'modules/core/components'
import { ROLES, TABLES } from 'modules/core/constants'
import { isUserAllowed } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'

const { ANALYSIS__REPORTSPNL__VIEW } = ROLES

const TableActions = ({
  setPeriodTableSettings,
  periodTableSettings,
  onClick,
  onClickDownloadSpreadsheets,
  onClickSaveFav,
  visibleFav,
  onClickSave,
  onClickCancelSaveFav,
  setFavouriteName,
  favouriteName,
  isLoading,
  hasFavourite = false,
  options = [],
  canSave,
  viewSelect = undefined,
  setViewSelect,
  alreadyExist = false,
  layoutLoading,
  setPrimary,
  onClickDeleteFav,
  showComments,
  hasComment,
  setHasComment,
  setViewComment,
  userDetail,
  settings,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  onCommentCreate,
  removeNewComment,
  currencyData,
  onClickEditNameFav,
}) => {
  // Feature flag
  const enabledEvolutionTable = useFeatureIsOn('feature-react-pivot-evolution')

  // Hooks
  const { t } = useTranslation()

  // Methods
  const dowload = {
    spreadsheets: onClickDownloadSpreadsheets,
    file: onClick,
  }

  return (
    <ButtonActionBar position="space-between">
      <References
        referencesItems={[
          {
            color: '',
            type: '123',
            title: t('LABEL_TYPE_VALUES_REFERENCE', {
              type: settings?.aggregatorName?.toLowerCase() || t('FIELD_DECIMALS').toLowerCase(),
            }),
          },
          {
            color: '#a73ba1',
            type: 'Ab',
            title: t('LABEL_RATIO'),
            description: t('REPORT_VALUES_RATIO'),
          },
          ...(currencyData
            ? [
                {
                  type: <DollarOutlined />,
                  title: currencyData && currencyData.code,
                  description:
                    currencyData &&
                    t('REFERENCE_DESCRIPTION', {
                      currencyName: t(currencyData.name).toLowerCase(),
                    }),
                },
              ]
            : []),
        ]}
      />
      <Space direction="horizontal">
        {canSave && (
          <FavouriteIcon
            visible={visibleFav}
            favouriteName={favouriteName}
            setFavouriteName={setFavouriteName}
            onClickCancelSaveFav={onClickCancelSaveFav}
            onClickSave={onClickSave}
            loading={isLoading}
            alreadyExist={alreadyExist}
            onClickSaveFav={onClickSaveFav}
            options={options}
          />
        )}
        {hasFavourite && (
          <FavouriteSelect
            viewSelect={viewSelect}
            loading={layoutLoading}
            options={options}
            setPrimary={setPrimary}
            setViewSelect={setViewSelect}
            onClickDeleteFav={onClickDeleteFav}
            onClickEditNameFav={onClickEditNameFav}
          />
        )}
        {showComments && (
          <Comments
            hasComment={hasComment.table}
            onClick={(visible) => {
              setHasComment({ type: 'table', data: visible })
              !visible && removeNewComment()
              !visible && setViewComment(visible)
            }}
            comments={dataComments}
            setData={setDataComments}
            loading={loadingComments}
            onRefetchComments={onRefetchComments}
            onCommentCreate={onCommentCreate}
            onCleanHasComment={() => {
              setHasComment({ type: 'table', data: false })
              setViewComment(false)
            }}
          >
            <Typography.Icon
              style={{ cursor: 'pointer' }}
              title={t('LABEL_COMMENTS')}
              icon={CommentOutlined}
            />
          </Comments>
        )}
        <DropdownMenu
          title={t('ACTION_DOWNLOAD_TABLE')}
          placement="bottomRight"
          icon={<Typography.Icon icon={DownloadOutlined} />}
          menu={TABLES.OPTIONS_DOWNLOAD.map((formatType) => {
            return {
              title: formatType.name,
              key: formatType.id,
              disabled: !isUserAllowed(ANALYSIS__REPORTSPNL__VIEW),
              onClick: dowload[formatType.id],
            }
          })}
        />
        {!enabledEvolutionTable && (
          <TableSettingsModal onConfirm={setPeriodTableSettings} settings={periodTableSettings} />
        )}
      </Space>
    </ButtonActionBar>
  )
}

export default TableActions

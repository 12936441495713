import React from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import MonitorDetail from '../../../monitor/monitor-detail'

const MonitorWorkflow = () => {
  let { step, projectionId } = useParams()

  return (
    <MonitorDetail
      moduleWorkflow={'transactions_file_upload'}
      moduleExecution={'planning'}
      keyExecution={`${projectionId}-${step}`}
    />
  )
}

export default connect(null, null)(MonitorWorkflow)

import { Card, Col, List, Row, Space, Tabs } from 'antd'
import { Typography } from 'modules/core/components'
import { useQuery } from 'modules/core/customHooks'
import { SiderLayout } from 'modules/core/layouts'
import { PlayCircleOutlined } from '@ant-design/icons'
import { dataCards } from './dataCards'
import { useState } from 'react'
import { VideoModal } from './components'
import { useTranslation } from 'react-i18next'

import './videosHome.scss'

const VideosHome = () => {
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [srcVideo, setSrcVideo] = useState('')
  const { t } = useTranslation()
  let query = useQuery()

  const renderContent = (dataSource) => {
    return (
      <List
        grid={{
          gutter: 24,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 5,
        }}
        dataSource={dataSource}
        renderItem={(item) => (
          <List.Item key={item.id}>
            <Card
              bodyStyle={{ padding: 10, minHeight: 305, display: 'flex', justifyContent: 'center' }}
              className="card-module"
              onClick={() => {
                setShowVideoModal(true)
                setSrcVideo(item.src)
              }}
            >
              <Space direction="vertical" style={{ display: 'flex', alignItems: 'center' }}>
                <img style={{ padding: 5 }} width={270} src={item.image} alt="" />
                <div style={{ padding: 5 }}>
                  <Typography.Body level={3}>{t(item.text)}</Typography.Body>
                </div>
              </Space>
            </Card>
          </List.Item>
        )}
      />
    )
  }

  const tabs = [
    {
      id: 'config',
      name: 'LABEL_CONFIG',
      content: renderContent(dataCards['config']),
    },
    {
      id: 'planning',
      name: 'LABEL_PLANNING',
      content: renderContent(dataCards['planning']),
    },
    {
      id: 'control',
      name: 'LABEL_CONTROL',
      content: renderContent(dataCards['control']),
    },
    {
      id: 'forecast',
      name: 'LABEL_FORECAST',
      content: renderContent(dataCards['forecast']),
    },
    {
      id: 'analysis',
      name: 'LABEL_ANALYSIS',
      content: renderContent(dataCards['analysis']),
    },
  ]

  return (
    <SiderLayout>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Space direction="horizontal">
            <Typography.Icon icon={PlayCircleOutlined} />
            <Typography.Headline type="primary" level={4}>
              {t('LABEL_PLIKA_VIDEOS')}
            </Typography.Headline>
          </Space>
        </Col>
        <Col span={24}>
          <Tabs
            size="small"
            defaultActiveKey={query.get('tab') !== null ? query.get('tab') : 'config'}
          >
            {tabs.map((tab) => (
              <Tabs.TabPane tab={t(tab.name)} key={tab.id}>
                {tab.content}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
      <VideoModal
        visible={showVideoModal}
        onClose={() => {
          setShowVideoModal(false)
          setSrcVideo('')
        }}
        srcVideo={srcVideo}
      />
    </SiderLayout>
  )
}

export default VideosHome

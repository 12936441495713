import React, { useEffect, useState } from 'react'
import { Modal, Input, Form, Select, Row, Col, Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import { generateSelectOptions } from 'modules/core/utils'
import { connect } from 'react-redux'
import configuration from 'modules/configuration'
import { onManagement } from './utils'
import { Typography } from 'modules/core/components'

const ManagementModal = ({
  visible,
  onCancel,
  groupAliasDimension,
  onFetchDimensionsValues,
  dimensionId,
  type,
  fetchAllDimensionsValues,
  allDimensionsValues,
  fetchAllDimensionsValuesMap,
  allDimensionsValuesMap,
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  useEffect(() => {
    if (visible) {
      fetchAllDimensionsValues({
        id_dimension: dimensionId,
        type: type === 'origin' || type === 'unmapped' ? 'origin' : 'destination',
      })
    }
  }, [fetchAllDimensionsValues, dimensionId, visible, type])

  useEffect(() => {
    if (visible) {
      fetchAllDimensionsValuesMap({
        id_dimension: dimensionId,
        type: type === 'origin' || type === 'unmapped' ? 'destination' : 'origin',
      })
    }
  }, [fetchAllDimensionsValuesMap, dimensionId, visible, type])

  const onClose = () => {
    form.resetFields()
    setIsSaving(false)
    onCancel()
  }

  const TYPE_NAME = {
    origin: 'CONFIG_DIMENSIONS_VALUES_MANAGEMENT_LABEL',
    destination: 'CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_ORIGIN',
    unmapped: 'CONFIG_DIMENSIONS_VALUES_MANAGEMENT_LABEL',
  }

  return (
    <Modal
      title={t('CONFIG_DIMENSIONS_VALUES_MAP_DATA_MODAL_TITLE')}
      visible={visible}
      onCancel={onClose}
      cancelText={t('ACTION_CANCEL')}
      onOk={() =>
        onManagement({
          form,
          setIsLoading: setIsSaving,
          groupAliasDimension: (values) =>
            groupAliasDimension({
              ...values,
              group_dimension: values.group_dimension.map((id) => parseInt(id)),
              id_dimension: parseInt(dimensionId),
            }),
          onRefetch: onFetchDimensionsValues,
          onClose,
        })
      }
      okText={t('ACTION_SAVE')}
      centered
      destroyOnClose={true}
      okButtonProps={{ loading: isSaving, disabled: isSaving }}
      cancelButtonProps={{ disabled: isSaving }}
    >
      <Form form={form} layout="vertical">
        {() => (
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Form.Item
                label={t('CONFIG_DIMENSIONS_VALUES_SELECT_FROM_SELECT_LABEL')}
                name={
                  type === 'origin' || type === 'unmapped'
                    ? 'group_dimension'
                    : 'id_dimension_value'
                }
                rules={[
                  {
                    required: true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
              >
                {type === 'origin' || type === 'unmapped' ? (
                  <Select
                    placeholder={t('ACTION_SELECT')}
                    allowClear
                    showSearch
                    showArrow
                    mode="multiple"
                    maxTagTextLength={8}
                    maxTagCount={1}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={generateSelectOptions({
                      options: allDimensionsValues.map((data) => {
                        return { id: data.id, name: data.name_origin, code: data.code_origin }
                      }),
                    })}
                  />
                ) : (
                  <Select
                    placeholder={t('ACTION_SELECT')}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={generateSelectOptions({
                      options: allDimensionsValues.map((data) => {
                        return {
                          id: data.id,
                          name: data.name_destination,
                          code: data.code_destination,
                        }
                      }),
                    })}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Typography.Body level={2}>
                {t('CONFIG_DIMENSIONS_VALUES_SUBTITLE_1_MAP_DATA_MODAL_TEXT', {
                  type: t(TYPE_NAME[type]).toLowerCase(),
                })}
              </Typography.Body>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('CONFIG_DIMENSIONS_FIELD_DESTINATION_NAME')}
                name="name"
                rules={[
                  {
                    required:
                      ((type === 'origin' || type === 'unmapped') &&
                        form.getFieldsValue().id_dimension_value) ||
                      (type === 'destination' && form.getFieldsValue().group_dimension)
                        ? false
                        : true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
              >
                <Input
                  disabled={
                    ((type === 'origin' || type === 'unmapped') &&
                      form.getFieldsValue().id_dimension_value) ||
                    (type === 'destination' && form.getFieldsValue().group_dimension)
                  }
                  placeholder={t('ENTER_NAME_PLACEHOLDER')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t('CONFIG_DIMENSIONS_FIELD_DESTINATION_CODE')}
                name="code"
                rules={[
                  {
                    required:
                      ((type === 'origin' || type === 'unmapped') &&
                        form.getFieldsValue().id_dimension_value) ||
                      (type === 'destination' && form.getFieldsValue().group_dimension)
                        ? false
                        : true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
              >
                <Input
                  disabled={
                    ((type === 'origin' || type === 'unmapped') &&
                      form.getFieldsValue().id_dimension_value) ||
                    (type === 'destination' && form.getFieldsValue().group_dimension)
                  }
                  placeholder={t('ENTER_CODE_PLACEHOLDER')}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Divider plain>{t('LABEL_OR')}</Divider>
            </Col>
            <Col span={24}>
              <Typography.Body level={2}>
                {t('CONFIG_DIMENSIONS_VALUES_SUBTITLE_2_MAP_DATA_MODAL_TEXT', {
                  type: t(TYPE_NAME[type]).toLowerCase(),
                })}
              </Typography.Body>
            </Col>
            <Col span={24}>
              <Form.Item
                label={t('CONFIG_DIMENSIONS_VALUES_SELECT_DESTINATION_VALUES_FROM_SELECT_LABEL', {
                  type: t(TYPE_NAME[type]).toLowerCase(),
                })}
                name={
                  type === 'origin' || type === 'unmapped'
                    ? 'id_dimension_value'
                    : 'group_dimension'
                }
                rules={[
                  {
                    required:
                      form.getFieldsValue().name || form.getFieldsValue().code ? false : true,
                    message: t('REQUIRED_FIELD'),
                  },
                ]}
              >
                {type === 'origin' || type === 'unmapped' ? (
                  <Select
                    placeholder={t('ACTION_SELECT')}
                    allowClear
                    showSearch
                    disabled={form.getFieldsValue().name || form.getFieldsValue().code}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={generateSelectOptions({
                      options: allDimensionsValuesMap.map((el) => {
                        return { id: el.id, name: el.name_destination, code: el.code_destination }
                      }),
                    })}
                  />
                ) : (
                  <Select
                    placeholder={t('ACTION_SELECT')}
                    allowClear
                    showSearch
                    showArrow
                    mode="multiple"
                    maxTagTextLength={8}
                    maxTagCount={1}
                    disabled={form.getFieldsValue().name || form.getFieldsValue().code}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={generateSelectOptions({
                      options: allDimensionsValuesMap.map((el) => {
                        return { id: el.id, name: el.name_origin, code: el.code_origin }
                      }),
                    })}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  allDimensionsValues: configuration.selectors.getAllDimensionsValues(state),
  allDimensionsValuesMap: configuration.selectors.getAllDimensionsValuesMap(state),
})

const mapDispatchToProps = {
  groupAliasDimension: configuration.actions.groupAliasDimension,
  fetchAllDimensionsValues: configuration.actions.fetchAllDimensionsValues,
  fetchAllDimensionsValuesMap: configuration.actions.fetchAllDimensionsValuesMap,
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagementModal)

import React, { useState } from 'react'
import { Modal, Form, Row, Col, Input, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { onCreateDimensionValue } from './utils'
import { generateSelectOptions } from 'modules/core/utils'
import { AddAndDeleteFormItem } from 'modules/core/components'
import configuration from 'modules/configuration'

const ConfigurationCreateModal = ({
  visible,
  onCancel,
  createDimensionValueMasive,
  onFetchDimensionValues,
  dimensionId,
}) => {
  const [isLoadingModal, setIsLoadingModal] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onClose = () => {
    form.resetFields()
    onCancel()
    setIsLoadingModal(false)
  }

  return (
    <Modal
      title={t('CONFIG_DIMENSIONS_VALUES_MODAL_CREATE_NEW_VALUE_TITLE')}
      visible={visible}
      width={560}
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
      centered
      okText={t('ACTION_CREATE')}
      cancelText={t('ACTION_CANCEL')}
      onOk={() =>
        onCreateDimensionValue({
          form,
          setIsLoading: setIsLoadingModal,
          createDimensionValue: (values) =>
            createDimensionValueMasive(
              parseInt(dimensionId),
              { type: values.type },
              values.dimensionValue,
            ),
          onRefetch: onFetchDimensionValues,
          onClose,
        })
      }
      onCancel={onClose}
      okButtonProps={{ loading: isLoadingModal }}
      cancelButtonProps={{ disabled: isLoadingModal }}
    >
      <Form form={form} layout="vertical" initialValues={{ dimensionValue: [''] }}>
        {() => (
          <>
            <Form.Item
              name="type"
              label={t('FIELD_TYPE')}
              rules={[
                {
                  required: true,
                  message: t('REQUIRED_FIELD'),
                },
              ]}
            >
              <Select
                placeholder={t('ACTION_SELECT')}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                showSearch
                options={generateSelectOptions({
                  options: [
                    {
                      id: 'origin',
                      name: t('CONFIG_RULES_FORMULA_VALUES_STEP_FORMULA_LABEL_ORIGIN'),
                    },
                    {
                      id: 'destination',
                      name: t('CONFIG_DIMENSIONS_VALUES_MANAGEMENT_LABEL'),
                    },
                  ],
                })}
              />
            </Form.Item>
            <Form.List name="dimensionValue">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => {
                      return (
                        <div key={field.key}>
                          <Row gutter={24} key={field.key} justify="end">
                            <Col span={12}>
                              <Form.Item
                                name={[field.name, 'code']}
                                label={[field.label, t('FIELD_CODE')]}
                                rules={[
                                  {
                                    required: true,
                                    message: t('REQUIRED_FIELD'),
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={t('CONFIG_DIMENSIONS_VALUES_INPUT_CODE_PLACEHOLDER')}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name={[field.name, 'name']}
                                label={[field.label, t('FIELD_NAME')]}
                                rules={[
                                  {
                                    required: true,
                                    message: t('REQUIRED_FIELD'),
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={t('CONFIG_DIMENSIONS_VALUES_INPUT_NAME_PLACEHOLDER')}
                                ></Input>
                              </Form.Item>
                            </Col>
                            <AddAndDeleteFormItem
                              fieldsLength={fields.length}
                              index={index}
                              addData={{
                                onClick: () => add(),
                                buttonName: 'CONFIG_DIMENSIONS_VALUE_ADD_NEW_ACTION',
                              }}
                              deleteData={{
                                onClick: () => {
                                  remove(field.name)
                                },
                                buttonName: 'ACTION_DELETE',
                              }}
                            />
                          </Row>
                        </div>
                      )
                    })}
                  </div>
                )
              }}
            </Form.List>
          </>
        )}
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  createDimensionValueMasive: configuration.actions.createDimensionValueMasive,
}

export default connect(null, mapDispatchToProps)(ConfigurationCreateModal)

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { notification } from 'antd'
import { UploadModal } from 'modules/core/components'
import { generateMessageError } from 'modules/core/utils'

const ConfigurationUploadModal = ({
  title,
  visible,
  onCancel,
  onSuccessUpload,
  uploadValuesXLS,
  setIsTableLoading,
  nameConfig = '',
  downloadTemplateFile = null,
  extraBody = null,
  afterOnClose = null,
}) => {
  const [isUploadingFile, setIsUploadingFile] = useState(false)
  const [fileList, setFileList] = useState([])
  const { t } = useTranslation()

  const onCloseUploadModal = () => {
    onCancel()
    setFileList([])
    setIsUploadingFile(false)
  }

  const onConfirmUpload = () => {
    setIsUploadingFile(true)
    setIsTableLoading(true)
    uploadValuesXLS(fileList)
      .then((resp) => {
        notification.success({
          message: t('FEEDBACK_VERY_GOOD'),
          description: t('FEEDBACK_UPLOAD_FILE_SUCCESS', {
            configModule: nameConfig,
          }),
          duration: 0,
        })
        onCloseUploadModal()
        onSuccessUpload()
      })
      .catch((error) => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        })
        setIsUploadingFile(false)
        setIsTableLoading(false)
        onCloseUploadModal()
      })
  }

  const onAddUploadFile = (file) => {
    setFileList([file])
    return false
  }

  return (
    <UploadModal
      title={title}
      visible={visible}
      onCancel={onCloseUploadModal}
      onConfirm={onConfirmUpload}
      uploadProps={{
        name: 'file',
        accept: '.xls, .xlsx',
        showUploadList: { showPreviewIcon: false },
        beforeUpload: onAddUploadFile,
        onRemove: (file) => setFileList([]),
        fileList: fileList,
      }}
      extraBody={extraBody}
      isUploadingFile={isUploadingFile}
      afterOnClose={afterOnClose}
      downloadTemplateFile={downloadTemplateFile}
    />
  )
}

export default ConfigurationUploadModal

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useState } from 'react'
import { Card, Dropdown, Menu } from 'antd'
import { connect } from 'react-redux'
import { CommentOutlined } from '@ant-design/icons'
import { isApproved, isUserAllowed } from 'modules/core/utils'
import { CardBodyKpi, CardData, CardExtra, CardFooter, CardTitle, EmptyKpis } from './components'
import { handleDelete, handleDuplicate, handleEdit, handleOnChange } from './utils'
import { useSortable } from '@dnd-kit/sortable'
import { useParams } from 'react-router-dom'
import login from 'modules/login'
import configuration from 'modules/configuration'
import _ from 'lodash'

import './ProjectionCard.scss'
import { CONCEPTS, GENERAL, PROJECTION } from 'modules/core/constants'
import { useTranslation } from 'react-i18next'
import Typography from 'modules/core/components/Typography/Typography'

const isGlobal = (type) => type === 'globalBudget' || type === 'globalForecast'

const ProjectionCard = ({
  budgetId,
  title,
  conceptId = null,
  kpi = null,
  type,
  role,
  status,
  onOpen,
  onOpenActivities,
  reloadList,
  is_closing,
  onEditName,
  onDeleteBudget,
  extraFooter,
  duplicateCard,
  dataLineChart = [],
  dataRankingUp = [],
  allDimensionsList = [],
  dimension,
  setDimensionId,
  defaultColor,
  changeCardColor,
  module,
  budgets_ids,
  conceptName,
  hasComment,
  setHasComment,
  loggedUser,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  setIsSaving,
}) => {
  const enabledRevampCard = useFeatureIsOn('feature-revamp-card')
  const extraConcept = useFeatureIsOn('feature-extra-concept')
  const enableKpis = useFeatureIsOn('feature-visible-kpi')
  const [titleValue, setTitleValue] = useState(title)
  const [budgetDelete, setBudgetDelete] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [editName, setEditName] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [color, setColor] = useState(defaultColor)
  const [showPopover, setShowPopover] = useState(false)
  const [viewComment, setViewComment] = useState(false)

  const { t } = useTranslation()

  let { periodId } = useParams()

  const { isDragging, attributes, listeners, setNodeRef, transform } = useSortable({ id: budgetId })

  const auxKey = window.location.pathname.replaceAll('/', '-')
  const key = !auxKey.includes('?tab=') ? `${auxKey}?tab=${type}` : auxKey

  const draggableStyle = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        userSelect: 'none',
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
      }
    : undefined

  const tagStyle = {
    cursor: transform ? 'move' : 'pointer',
  }

  const auxIdOrType = conceptId ? conceptId : type

  const onCloseEdit = () => {
    setEditName(false)
    setIsEditing(false)
  }

  const onCloseDelete = () => {
    setIsDeleting(false)
    setBudgetDelete(false)
  }

  const onDuplicate = () => {
    if (isGlobal(type)) {
      const aux = budgets_ids.map((el) => {
        const id = _.keys(el)[0]
        return {
          concept_id: id,
          ...(type === 'globalBudget'
            ? {
                planning_id: el[id],
              }
            : {
                forecast_id: el[id],
              }),
        }
      })
      return duplicateCard({ name: title, period_id: periodId, overall_budget: aux })
    } else {
      return duplicateCard(budgetId)
    }
  }

  const permission = [
    PROJECTION.GLOBAL_BUDGET.toString(),
    PROJECTION.GLOBAL_FORECAST.toString(),
    CONCEPTS.IDS.SALES_ID.toString(),
    CONCEPTS.IDS.EXPENSES_ID.toString(),
    CONCEPTS.IDS.COSTS_ID.toString(),
    CONCEPTS.IDS.HUMAN_RESOURCES_ID.toString(),
    CONCEPTS.IDS.OTHER_5_ID.toString(),
    CONCEPTS.IDS.OTHER_6_ID.toString(),
  ]

  const verifyABM = (concept_id) => {
    if (permission.includes(concept_id.toString())) {
      return isUserAllowed(role[concept_id].ABM)
    } else if (extraConcept) {
      return true
    }

    return false
  }

  const verifyVIEW = (concept_id) => {
    if (permission.includes(concept_id.toString())) {
      return isUserAllowed(role[concept_id].VIEW)
    } else if (extraConcept) {
      return true
    }

    return false
  }

  const renderCardExtra = () => (
    <CardExtra
      status={status}
      title={title}
      loading={isDeleting}
      type={type} // Pasa la prop 'type' al componente CardExtra
      onConfirmPopconfirm={() =>
        handleDelete({
          setIsLoading: setIsDeleting,
          onDeleteBudget: () => onDeleteBudget(budgetId),
          onRefetch: reloadList,
          onClose: onCloseDelete,
        })
      }
      onCancelPopconfirm={() => setBudgetDelete(false)}
      visible={budgetDelete}
      onClickEdit={() => setEditName(true)}
      disabled={!verifyABM(auxIdOrType) || isApproved(status)}
      onClickDuplicate={() =>
        handleDuplicate({
          setIsLoading: setIsSaving,
          duplicateCard: () => onDuplicate(),
          onRefetch: reloadList,
          onClose: () => setIsSaving(false),
        })
      }
      onClickDelete={() => setBudgetDelete(true)}
      is_closing={is_closing}
      onOpenActivities={onOpenActivities}
      attributes={attributes}
      listeners={listeners}
      tagStyle={tagStyle}
      className="drag-card-icon"
      setColor={setColor}
      color={color}
      onClickCancelSaveColor={() => {
        setColor(defaultColor)
        setShowPopover(false)
      }}
      onClickSaveColor={() =>
        handleOnChange({
          changeCardColor: () => changeCardColor({ color, card_id: budgetId, type: module }),
          onConfirm: () => setShowPopover(false),
          onClose: () => {
            setShowPopover(false)
            setColor(defaultColor)
          },
        })
      }
      open={showPopover}
      onClickIconColor={() => setShowPopover(true)}
      viewComment={viewComment}
      hasComment={hasComment}
      setHasComment={setHasComment}
      setViewComment={setViewComment}
      loggedUser={loggedUser}
      setDataComments={setDataComments}
      dataComments={dataComments}
      onRefetchComments={onRefetchComments}
      loadingComments={loadingComments}
      cardId={budgetId}
      module={module}
    />
  )

  const renderCardTitle = () => (
    <CardTitle
      isEditing={isEditing}
      editName={editName}
      handleEdit={(value) =>
        handleEdit({
          value,
          titleValue,
          setIsLoading: setIsEditing,
          onEditName: () => onEditName(budgetId, { name: value }),
          onClose: onCloseEdit,
          setTitleValue,
        })
      }
      titleValue={titleValue}
      style={{ width: 180 }}
    />
  )

  const renderBody = () => {
    if (!enableKpis) {
      return <Typography.Body>{t('NOT_VIEW_INFORMATION')}</Typography.Body>
    }
    return <CardData kpi={kpi} />
  }

  const renderDefaultCard = () => {
    return (
      <Dropdown
        trigger={['contextMenu']}
        overlay={
          <Menu>
            {GENERAL.OPTION_COMMENT.map((el) => (
              <Menu.Item
                key={el.key}
                icon={<CommentOutlined />}
                onClick={() => {
                  setViewComment(true)
                  setHasComment({
                    type: 'card',
                    data: !_.isEmpty(hasComment?.card)
                      ? !_.isEmpty(hasComment?.card[key])
                        ? !hasComment?.card[key][budgetId]
                        : true
                      : true,
                    key,
                    id: budgetId,
                  })
                }}
              >
                {t(el.label)}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Card
          title={renderCardTitle()}
          className={`tracing-list-card`}
          style={{ boxShadow: `0px -3px 0px ${color}` }}
          headStyle={{ height: 56, padding: '0px 20px' }}
          bodyStyle={{ height: 128, display: 'flex', alignItems: 'center' }}
          bordered={false}
          actions={[
            <CardFooter
              onClick={onOpen}
              extraFooter={extraFooter}
              disabled={!verifyVIEW(auxIdOrType)}
            />,
          ]}
          extra={renderCardExtra()}
          loading={isDeleting}
        >
          {kpi !== null && renderBody()}
          {kpi === null && <EmptyKpis />}
        </Card>
      </Dropdown>
    )
  }

  const renderRevampCard = () => {
    return (
      <Dropdown
        trigger={['contextMenu']}
        overlay={
          <Menu>
            {GENERAL.OPTION_COMMENT.map((el) => (
              <Menu.Item
                key={el.key}
                icon={<CommentOutlined />}
                onClick={() => {
                  setViewComment(true)
                  setHasComment({
                    type: 'card',
                    data: !_.isEmpty(hasComment.card)
                      ? !_.isEmpty(hasComment?.card[key])
                        ? !hasComment.card[key][budgetId]
                        : true
                      : true,
                    key,
                    id: budgetId,
                  })
                }}
              >
                {t(el.label)}
              </Menu.Item>
            ))}
          </Menu>
        }
      >
        <Card
          className="tracing-list-card"
          style={{ boxShadow: `0px -3px 0px ${color}`, margin: '0px 5px' }}
        >
          <CardBodyKpi
            title={title}
            loading={isDeleting}
            onConfirmPopconfirm={() =>
              handleDelete({
                setIsLoading: setIsDeleting,
                onDeleteBudget: () => onDeleteBudget(budgetId),
                onRefetch: reloadList,
                onClose: onCloseDelete,
              })
            }
            onCancelPopconfirm={() => setBudgetDelete(false)}
            visible={budgetDelete}
            onClickEdit={() => setEditName(true)}
            disabled={!isUserAllowed(role[auxIdOrType].ABM) || isApproved(status)}
            onClickDuplicate={() =>
              handleDuplicate({
                setIsLoading: setIsSaving,
                duplicateCard: () => duplicateCard(budgetId),
                onRefetch: reloadList,
                onClose: () => setIsSaving(false),
              })
            }
            onClickDelete={() => setBudgetDelete(true)}
            onOpenActivities={onOpenActivities}
            type={type}
            attributes={attributes}
            listeners={listeners}
            tagStyle={tagStyle}
            onOpen={onOpen}
            is_closing={is_closing}
            status={status}
            kpi={kpi}
            dataLineChart={dataLineChart}
            dataRankingUp={dataRankingUp}
            allDimensionsList={allDimensionsList}
            editName={editName}
            dimension={dimension}
            setDimensionId={setDimensionId}
            open={showPopover}
            color={color}
            setColor={setColor}
            onClickCancelSaveColor={() => {
              setColor(defaultColor)
              setShowPopover(false)
            }}
            onClickSaveColor={() =>
              handleOnChange({
                changeCardColor: () => changeCardColor({ color, card_id: budgetId, type: module }),
                onConfirm: () => setShowPopover(false),
                onClose: () => {
                  setShowPopover(false)
                  setColor(defaultColor)
                },
              })
            }
            onClickIconColor={() => setShowPopover(true)}
            isEditing={isEditing}
            handleEdit={(value) =>
              handleEdit({
                value,
                titleValue,
                setIsLoading: setIsEditing,
                onEditName: () => onEditName(budgetId, { name: value }),
                onClose: onCloseEdit,
                setTitleValue,
              })
            }
            titleValue={titleValue}
            conceptName={conceptName}
            loggedUser={loggedUser}
            hasComment={hasComment}
            setHasComment={setHasComment}
            viewComment={viewComment}
            setViewComment={setViewComment}
            setDataComments={setDataComments}
            dataComments={dataComments}
            onRefetchComments={onRefetchComments}
            loadingComments={loadingComments}
            cardId={budgetId}
            module={module}
          />
        </Card>
      </Dropdown>
    )
  }

  const getRenderCard = () => {
    if (isGlobal(type)) {
      return renderDefaultCard()
    }
    if (enabledRevampCard) {
      return renderRevampCard()
    }
    return renderDefaultCard()
  }

  return (
    <div ref={setNodeRef} style={draggableStyle}>
      {getRenderCard(type)}
    </div>
  )
}

const mapStateToProps = (state) => ({
  loggedUser: login.selectors.getWhoAmI(state),
  hasComment: configuration.selectors.getHasComment(state),
})

const mapDispatchToProps = {
  setHasComment: configuration.actions.setHasComment,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectionCard)

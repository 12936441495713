import { Select } from 'antd'
import { ROWS } from 'modules/core/constants'
import { generateSelectOptions, orderBySelection } from 'modules/core/utils'

const SelectReportLine = ({ rows, lineId, onChange }) => {
  const filterTotalizer = rows.filter((el) => el.type === ROWS.TOTALIZER)
  const filterGrouper = rows.filter((el) => el.type === ROWS.GROUPER)
  const reportLineId =
    filterTotalizer.length > 0
      ? filterTotalizer[filterTotalizer.length - 1].id
      : filterGrouper.length > 0
      ? filterGrouper[filterGrouper.length - 1].id
      : null
  const reportLinesOptions = rows.map((reportLine) => {
    return { id: reportLine.id, name: reportLine.name }
  })
  return (
    <Select
      bordered={false}
      style={{ width: 150 }}
      showSearch
      optionFilterProp="children"
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      key={reportLineId}
      defaultValue={reportLineId}
      onChange={onChange}
      options={generateSelectOptions({
        options: orderBySelection(!lineId ? [reportLineId] : [lineId], reportLinesOptions),
      })}
    />
  )
}

export default SelectReportLine

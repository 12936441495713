import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleDeleteRow = ({ setIsLoading, deleteRow, onRefetch, onClose }) => {
  setIsLoading(true)
  deleteRow()
    .then(() => {
      onRefetch()
      message.success(i18n.t('FEEDBACK_DELETE_SELECTION_SUCCESS'), 8)
      onClose()
    })
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_DELETE_SELECTION_ERROR'), 8)
      onClose()
    })
}

export const handleCellEdit = ({ updateTransactionNested, onRefetch }) => {
  message.loading({
    key: 'loading_edit',
    content: i18n.t('FEEDBACK_LOADING_CHANGES'),
  })
  updateTransactionNested()
    .then(() => {
      onRefetch()
      // .finally(() => {
      message.destroy('loading_edit')
      message.success(i18n.t('FEEDBACK_SAVE_CHANGES_SUCCES', 8))
      // });
    })
    .catch((error) => message.error(generateMessageError(error, 'FEEDBACK_DEFAULT_ERROR'), 8))
}

export const handleDelete = ({
  setIsLoading,
  deleteLayout,
  onRefetch,
  onClose,
  onSetPrimary = null,
  setViewSelect = null,
  setSettings = null,
}) => {
  setIsLoading(true)
  deleteLayout()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        onClose()
        if (onSetPrimary && setViewSelect) {
          onSetPrimary().then(() => {
            onRefetch().then(() => {
              setViewSelect()
            })
          })
        }
        if (setSettings) {
          setSettings()
        }
      }),
    )
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'))
      onClose()
    })
}

//comentarios en celda

// export const getFunctionComments = ({showRowsComment,createTransactionComment, editTransactionComment, syncBudgetComment }) => {
//   return{
//       showRowsComment,
//       onComment: (data)=> onCommentCell({
//           create: ()=> createTransactionComment({cell_id: data.transactionId, comment: data.comment}),
//           syncProjectionComment: (response)=> syncBudgetComment({
//               columnId: data.columnId,
//               rowId: data.rowId,
//               reducerKey: 'dataTablePhase',
//               newComment: response.payload.data,
//               type: 'create'
//               })
//       }),
//       onEditComment: (data)=> onEditCell({
//           edit: ()=> editTransactionComment({comment_id:  data.commentId, comment: data.comment}),
//           syncProjectionComment: (response)=> syncBudgetComment({
//               columnId: data.columnId,
//               rowId: data.rowId,
//               reducerKey: 'dataTablePhase',
//               newComment: response.payload.data,
//               commentId: data.commentId,
//               type: 'edit'
//           })
//       }),
//   }
// }

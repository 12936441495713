import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Form, Col, Row, Input, Modal, Upload, Radio, Space, InputNumber } from 'antd'
import { useTranslation } from 'react-i18next'
import { isUserAllowed } from 'modules/core/utils'
import { UploadOutlined } from '@ant-design/icons'
import { REPORT } from '../../constants'
import { onCreateReport } from './utils'
import { DownloadTemplate } from './components'
import reports from 'modules/reports'
import Button from '../Button/Button'
import moment from 'moment/moment'

const FormItem = Form.Item

const CreateReportModal = ({
  rolesAvailables = [],
  createReportByFile,
  onFetchReportList,
  downloadTemplateXLS,
  dataPeriod = null,
  onClickSpreadsheets,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [fileList, setFileList] = useState([])
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const userCanCreate = isUserAllowed(rolesAvailables)

  const onCancel = () => {
    form.resetFields()
    setShowModal(false)
    setFileList([])
    setIsLoading(false)
  }

  const onAddUploadFile = (file) => {
    setFileList([file])
    return false
  }

  const uploadProps = {
    name: 'file',
    accept: '.xls, .xlsx',
    showUploadList: { showPreviewIcon: false },
    beforeUpload: onAddUploadFile,
    onRemove: (file) => setFileList([]),
    fileList: fileList,
  }

  return (
    <>
      <Button.Primary
        onClick={() => setShowModal(true)}
        disabled={!userCanCreate}
        title="ACTION_CREATE_NEW"
      />
      <Modal
        title={t('CONFIG_REPORT_CREATE_ACTION')}
        visible={showModal}
        onCancel={!isLoading ? onCancel : null}
        destroyOnClose={true}
        forceRender
        centered
        okText={t('ACTION_CREATE')}
        cancelText={t('ACTION_CANCEL')}
        onOk={() =>
          onCreateReport({
            form,
            setIsLoading,
            createReportByFile,
            onRefetch: onFetchReportList,
            onClose: onCancel,
          })
        }
        confirmLoading={isLoading}
        okButtonProps={{ disabled: isLoading }}
      >
        <Form form={form} layout="vertical">
          {() => (
            <Row gutter={[16, 6]}>
              <Col span={24}>
                <FormItem
                  label={t('FIELD_NAME')}
                  name="reporte"
                  rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                >
                  <Input placeholder={t('CONFIG_REPORT_CREATE_MODAL_NAME_PLACEHOLDER')} />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem label={t('FIELD_DESCRIPTION')} name="description">
                  <Input.TextArea
                    placeholder={t('SOCIETY_ENTER_DESCRIPTION_PLACEHOLDER')}
                    maxLength={100}
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <Form.Item
                  label={t('FIELD_REPORT_TYPE')}
                  name="report_type"
                  rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                  initialValue={'ECONOMICO'}
                >
                  <Radio.Group>
                    <Space direction="horizontal">
                      {REPORT.REPORTS_TYPES.map((it) => (
                        <Radio key={it.id} value={it.id}>
                          {t(it.name)}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {form.getFieldsValue().report_type === 'FINANCIERO' && (
                <Col span={24}>
                  <Form.Item
                    label={t('FIELD_REPORT_INITIAL_AMOUNT')}
                    name="initial_amount"
                    rules={[
                      {
                        required: form.getFieldsValue().report_type === 'FINANCIERO' ? true : false,
                        message: t('REQUIRED_FIELD'),
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder={t('FIELD_REPORT_INITIAL_AMOUNT')}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span={24}>
                <FormItem
                  label={t('ACTION_UPLOAD_FILE')}
                  name="files_data"
                  rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                >
                  <Upload {...uploadProps}>
                    {uploadProps.fileList.length <= 0 && (
                      <Button.DefaultWhitILeftIcon
                        icon={<UploadOutlined />}
                        title="UPLOAD_FILE_PLACEHOLDER"
                      />
                    )}
                  </Upload>
                </FormItem>
              </Col>
              <Col span={24}>
                <DownloadTemplate
                  type={form.getFieldsValue().report_type}
                  onClick={() =>
                    downloadTemplateXLS(
                      t('REPORT_TEMPLATE_FILE_NAME', {
                        name: 'Analisis',
                        period: dataPeriod ? dataPeriod.name : '',
                        date: moment().format('lll'),
                      }),
                      {
                        ...(form.getFieldsValue().report_type === 'PATRIMONIAL' && {
                          type_report: 'patrimonial',
                        }),
                      },
                    )
                  }
                  onClickSpreadsheets={onClickSpreadsheets}
                />
              </Col>
            </Row>
          )}
        </Form>
      </Modal>
    </>
  )
}

const mapDispatchToProps = {
  createReportByFile: reports.actions.createReportByFile,
  downloadTemplateXLS: reports.actions.downloadTemplateXLS,
}

export default connect(null, mapDispatchToProps)(CreateReportModal)

import { message } from 'antd'
import { generateMessageError } from 'modules/core/utils'
import i18n from 'i18next'

export const handleDelete = ({
  setIsLoading,
  deleteLayout,
  onRefetch,
  onClose,
  onSetPrimary = null,
  setViewSelect = null,
  setSettings = null,
}) => {
  setIsLoading(true)
  deleteLayout()
    .then(() =>
      onRefetch().then(() => {
        message.success(i18n.t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        onClose()
        if (onSetPrimary && setViewSelect) {
          onSetPrimary().then(() => {
            onRefetch().then(() => {
              setViewSelect()
            })
          })
        }
        if (setSettings) {
          setSettings()
        }
      }),
    )
    .catch((error) => {
      message.error(generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL_MESSAGE'))
      onClose()
    })
}

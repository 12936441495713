import { Col, Form, Modal, Row, Select } from 'antd'
import { AddAndDeleteFormItem } from 'modules/core/components'
import { generateSelectOptions } from 'modules/core/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const SelectDimensionModal = ({
  visible,
  onClose,
  setDataDimensions,
  setDimensionId,
  valuesSelected,
  allDimensionsList,
  // defaultValue,
}) => {
  const [selectedDimensions, setSelectedDimensions] = useState([])
  const [form] = Form.useForm()
  const { t } = useTranslation()

  // useEffect(()=>{
  //   form.setFieldsValue({
  //     ...(defaultValue.length > 0 && {
  //       dimensionList: defaultValue
  //     })
  //   })
  // },[form, defaultValue])

  const onCancel = () => {
    form.resetFields()
    setDimensionId(null)
    setSelectedDimensions([])
    onClose()
  }

  const onConfirm = () => {
    form.validateFields().then((values) => {
      const data = values.dimensionList.reduce(
        (acum, data) => (acum = { ...acum, [data.dimension]: data.dimensionValue }),
        {},
      )
      setDataDimensions(data)
      onCancel()
    })
  }

  return (
    <Modal
      title={t('PLANNING_SAC_COMBINATION_DIMENSIONS_STEP_DESCRIPTION')}
      visible={visible}
      onCancel={onCancel}
      okText={t('ACTION_SAVE')}
      onOk={onConfirm}
      centered
      forceRender
      destroyOnClose
      bodyStyle={{ overflowY: 'auto', maxHeight: 400 }}
    >
      <Form form={form} layout="vertical" initialValues={{ dimensionList: [''] }}>
        {() => (
          <Form.List name="dimensionList">
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field, index) => {
                    return (
                      <Row gutter={24} key={field.key} justify="end">
                        <Col span={12}>
                          <Form.Item
                            name={[field.name, 'dimension']}
                            label={[field.label, t('FIELD_DIMENSION')]}
                            rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                          >
                            <Select
                              placeholder={t('ACTION_SELECT')}
                              onChange={(id) => {
                                setDimensionId(id)
                                setSelectedDimensions([
                                  ...(form.getFieldsValue('dimensionList').dimensionList &&
                                    form
                                      .getFieldsValue('dimensionList')
                                      .dimensionList.map((el) => el && el.dimension.toString())),
                                ])
                              }}
                              options={generateSelectOptions({
                                options: allDimensionsList.map((el) => {
                                  return { id: el.id, name: el.name_destination }
                                }),
                                selection: selectedDimensions,
                              })}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            name={[field.name, `dimensionValue`]}
                            label={[field.label, t('FIELD_DIMENSION_VALUES')]}
                            rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
                          >
                            <Select
                              placeholder={t('ACTION_SELECT')}
                              allowClear
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              onFocus={() =>
                                setDimensionId(
                                  form.getFieldValue('dimensionList')[field.name]['dimension'],
                                )
                              }
                              disabled={
                                !form.getFieldValue('dimensionList')[field.name] ||
                                !form.getFieldValue('dimensionList')[field.name]['dimension']
                              }
                              options={generateSelectOptions({
                                options:
                                  Object.keys(valuesSelected).length > 0 &&
                                  form.getFieldValue('dimensionList')[field.name] &&
                                  form.getFieldValue('dimensionList')[field.name]['dimension'] &&
                                  valuesSelected[
                                    form.getFieldValue('dimensionList')[field.name]['dimension']
                                  ]
                                    ? valuesSelected[
                                        form.getFieldValue('dimensionList')[field.name]['dimension']
                                      ].map((el) => {
                                        return {
                                          code: el.code_destination,
                                          name: el.name_destination,
                                          id: el.id,
                                        }
                                      })
                                    : [],
                              })}
                            />
                          </Form.Item>
                        </Col>
                        <AddAndDeleteFormItem
                          fieldsLength={fields.length}
                          index={index}
                          addData={{
                            onClick: () => {
                              add()
                              setDimensionId(null)
                            },
                            buttonName: 'PLANNING_GLOBAL_BUDGET_ADD_NEW_COMBINATION_ACTION',
                          }}
                          deleteData={{
                            onClick: () => {
                              const selectedID =
                                form.getFieldValue('dimensionList')[field.name] &&
                                form.getFieldValue('dimensionList')[field.name]['dimension']
                              if (selectedID)
                                setSelectedDimensions(
                                  selectedDimensions.filter((id) => id !== selectedID.toString()),
                                )
                              remove(field.name)
                            },
                            buttonName: 'PLANNING_GLOBAL_BUDGET_DELETE_COMBINATION_ACTION',
                          }}
                        />
                      </Row>
                    )
                  })}
                </div>
              )
            }}
          </Form.List>
        )}
      </Form>
    </Modal>
  )
}

export default SelectDimensionModal

import { Image, List, message, notification, Space, Tabs, Upload } from 'antd'
import {
  Button,
  ButtonActionBar,
  DeleteAndUpdate,
  InputCell,
  SelectCell,
  Typography,
} from 'modules/core/components'
import { useQuery } from 'modules/core/customHooks'
import { EditOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { connect } from 'react-redux'
import { generateMessageError } from 'modules/core/utils'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import configuration from 'modules/configuration'
import _ from 'lodash'

const TabsData = ({
  pkid,
  userDetail,
  rolesList,
  areaList,
  userProfile,
  setShowUploadModal,
  society_id,
  onRefetch,
  setShowDrawer,
  setIsSaving,
  isSaving,
  assignRole,
  editUser,
  editProfile,
}) => {
  const [rowId, setRowId] = useState(null)
  const [dataCellEdit, setDataCellEdit] = useState({})
  const [fileList, setFileList] = useState([])
  const [showEdit, setShowEdit] = useState(false)
  let { userId } = useParams()
  let query = useQuery()
  const { t } = useTranslation()

  const onCellEdit = (name, value) => setDataCellEdit({ ...dataCellEdit, [`${name}`]: value })

  const onCancel = () => setRowId(null)

  const NAMES = {
    email: {
      id: 'email',
      title: t('FIELD_EMAIL'),
      data: userDetail.email,
      isInput: true,
      isEditable: true,
      placeholder: t('ACTION_ENTER'),
    },
    first_name: {
      id: 'first_name',
      title: t('FIELD_NAME'),
      data: userDetail.first_name,
      isInput: true,
      isEditable: true,
      placeholder: t('ACTION_ENTER'),
    },
    last_name: {
      id: 'last_name',
      title: t('FIELD_SURNAME'),
      data: userDetail.last_name,
      isInput: true,
      isEditable: true,
      placeholder: t('ACTION_ENTER'),
    },
    roles: {
      id: 'roles',
      title: t('LABEL_ROLE'),
      options: rolesList.data,
      extraData: userDetail.roles.map((el) => el.id),
      data: userDetail.roles.map((el) => el.name).join(', '),
      isInput: false,
      isEditable: true,
      placeholder: t('ACTION_SELECT'),
    },
    approval_areas: {
      id: 'approval_areas',
      title: t('LABEL_ASSOCIATED_AREAS'),
      data:
        userDetail.approval_areas.length > 0
          ? userDetail.approval_areas
              .map((el) =>
                areaList.length > 0 ? areaList.find((area) => area.id === el).name : '',
              )
              .join(', ')
          : '',
      isInput: false,
      isEditable: false,
      placeholder: t('ACTION_SELECT'),
    },
    ...(pkid === userDetail.id && {
      phone_number: {
        id: 'phone_number',
        title: t('LABEL_PHONE'),
        data: pkid === userDetail.id ? userProfile?.phone_number : '',
        isInput: true,
        isEditable: true,
        placeholder: '+549 221 9999999',
      },
      nickname: {
        id: 'nickname',
        title: t('LABEL_NICKNAME'),
        data: pkid === userDetail.id ? userProfile?.nickname : '',
        isInput: true,
        isEditable: true,
        placeholder: t('ACTION_ENTER'),
      },
    }),
  }

  const renderComponent = (item) =>
    item.isInput ? (
      <InputCell
        initialValue={item.data}
        onCellUpdate={onCellEdit}
        name={item.id}
        placeholder={item.placeholder}
      />
    ) : (
      <SelectCell
        initialValue={item.extraData}
        name={item.id}
        onCellUpdate={onCellEdit}
        mode="multiple"
        maxTagCount={1}
        maxTagTextLength={8}
        showArrow
        allowClear
        style={{ width: 250 }}
        options={item.options}
      />
    )

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList)
  }

  const onAddUploadFile = (file) => {
    setFileList([file])
    return false
  }

  const handleEdit = () => {
    const { nickname, phone_number, roles, ...restProps } = dataCellEdit
    setIsSaving(true)
    Promise.all([
      editUser(userId, { society: society_id, ...restProps }),
      roles && assignRole(userId, { role_ids: roles }),
      (nickname || phone_number) && editProfile(userId, { nickname, phone_number }),
    ])
      .then(() => {
        onRefetch()
        message.success(t('FEEDBACK_SAVE_CHANGES_SUCCES'), 8)
        setIsSaving(false)
        setRowId(null)
        setDataCellEdit({})
      })
      .catch((error) => {
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error, 'FEEDBACK_SAVE_CHANGES_FAIL'),
          duration: 0,
        })
        setIsSaving(false)
        setRowId(null)
        setDataCellEdit({})
      })
  }

  const renderButtons = (item) => {
    if (item.isEditable) {
      if (showEdit && rowId === item.id) {
        return (
          <DeleteAndUpdate
            onCancel={onCancel}
            onConfirm={onCancel}
            onSave={() => handleEdit()}
            dataCellEdit={dataCellEdit}
            loading={false}
          />
        )
      } else {
        return (
          <Button.Icon
            onClick={() => {
              setShowEdit(true)
              setRowId(item.id)
            }}
            icon={<Typography.Icon icon={EditOutlined} />}
          />
        )
      }
    }
  }

  const handleEditPhoto = () => {
    setIsSaving(true)
    const formData = new FormData()
    fileList.forEach((file) => {
      formData.append('photo', file['originFileObj'])
    })
    editProfile(userId, formData)
      .then(() => {
        onRefetch()
        message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        setIsSaving(false)
      })
      .catch((error) => {
        setIsSaving(false)
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        })
      })
  }

  const renderuserData = () => {
    const { id, full_name, status, society_pk, society_name, groups, quicksight, ...restProps } =
      userDetail
    const data = {
      ...restProps,
      ...(pkid === userDetail.id && {
        nickname: userProfile?.nickname,
        phone_number: userProfile?.phone_number,
      }),
    }

    return (
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {pkid === userDetail.id && (
          <ButtonActionBar position="space-between">
            <Typography.Headline type="primary" level={4}>
              {t('CONFIG_PROFILE_PERSONAL_DATA_TAB')}
            </Typography.Headline>
            <Button.Primary title="ACTION_CONFIGURATE" onClick={() => setShowDrawer(true)} />
          </ButtonActionBar>
        )}
        {pkid === userDetail.id && (
          <>
            <Space direction="vertical">
              <Typography.Subtitle>{t('CONFIG_PROFILE_PHOTO_TITLE')}</Typography.Subtitle>
              <Typography.Body level={2}>
                {t('CONFIG_PROFILE_SELECT_MODIFY_PHOTO_SUBTITLE')}
              </Typography.Body>
            </Space>
            <Space direction="horizontal">
              {userProfile?.photo && (
                <>
                  <Space direction="vertical">
                    <Image width={100} src={userProfile?.photo} />
                    <Typography.Body level={2}>
                      {t('CONFIG_PROFILE_IMG_FORMAT_TYPOGRAPHY_TEXT')}
                    </Typography.Body>
                  </Space>
                  <Button.Icon
                    title="ACTION_EDIT"
                    icon={<Typography.Icon icon={EditOutlined} />}
                    onClick={() => setShowUploadModal(true)}
                  />
                </>
              )}
              {!userProfile?.photo && (
                <>
                  <Space direction="vertical">
                    <Upload
                      listType="picture-card"
                      accept=".jpg, .png"
                      fileList={fileList}
                      onChange={onChange}
                      beforeUpload={onAddUploadFile}
                    >
                      {fileList.length < 1 && `+ ${t('ACTION_UPLOAD')}`}
                    </Upload>
                    <Typography.Body level={2}>
                      {t('CONFIG_PROFILE_IMG_FORMAT_TYPOGRAPHY_TEXT')}
                    </Typography.Body>
                  </Space>
                  {fileList.length > 0 && (
                    <Button.Primary title="ACTION_SAVE" onClick={() => handleEditPhoto()} />
                  )}
                </>
              )}
            </Space>
          </>
        )}
        <List
          itemLayout="horizontal"
          dataSource={_.keys(data)}
          loading={isSaving}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <List.Item.Meta
                title={
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Space direction="horizontal">
                      <Typography.Subtitle>{`${NAMES[item].title}:`}</Typography.Subtitle>
                      {showEdit && rowId === NAMES[item].id ? (
                        renderComponent(NAMES[item])
                      ) : (
                        <Typography.Body>
                          {NAMES[item].data || t('STATE_TAG_UNDEFINED')}
                        </Typography.Body>
                      )}
                    </Space>
                    {pkid === userDetail.id && renderButtons(NAMES[item])}
                  </div>
                }
              />
            </List.Item>
          )}
        />
      </Space>
    )
  }

  return (
    <Tabs size="small" defaultActiveKey={query.get('tab') !== null ? query.get('tab') : 'userData'}>
      <Tabs.TabPane
        tab={t('CONFIG_PROFILE_PERSONAL_DATA_TAB')}
        key="userData"
        style={{ padding: '0px 24px' }}
      >
        {renderuserData()}
      </Tabs.TabPane>
    </Tabs>
  )
}

const mapDispatchToProps = {
  editProfile: configuration.actions.editProfile,
  editUser: configuration.actions.editUser,
  assignRole: configuration.actions.assignRole,
}

export default connect(null, mapDispatchToProps)(TabsData)

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import React, { useEffect, useState } from 'react'
import { Col, Row, Space, Spin, Tabs } from 'antd'
import { connect } from 'react-redux'
import { TABLES, TAB_KEYS } from 'modules/core/constants'
import { useParams } from 'react-router-dom'
import {
  ComparisonTable,
  EvolutionTable,
  GlobalProjectionLineChart,
  SelectType,
  TableTypeSelect,
} from 'modules/core/components'
import { useTranslation } from 'react-i18next'
import { setPhaseAvailable } from 'modules/core/utils'
import reports from 'modules/reports'
import planning from 'modules/planning'
import forecast from 'modules/forecast'
import moment from 'moment'
import ComparisonPivotTable from './components/ComparisonPivotTable'
import EvolutionPivotTable from './components/EvolutionPivotTable'
import { SelectReportLine } from 'modules/reports/containers/ReportDetailHome/components/ReportDetail/components/ReportChart/components/ChartTitle/components'
import _ from 'lodash'

const TabsReports = ({
  tableId,
  setTableId,
  analysisList,
  loading,
  dataPhaseBudget,
  stepsForecastInstance,
  dataPeriod,
  globalForecastName,
  selectedDates,
  filters,
  tableLoading,
  allDimensionsList,
  defaultPhase,
  setDefaultPhase,
  auxCurrencyId,
  setDataComments,
  dataComments,
  onRefetchComments,
  loadingComments,
  currencyData,
  showProgressBar,
  setShowProgressBar,
  fetchGlobalForecastComparisonTable,
  globalForecastComparisonTable,
  fetchGlobalForecastEvolutionTable,
  globalForecastEvolutionTable,
  fetchForecastEvolutionTableOpenRow,
  fetchForecastComparisonTableOpenRow,
  fetchGlobalForecastChart,
  globalForecastChart,
  downloadGlobalForecastEvolutiontXLS,
  downloadGlobalForecastComparisontXLS,
  fetchDataEvolutionByOverallForecast,
  fetchDataComparativeByOverallForecast,
  globalForecastChartDefault,
  setReportLineSelectedForecast,
}) => {
  // Feature flag
  const enabledEvolution = useFeatureIsOn('feature-react-pivot-evolution')
  const enabledComparative = useFeatureIsOn('feature-react-pivot-comparative')

  const [periodTableSettings, setPeriodTableSettings] = useState(TABLES.DEFAULT_SETTINGS)
  const [isEvolutionLoading, setIsEvolutionLoading] = useState(false)
  const [isComparisonLoading, setIsComparisonLoading] = useState(false)
  const [tableKey, setTableKey] = useState(TAB_KEYS.TAB_KEY_EVOLUTION)
  const [periodTableColumns, setPeriodTableColumns] = useState([])
  const [localReferenceBase, setLocalReferenceBase] = useState({ value: 'BASE', id: null })
  const [localReferenceComparisonBase, setLocalReferenceComparisonBase] = useState([])
  const [isChartLoading, setIsChartLoading] = useState(false)
  const [optionsSelected, setOptionsSelected] = useState([])
  const { t } = useTranslation()
  let { periodId, globalForecastId } = useParams()

  const comparativeOptions = _.compact(
    setPhaseAvailable(stepsForecastInstance, dataPhaseBudget).map(
      (el) => el.key.toUpperCase() !== localReferenceBase.value && el.key,
    ),
  )

  useEffect(() => {
    if (tableId) {
      setIsEvolutionLoading(true)
      setShowProgressBar(true)
      // TODO: NEW ENDPOINTS FOR REVAMP TABLE
      if (enabledEvolution) {
        fetchDataEvolutionByOverallForecast(tableId, parseInt(globalForecastId), defaultPhase, {
          ...(auxCurrencyId && { currency_id: auxCurrencyId }),
        }).then(() => {
          setIsEvolutionLoading(false)
          setTimeout(() => {
            setShowProgressBar(false)
          }, 2000)
        })
      } else {
        fetchGlobalForecastEvolutionTable(tableId, parseInt(globalForecastId), defaultPhase, {
          ...selectedDates,
          ...filters,
        }).then(() => {
          setIsEvolutionLoading(false)
          setTimeout(() => {
            setShowProgressBar(false)
          }, 2000)
        })
      }
    }
  }, [tableId, globalForecastId, defaultPhase, selectedDates, filters, auxCurrencyId])

  useEffect(() => {
    if (tableKey !== TAB_KEYS.TAB_KEY_COMPARATION) return

    if (tableId) {
      setIsComparisonLoading(true)
      setShowProgressBar(true)
      // TODO: NEW ENDPOINTS FOR REVAMP TABLE
      if (enabledComparative) {
        fetchDataComparativeByOverallForecast(tableId, parseInt(globalForecastId), {
          comparative_steps:
            localReferenceComparisonBase.length === 0
              ? [...comparativeOptions, localReferenceBase.value.toLowerCase()]
              : [...localReferenceComparisonBase, localReferenceBase.value.toLowerCase()],
          ...(auxCurrencyId && { currency_id: auxCurrencyId }),
        }).then(() => {
          setIsComparisonLoading(false)
          setTimeout(() => {
            setShowProgressBar(false)
          }, 2000)
        })
      } else {
        fetchGlobalForecastComparisonTable(tableId, parseInt(globalForecastId), {
          ...selectedDates,
          ...filters,
        }).then(() => {
          setIsComparisonLoading(false)
          setTimeout(() => {
            setShowProgressBar(false)
          }, 2000)
        })
      }
    }
  }, [
    tableId,
    globalForecastId,
    selectedDates,
    filters,
    auxCurrencyId,
    tableKey,
    localReferenceComparisonBase,
    localReferenceBase,
  ])

  useEffect(() => {
    if (tableId) {
      setIsChartLoading(true)
      fetchGlobalForecastChart({
        period_id: periodId,
        forecast_overall_budget_id: globalForecastId,
        templated_id: tableId,
        step: defaultPhase,
        ...selectedDates,
        ...filters,
        ...(auxCurrencyId && { currency_id: auxCurrencyId }),
      }).then(() => setIsChartLoading(false))
    }
  }, [
    fetchGlobalForecastChart,
    periodId,
    globalForecastId,
    selectedDates,
    filters,
    auxCurrencyId,
    tableId,
    defaultPhase,
  ])

  useEffect(() => {
    setPeriodTableColumns(globalForecastEvolutionTable?.columns)
  }, [globalForecastEvolutionTable])

  const download = {
    evolution: () =>
      downloadGlobalForecastEvolutiontXLS(
        t('PLANNING_GLOBAL_BUDGET_EVOLUTION_TABLE_FILE_NAME', {
          name: globalForecastName,
          reportName: analysisList.values.find((el) => el.id === tableId).name,
          periodName: dataPeriod.name,
          date: moment().format('lll'),
        }),
        tableId,
        globalForecastId,
        defaultPhase,
        { ...selectedDates, ...filters },
      ),
    comparison: () =>
      downloadGlobalForecastComparisontXLS(
        t('PLANNING_GLOBAL_BUDGET_COMPARISON_TABLE_FILE_NAME', {
          name: globalForecastName,
          reportName: analysisList.values.find((el) => el.id === tableId).name,
          periodName: dataPeriod.name,
          date: moment().format('lll'),
        }),
        tableId,
        globalForecastId,
        { ...selectedDates, ...filters },
      ),
  }

  const getEvolutionTable = () => {
    if (enabledEvolution) {
      return (
        <EvolutionPivotTable
          setPeriodTableSettings={setPeriodTableSettings}
          periodTableSettings={periodTableSettings}
          download={download[tableKey]}
          globalProjectionId={globalForecastId}
          allDimensionsList={allDimensionsList}
          isEvolutionLoading={isEvolutionLoading}
          tableLoading={tableLoading}
          reportId={tableId}
          onRefetch={() =>
            fetchDataEvolutionByOverallForecast(tableId, parseInt(globalForecastId), defaultPhase, {
              ...(auxCurrencyId && { currency_id: auxCurrencyId }),
            })
          }
          setDataComments={setDataComments}
          dataComments={dataComments}
          onRefetchComments={onRefetchComments}
          loadingComments={loadingComments}
          showProgressBar={showProgressBar}
          currencyData={currencyData}
        />
      )
    }
    return (
      <EvolutionTable
        reportId={tableId}
        rows={globalForecastEvolutionTable.rows}
        columns={periodTableColumns}
        loading={isEvolutionLoading || tableLoading}
        settings={periodTableSettings}
        fetchEvolutionTableOpenRow={fetchForecastEvolutionTableOpenRow}
        globalProjectionId={globalForecastId}
        step={defaultPhase}
        selectedDates={selectedDates}
        filters={filters}
      />
    )
  }

  const getComparisonTable = () => {
    if (enabledComparative) {
      return (
        <ComparisonPivotTable
          setPeriodTableSettings={setPeriodTableSettings}
          periodTableSettings={periodTableSettings}
          download={download[tableKey]}
          globalProjectionId={globalForecastId}
          allDimensionsList={allDimensionsList}
          isComparisonLoading={isComparisonLoading}
          tableLoading={tableLoading}
          reportId={tableId}
          onRefetch={() =>
            fetchDataComparativeByOverallForecast(tableId, parseInt(globalForecastId), {
              ...(auxCurrencyId && { currency_id: auxCurrencyId }),
            })
          }
          setDataComments={setDataComments}
          dataComments={dataComments}
          onRefetchComments={onRefetchComments}
          loadingComments={loadingComments}
          showProgressBar={showProgressBar}
          currencyData={currencyData}
          localReferenceBase={localReferenceBase}
        />
      )
    }
    return (
      <ComparisonTable
        globalProjectionComparisonTable={globalForecastComparisonTable}
        fetchComparisonTableOpenRow={fetchForecastComparisonTableOpenRow}
        loading={isComparisonLoading || tableLoading}
        reportId={tableId}
        selectedDates={selectedDates}
        filters={filters}
      />
    )
  }

  const tables = {
    evolution: getEvolutionTable(),
    comparison: getComparisonTable(),
  }

  const renderChart = () => {
    const renderChartTitle = () => (
      <>
        {t('REPORT_GRAPHIC_TITLE')}
        <SelectReportLine
          rows={globalForecastChartDefault.rows}
          lineId={globalForecastChartDefault.lineId}
          onChange={(id) => setReportLineSelectedForecast(id)}
        />
      </>
    )
    //TODO: VER SI EN ESTE CASO ES POSIBLE AGREGAR EL SELECTOR DE LINEAS
    return (
      <GlobalProjectionLineChart
        title={renderChartTitle()}
        exportTitle={`${t('REPORT_GRAPHIC_TITLE')} - ${t('LABEL_GLOBAL_BUDGET')}`}
        data={[globalForecastChart]}
        setDataComments={setDataComments}
        dataComments={dataComments}
        onRefetchComments={onRefetchComments}
        loadingComments={loadingComments}
        currencyData={currencyData}
      />
    )
  }

  const renderTableAndActions = () => tables[tableKey]

  return (
    <>
      <Tabs
        defaultActiveKey={tableId}
        onChange={(key) => {
          setTableId(key)
          setReportLineSelectedForecast(undefined)
        }}
        size="small"
      >
        {analysisList.count > 0 &&
          analysisList?.values
            .filter((report) => report.has_data)
            .map((report) => <Tabs.TabPane tab={report.name} key={report.id} />)}
      </Tabs>
      <Spin spinning={loading || isChartLoading} />
      {tableId && !loading && !isChartLoading && (
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Space direction="horizontal">
              <TableTypeSelect
                tableKey={tableKey}
                setTableKey={setTableKey}
                defaultPhase={defaultPhase}
                dataPhaseBudget={setPhaseAvailable(stepsForecastInstance, dataPhaseBudget)}
                setDefaultPhase={setDefaultPhase}
              />
              {tableKey === TAB_KEYS.TAB_KEY_COMPARATION && (
                <SelectType.GlobalComparisonSelect
                  localReferenceBase={localReferenceBase}
                  setLocalReferenceBase={setLocalReferenceBase}
                  stepsInstance={stepsForecastInstance}
                  dataPhaseBudget={dataPhaseBudget}
                  localReferenceComparisonBase={localReferenceComparisonBase}
                  comparativeOptions={comparativeOptions}
                  setOptionsSelected={setOptionsSelected}
                  onApply={() => setLocalReferenceComparisonBase(optionsSelected)}
                  optionsSelected={optionsSelected}
                />
              )}
            </Space>
          </Col>
          <Col span={24}>{renderTableAndActions()}</Col>
          <Col span={24}>{renderChart()}</Col>
        </Row>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  analysisList: reports.selectors.getAnalysisList(state),
  dataPhaseBudget: planning.selectors.getDataPhaseBudget(state),
  globalForecastEvolutionTable: forecast.selectors.getGlobalForecastEvolutionTable(state),
  globalForecastComparisonTable: forecast.selectors.getGlobalForecastComparisonTable(state),
  globalForecastChart: forecast.selectors.getGlobalForecastChart(state),
  globalForecastChartDefault: forecast.selectors.getGlobalForecastChartDefault(state),
})

const mapDispatchToProps = {
  fetchGlobalForecastComparisonTable: forecast.actions.fetchGlobalForecastComparisonTable,
  fetchGlobalForecastEvolutionTable: forecast.actions.fetchGlobalForecastEvolutionTable,
  fetchForecastEvolutionTableOpenRow: forecast.actions.fetchForecastEvolutionTableOpenRow,
  fetchForecastComparisonTableOpenRow: forecast.actions.fetchForecastComparisonTableOpenRow,
  fetchGlobalForecastChart: forecast.actions.fetchGlobalForecastChart,
  downloadGlobalForecastEvolutiontXLS: forecast.actions.downloadGlobalForecastEvolutiontXLS,
  downloadGlobalForecastComparisontXLS: forecast.actions.downloadGlobalForecastComparisontXLS,
  // TODO: NEW ENDPOINTS FOR REVAMP TABLE
  fetchDataEvolutionByOverallForecast: forecast.actions.fetchDataEvolutionByOverallForecast,
  fetchDataComparativeByOverallForecast: forecast.actions.fetchDataComparativeByOverallForecast,
  setReportLineSelectedForecast: forecast.actions.setReportLineSelectedForecast,
}

export default connect(mapStateToProps, mapDispatchToProps)(TabsReports)

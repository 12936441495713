const BUDGET = 'budget'
const BUDGET_PERSONALIZED_REPORT = 'Presupuesto'
const BUDGET_VAR_MONEY = 'budget_var$'
const BUDGET_VAR_PERCENTAGE = 'budget_var%'
const REAL = 'real'
const REAL_PERSONALIZED_REPORT = 'Real'
const REAL_LAST_PERIOD = 'prev_real'
const REAL_LAST_PERIOD_MONEY = 'prev_real_var$'
const REAL_LAST_PERIOD_PERCENTAGE = 'prev_real_var%'
const FORECAST = 'forecast'
const LAST_FORECAST = 'last_forecast'
const LAST_FORECAST_VAR_MONEY = 'last_forecast_var$'
const LAST_FORECAST_VAR_PERCENTAGE = 'last_forecast_var%'
const VAR_MONEY = 'VAR $'
const VAR_PERCENTAGE = 'VAR %'
const GLOBAL_BUDGET = 'globalBudget'
const GLOBAL_FORECAST = 'globalForecast'
const BUDGET_LAST_PERIOD = 'prev_budget'
const LAST_PERIOD = 'last_period'
const TRANSACTIONAL_KEY = 'transactional'
const PERCENTAGE_KEY = 'percentage'
const FLAT_KEY = 'flat'

// Constantes para los tipos de tarjeta
const CARD_TYPE = {
  GLOBAL_BUDGET,
  GLOBAL_FORECAST,
}

const PXQ_KEY = 'PxQ'

const FUNCTIONAL_KEY = 'functional'
const ORIGIN_KEY = 'origin'

const IN_ELABORATION = 'in_elaboration'
const MAKING = 'making'
const PUBLISHED = 'published'
const APPROVED = 'approved'
const INITIATED = 'initiated'
const APPLIED = 'applied'
const BASE = 'BASE'
const TOPDOWN = 'TOP_DOWN'
const BOTTOMUP = 'BOTTOM_UP'
const CLOSING = 'CLOSING'

const BASE_KEY = 'base'

const STATUS = {
  IN_ELABORATION: IN_ELABORATION,
  MAKING: MAKING,
  PUBLISHED: PUBLISHED,
  APPROVED: APPROVED,
  INITIATED: INITIATED,
  APPLIED: APPLIED,
}

const STATUS_COLORS = {
  [IN_ELABORATION]: { color: 'gold', name: 'in_elaboration' },
  [MAKING]: { color: 'gold', name: 'in_elaboration' },
  [APPROVED]: { color: 'green', name: 'approved' },
  [PUBLISHED]: { color: 'cyan', name: 'published' },
  [BASE]: { color: 'volcano', name: 'Base' },
  [TOPDOWN]: { color: 'magenta', name: 'TopDown' },
  [BOTTOMUP]: { color: 'purple', name: 'BottomUp' },
  [CLOSING]: { color: 'geekblue', name: 'LABEL_CLOSING', extraName: 'STATUS_TAG_CLOSED' },
}

const I18N = {
  [BUDGET]: 'LABEL_BUDGET',
  [BUDGET_VAR_MONEY]: 'VARIATION_MONEY',
  [BUDGET_VAR_PERCENTAGE]: 'VARIATION_PERCENTAGE',
  [REAL]: 'LABEL_REAL',
  [REAL_PERSONALIZED_REPORT]: 'LABEL_REAL',
  [REAL_LAST_PERIOD]: 'REAL_LAST_PERIOD',
  [REAL_LAST_PERIOD_MONEY]: 'VARIATION_MONEY',
  [REAL_LAST_PERIOD_PERCENTAGE]: 'VARIATION_PERCENTAGE',
  [FORECAST]: 'LABEL_FORECAST',
  [LAST_FORECAST]: 'LAST_FORECAST',
  [LAST_FORECAST_VAR_MONEY]: 'VARIATION_MONEY',
  [LAST_FORECAST_VAR_PERCENTAGE]: 'VARIATION_PERCENTAGE',
  [BUDGET_PERSONALIZED_REPORT]: 'LABEL_BUDGET',
  [VAR_MONEY]: 'VARIATION_MONEY',
  [VAR_PERCENTAGE]: 'VARIATION_PERCENTAGE',
  [LAST_PERIOD]: 'LAST_PERIOD',
}

const LOAD_TYPES = {
  CONVENTIONAL: { id: 'conventional', name: 'LABEL_CONVENTIONAL_LOAD' },
  PLANE: { id: 'plane', name: 'LABEL_FLAT_LOAD' },
}

const LOAD_TYPES_LIST = Object.values(LOAD_TYPES)

const STATUS_COLUMN = {
  0: {
    status: STATUS.APPROVED,
    title: 'CONTROL_APPROVED_DATA_TOOLTIP_TITLE',
  },
  1: {
    status: STATUS.PUBLISHED,
    title: 'CONTROL_PUBLISHED_DATA_TOOLTIP_TITLE',
  },
  2: { status: 'load', title: 'CONTROL_UPLOADED_DATA_TOOLTIP_TITLE' },
  3: { status: 'null', title: '' },
}

const FACTORS_TYPE_OPTIONS = [
  { id: 'principal', name: 'FIELD_PRINCIPAL' },
  { id: 'secondary', name: 'FIELD_SECONDARY' },
]

const VARIABLES_TYPE_OPTIONS = [
  { id: 'percentage', name: 'FIELD_PERCENTAGE' },
  { id: 'number', name: 'FIELD_NUMBER' },
]

const CURRENCIES_OPTIONS = [
  { id: 'USD', code: 'USD', name: 'CONFIG_CURRENCY_USD', prefix: 'US$' },
  { id: 'EUR', code: 'EUR', name: 'CONFIG_CURRENCY_EUR', prefix: '€' },
  { id: 'ARS', code: 'ARS', name: 'CONFIG_CURRENCY_ARS', prefix: 'AR$' },
  { id: 'CLP', code: 'CLP', name: 'CONFIG_CURRENCY_CLP', prefix: 'CL$' },
  { id: 'COP', code: 'COP', name: 'CONFIG_CURRENCY_COP', prefix: 'CO$' },
  { id: 'MXN', code: 'MXN', name: 'CONFIG_CURRENCY_MXN', prefix: 'MX$' },
  { id: 'UYU', code: 'UYU', name: 'CONFIG_CURRENCY_UYU', prefix: 'UY$' },
  { id: 'PEN', code: 'PEN', name: 'CONFIG_CURRENCY_PEN', prefix: 'S/' },
]

const OPTIONS_ALL_PROJECTION_TYPE = [
  {
    id: REAL,
    name: 'LABEL_REAL',
  },
  {
    id: BUDGET,
    name: 'LABEL_BUDGET',
  },
  {
    id: LAST_PERIOD,
    name: 'LAST_PERIOD',
  },
  {
    id: LAST_FORECAST,
    name: 'LAST_FORECAST',
  },
]

const PROJECTIONS_OPTIONS = [
  { id: BUDGET_PERSONALIZED_REPORT.toUpperCase(), name: 'LABEL_BUDGET' },
  { id: REAL.toUpperCase(), name: 'LABEL_REAL' },
]

const NAME_PROJECTIONS = {
  real: I18N.Real,
  budget: I18N.Presupuesto,
  last_period: I18N.last_period,
  last_forecast: I18N.last_forecast,
}

const VALUES_TYPES = [
  {
    id: 'amount',
    name: 'FIELD_AMOUNT',
  },
  {
    id: 'p',
    name: 'P',
  },
  {
    id: 'q',
    name: 'Q',
  },
]

const TYPE_UPLOAD_OPTION_DEFAULT = 2
const TYPE_UPLOAD_OPTION_ADD = 3
const TYPE_UPLOAD_OPTION_REPLACE = 1

const TYPE_UPLOAD_OPTIONS = [
  {
    id: 3,
    title: 'LABEL_OPTION_TITLE_ADD',
    text: 'LABEL_OPTION_TEXT_ADD',
  },
  {
    id: 1,
    title: 'LABEL_OPTION_TITLE_REPLACE',
    text: 'LABEL_OPTION_TEXT_REPLACE',
  },
  {
    id: 2,
    title: 'LABEL_OPTION_TITLE_OVERWRITE',
    text: 'LABEL_OPTION_TEXT_OVERWRITE',
  },
]

const PLANNING_KEYS = {
  TRANSACTIONAL_KEY: TRANSACTIONAL_KEY,
  PERCENTAGE_KEY: PERCENTAGE_KEY,
  FLAT_KEY: FLAT_KEY,
}

const PROJECTION = {
  I18N,
  BUDGET,
  BUDGET_PERSONALIZED_REPORT,
  BUDGET_VAR_MONEY,
  BUDGET_VAR_PERCENTAGE,
  REAL,
  REAL_LAST_PERIOD_MONEY,
  REAL_LAST_PERIOD_PERCENTAGE,
  FORECAST,
  REAL_LAST_PERIOD,
  LAST_FORECAST,
  LAST_FORECAST_VAR_MONEY,
  LAST_FORECAST_VAR_PERCENTAGE,
  GLOBAL_BUDGET,
  GLOBAL_FORECAST,
  STATUS,
  STATUS_COLORS,
  LOAD_TYPES,
  LOAD_TYPES_LIST,
  STATUS_COLUMN,
  FACTORS_TYPE_OPTIONS,
  VARIABLES_TYPE_OPTIONS,
  CURRENCIES_OPTIONS,
  FUNCTIONAL_KEY,
  ORIGIN_KEY,
  PXQ_KEY,
  PROJECTIONS_OPTIONS,
  BASE,
  TOPDOWN,
  BOTTOMUP,
  CLOSING,
  BASE_KEY,
  BUDGET_LAST_PERIOD,
  OPTIONS_ALL_PROJECTION_TYPE,
  LAST_PERIOD,
  NAME_PROJECTIONS,
  VALUES_TYPES,
  TYPE_UPLOAD_OPTIONS,
  TYPE_UPLOAD_OPTION_DEFAULT,
  TYPE_UPLOAD_OPTION_ADD,
  TYPE_UPLOAD_OPTION_REPLACE,
  CARD_TYPE,
  PLANNING_KEYS,
}

export default PROJECTION

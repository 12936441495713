import ButtonBase from '../../ButtonBase'
import { useTranslation } from 'react-i18next'

const ButtonIcon = ({
  title,
  disabled = false,
  icon = null,
  tooltipTitle = '',
  onClick = () => {},
  ...props
}) => {
  const { t } = useTranslation()
  return (
    // reveer porque el de icon no tine que renderizar nada
    <ButtonBase
      {...props}
      type="text"
      shape="circle"
      title={t(title)}
      disabled={disabled}
      icon={icon}
      onClick={onClick}
      tooltipTitle={tooltipTitle}
    />
  )
}

export default ButtonIcon

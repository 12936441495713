import Immutable from 'seamless-immutable'
import * as types from './constants'

const initialState = Immutable({
  societiesList: {
    count: 0,
    values: [],
  },
  functionsButtons: {
    count: 0,
    values: [],
  },
  societiesDetail: {},
  societyFunctions: {
    count: 0,
    values: [],
  },
  buttonsStandar: [],
  societiesDimensions: {
    count: 0,
    values: [],
  },
  quicksightList: [],
  tagsList: [],
})

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SOCIETIES_LIST_SUCCESS:
      return state.merge({
        societiesList: {
          count: action.payload.data.society.count,
          values: action.payload.data.society.results,
        },
      })

    case types.FETCH_FUNCTIONS_BUTTONS_LIST_SUCCESS:
      return state.merge({
        functionsButtons: {
          count: action.payload.data.functions.length,
          values: action.payload.data.functions,
        },
      })

    case types.FETCH_SOCIETIES_DETAIL_SUCCESS:
      return state.merge({
        societiesDetail: action.payload.data.society.results,
      })

    case types.FETCH_FUNCTIONS_BUTTONS_TENANT_LIST_SUCCESS:
      return state.merge({
        societyFunctions: {
          count: action.payload.data.functions.length,
          values: action.payload.data.functions,
        },
      })

    case types.FETCH_BUTTONS_STANDAR_SUCCESS:
      return state.merge({
        buttonsStandar: action.payload.data,
      })

    case types.FETCH_FUNCTIONS_BUTTONS_DIMENSION_LIST_SUCCESS:
      return state.merge({
        societiesDimensions: {
          count: action.payload.data.length,
          values: action.payload.data,
        },
      })

    case types.FETCH_QUICKSIGHT_LIST_SUCCESS:
      return state.merge({
        quicksightList: action.payload.data.data,
      })

    case types.FETCH_TAGS_LIST_SUCCESS:
      return state.merge({
        tagsList: action.payload.data.results,
      })

    default:
      return state
  }
}

import { Form, Input, message, Modal, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { generateMessageError } from 'modules/core/utils'
import { ChromePicker } from 'react-color'
import societies from 'modules/societies'
import { useState } from 'react'

const CreateTagModal = ({ visible, onClose, isSaving, setIsSaving, onRefetch, createTag }) => {
  const [color, setColor] = useState('')
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const handleCreate = () => {
    form.validateFields().then((values) => {
      setIsSaving(true)
      createTag({ ...values, color: values.color.hex })
        .then(() => {
          onRefetch().then(() => {
            message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
            setIsSaving(false)
            onClose()
          })
        })
        .catch((error) => {
          notification.error({
            message: t('FEEDBACK_DEFAULT_ERROR'),
            description: generateMessageError(error),
            duration: 0,
          })
          setIsSaving(false)
          onClose()
        })
    })
  }

  return (
    <Modal
      title={t('SOCIETY_CREATE_TAG_MODAL_TITLE')}
      visible={visible}
      onCancel={onClose}
      okText={t('ACTION_CREATE')}
      onOk={() => handleCreate()}
      okButtonProps={{ loading: isSaving }}
      cancelButtonProps={{ disabled: isSaving }}
      centered
      forceRender
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t('SOCIETY_NAME_TAG_FORM_LABEL')}
          name="name"
          rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
        >
          <Input placeholder={t('ACTION_ENTER')} />
        </Form.Item>
        <Form.Item
          label={t('Color')}
          name="color"
          rules={[{ required: true, message: t('REQUIRED_FIELD') }]}
        >
          <ChromePicker disableAlpha color={color} onChangeComplete={(col) => setColor(col.hex)} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const mapDispatchToProps = {
  createTag: societies.actions.createTag,
}

export default connect(null, mapDispatchToProps)(CreateTagModal)

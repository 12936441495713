import * as types from './constants'
import { saveAs } from 'file-saver'
import i18n from 'i18next'
import _ from 'lodash'

// ++ DIMENSIONS ++

export const fetchDimensionPlika = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_DIMENSION_PLIKA,
    payload: {
      request: {
        url: `/common/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchColumnsAndRowsSelections =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_COLUMNS_AND_ROWS_SELECTIONS,
      payload: {
        request: {
          url: `/dimensions/data_tables/get/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const createColumnsRows =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_COLUMNS_ROWS,
      payload: {
        request: {
          url: `/dimensions/data_tables/create/`,
          method: 'POST',
          data: data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error.response.data[0])
    }
    return response
  }

export const addNewColumnsRows = (data) => (dispatch) =>
  dispatch({
    type: types.ADD_NEW_COLUMNS_ROWS,
    payload: data,
  })

export const setDataColumnsRows = (data) => (dispatch) =>
  dispatch({
    type: types.SET_DATA_COLUMNS_ROWS,
    payload: data,
  })

export const deleteColumnsRows = (data) => (dispatch) =>
  dispatch({
    type: types.DELETE_COLUMNS_ROWS,
    payload: data,
  })

export const fetchDimensionsList =
  (data = {}) =>
  async (dispatch) => {
    const { roleDimension, ...restProps } = data
    const response = await dispatch({
      type: types.FETCH_DIMENSIONS_LIST,
      payload: {
        request: {
          url: `/dimensions/all_dimensions/`,
          method: 'GET',
          params: restProps,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchRoleDimensionsList = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_ROLE_DIMENSIONS_LIST,
    payload: {
      request: {
        url: `/dimensions/filter/dimensions_available/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchAllDimensions = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_ALL_DIMENSIONS,
    payload: {
      request: {
        url: `/dimensions/filter/dimensions_available/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchAllDimensionsValues =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_ALL_DIMENSIONS_VALUES,
      payload: {
        request: {
          url: `/dimensions/filter/dimensions_value_available/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchAllDimensionsValuesMap =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_ALL_DIMENSIONS_VALUES_MAP,
      payload: {
        request: {
          url: `/dimensions/filter/dimensions_value_available/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchAllDimensionsValuesBudget =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_ALL_DIMENSIONS_VALUES_BUDGET,
      payload: {
        request: {
          url: `/dimensions/filter/dimensions_value_budget/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchDimensionValues =
  (id, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DIMENSION_VALUES,
      payload: {
        request: {
          url: `/dimensions/values/${id}/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

//dimension value especifico de dimensiones
export const fetchValuesDimension = (params) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_VALUES_DIMENSION,
    payload: {
      request: {
        url: `/dimensions/values/`,
        method: 'GET',
        params,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchDimensionValuesParents = (params) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_DIMENSION_VALUES_PARENTS,
    payload: {
      request: {
        url: `/dimensions/values/`,
        method: 'GET',
        params,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const createDimension =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_DIMENSION,
      payload: {
        request: {
          url: `/dimensions/create/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteDimension =
  (id, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_DIMENSION,
      payload: {
        request: {
          url: `/dimensions/delete_dimension/${id}/`,
          method: 'DELETE',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteAllDimensionValues =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_ALL_DIMENSION_VALUES,
      payload: {
        request: {
          url: `/dimensions/values/delete-all-dimension-values/`,
          method: 'DELETE',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const editDimensionName = (id, data) => async (dispatch) => {
  const response = await dispatch({
    type: types.EDIT_DIMENSION_NAME,
    payload: {
      request: {
        url: `/dimensions/dimensions/${id}/`,
        method: 'PATCH',
        data: data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

//editar concepto asociado a dimension
export const editConceptDimension = (data) => async (dispatch) => {
  const response = await dispatch({
    type: types.EDIT_CONCEPT_DIMENSION,
    payload: {
      request: {
        url: `/planning/concept-configs/`,
        method: 'POST',
        data: data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

//eliminar concepto asociado a dimension
export const deleteConceptDimension = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_CONCEPT_DIMENSION,
    payload: {
      request: {
        url: `planning/concept-configs/${id}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error.response.data)
  }
  return response
}

//DIMENSION VALUE
export const createDimensionValue =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_DIMENSION_VALUE,
      payload: {
        request: {
          url: `/dimensions/create_value_dimension/`,
          method: 'POST',
          data: data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const createDimensionValueMasive =
  (id, params = {}, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_DIMENSION_VALUE_MASIVE,
      payload: {
        request: {
          url: `/dimensions/${id}/bulk_create_dimension_values/`,
          method: 'POST',
          data,
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchDimensionValuesKpis = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_DIMENSION_VALUES_KPIS,
    payload: {
      request: {
        url: `/dimensions/dimension_values/${id}/kpis/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const editDimensionValue = (id, data) => async (dispatch) => {
  const response = await dispatch({
    type: types.EDIT_DIMENSION_VALUE,
    payload: {
      request: {
        url: `/dimensions/values/${id}/`,
        method: 'PATCH',
        data: data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const deleteDimensionValue = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_DIMENSION_VALUE,
    payload: {
      request: {
        url: `/dimensions/values/${id}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error.response.data)
  }
  return response
}

export const asignManagementDimension =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.ASIGN_MANAGEMENT_DIMENSION,
      payload: {
        request: {
          url: `/core/management/alias_dimension/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error.response.data[0])
    }
    return response
  }

export const asignManagementDimensionValues =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.ASIGN_MANAGEMENT_DIMENSION_VALUES,
      payload: {
        request: {
          url: `/core/management/alias_value_dimension/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error.response.data[0])
    }
    return response
  }

export const groupAliasDimension =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.GROUP_ALIAS_DIMENSION,
      payload: {
        request: {
          url: `/core/management/group_alias_dimension/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error.response.data[0])
    }
    return response
  }

//BudgetType

export const fetchBudgetTypeList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_BUDGET_TYPE_LIST,
      payload: {
        request: {
          url: `core/concepts/get_concepts/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const createBudgetType =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_BUDGET_TYPE,
      payload: {
        request: {
          url: `/core/concepts/create/`,
          method: 'POST',
          data: data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const editBudgetType =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_BUDGET_TYPE,
      payload: {
        request: {
          url: `/core/concepts/update_concept/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteBudgetType =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_BUDGET_TYPE,
      payload: {
        request: {
          url: `/core/concepts/delete_concept/`,
          method: 'DELETE',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error.response.data)
    }
    return response
  }

//++CREAR PLAN DE CUENTAS++
export const createPlanAccount =
  (data = {}, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_PLAN_ACCOUNT,
      payload: {
        request: {
          url: `/dimensions/values/`,
          method: 'POST',
          data: data,
          params: params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error.response.data[0])
    }
    return response
  }

//++ELIMINAR PLAN DE CUENTA++
export const deletePlanAccount = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_PLAN_ACCOUNT,
    payload: {
      request: {
        url: `/dimensions/values/${id}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error.response.data)
  }
  return response
}

//++EDITAR PLAN DE CUENTA++
export const editPlanAccount =
  (id, data, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_PLAN_ACCOUNT,
      payload: {
        request: {
          url: `/dimensions/values/${id}/`,
          method: 'PATCH',
          data: data,
          params: params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchDimensionDetail = (dimensionId) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_DIMENSION_DETAIL,
    payload: {
      request: {
        url: `/dimensions/dimensions/${dimensionId}/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchDisplaySettings =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DISPLAY_SETTINGS,
      payload: {
        request: {
          url: `/dimensions/get_dimension/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const createDisplaySettings =
  (data = {}, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_DISPLAY_SETTINGS,
      payload: {
        request: {
          url: `/dimensions/user-configs/create-user-config/`,
          method: 'POST',
          data,
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const updateDisplaySettings =
  (settingId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPDATE_DISPLAY_SETTINGS,
      payload: {
        request: {
          url: `/dimensions/update_view_data/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const uploadDimensionValuesXLS = (data, dimension_id) => async (dispatch) => {
  const formData = new FormData()
  data.forEach((file) => {
    formData.append('file', file)
  })
  formData.append('dimension_id', dimension_id)
  const response = await dispatch({
    type: types.UPLOAD_DIMENSION_VALUES_XLS,
    payload: {
      request: {
        url: `/dimensions/upload_excel/`,
        method: 'POST',
        data: formData,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error.response.data[0])
  }
  return response
}

export const fetchDimensionTemplateXLS =
  (fileName, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DIMENSION_TEMPLATE_XLS,
      payload: {
        request: {
          url: `/dimensions/export_excel/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    saveAs(response.payload.data, fileName)
    return Promise.resolve()
  }

export const fetchDimensionValuesConsolidatedXLS =
  (fileName = i18n.t('CONFIG_DIMENSIONS_VALUES_FILE_NAME'), params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_DIMENSION_VALUES_CONSOLIDATED_XLS,
      payload: {
        request: {
          url: `/dimensions/values/consolidated/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    saveAs(response.payload.data, fileName)
    return Promise.resolve()
  }

// ++ USERS ++
export const fetchUsersList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_USERS_LIST,
      payload: {
        request: {
          url: `/auth/list_users/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchUserDetail =
  (userId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_USER_DETAIL,
      payload: {
        request: {
          url: `/auth/users/${userId}/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const assignRole = (id, data) => async (dispatch) => {
  const response = await dispatch({
    type: types.ASSIGN_ROLE,
    payload: {
      request: {
        url: `/auth/user/${id}/assign_role/`,
        method: 'POST',
        data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchUserRoleRelation =
  (userId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_USER_ROLE_RELATION,
      payload: {
        request: {
          url: `/auth/all_user_role/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteUserRoleRelation = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_USER_ROLE_RELATION,
    payload: {
      request: {
        url: `/auth/delete_user_role/${id}/ `,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const downloadUserList =
  (filename = i18n.t('CONFIG_USER_LIST_FILE_NAME')) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_USER_CONSOLIDATED_XLS,
      payload: {
        request: {
          url: `/auth/download_user_list/`,
          method: 'GET',
          responseType: 'blob',
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    saveAs(response.payload.data, `${filename}.xlsx`)
    return Promise.resolve()
  }

export const fetchPlanAccountsConsolidatedXLS =
  (
    fileName = i18n.t('CONFIG_ACCOUNTS_STRUCTURE_FILE_NAME'),
    params = {
      dimension_name: 'Nivel',
    },
  ) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_PLAN_ACCOUNTS_CONSOLIDATED_XLS,
      payload: {
        request: {
          url: `/dimensions/values/consolidated/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    saveAs(response.payload.data, fileName)
    return Promise.resolve()
  }

export const fetchUserTemplateXLS =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_USER_TEMPLATE_XLS,
      payload: {
        request: {
          url: `/auth/users/template/`,
          method: 'GET',
          responseType: 'blob',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }

    saveAs(response.payload.data, i18n.t('CONFIG_USERS_TEMPLATE'))
    return Promise.resolve()
  }

export const fetchUserConsolidatedXLS = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_USER_CONSOLIDATED_XLS,
    payload: {
      request: {
        url: `/auth/users/consolidated/`,
        method: 'GET',
        responseType: 'blob',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }

  saveAs(response.payload.data, i18n.t('CONFIG_USERS_FILE_NAME'))
  return Promise.resolve()
}

export const uploadUsersXLS =
  (data, params = {}) =>
  async (dispatch) => {
    const formData = new FormData()
    data.forEach((file) => {
      formData.append('file', file)
    })
    const response = await dispatch({
      type: types.UPLOAD_USERS_XLS,
      payload: {
        request: {
          url: `/auth/users/excel-upload/`,
          method: 'POST',
          data: formData,
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error.response.data[0])
    }
    return response
  }

export const fetchUserRoles =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_USER_ROLES,
      payload: {
        request: {
          url: `/auth/users/roles/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const createUser = (data) => async (dispatch) => {
  const response = await dispatch({
    type: types.CREATE_USER,
    payload: {
      request: {
        url: `/auth/users/`,
        method: 'POST',
        data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const editUser = (id, data) => async (dispatch) => {
  const response = await dispatch({
    type: types.EDIT_USER,
    payload: {
      request: {
        url: `/auth/users/${id}/`,
        method: 'PATCH',
        data: data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return Promise.resolve()
}

export const deleteUser =
  (userId, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_USER,
      payload: {
        request: {
          url: `/auth/delete_user/${userId}/`,
          method: 'DELETE',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

//  ++ VARIABLES +++

export const fetchVariablesList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_VARIABLES_LIST,
      payload: {
        request: {
          url: `/planning/variables/list_all/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchVariableDetail =
  (variableId, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_VARIABLE_DETAIL,
      payload: {
        request: {
          url: `/planning/variables/${variableId}/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchVariablesKpis =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_VARIABLES_KPI,
      payload: {
        request: {
          url: `/planning/variables/kpi/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const createVariable =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_VARIABLE,
      payload: {
        request: {
          url: `/planning/variables/create/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteVariable = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_VARIABLE,
    payload: {
      request: {
        url: `/planning/variables/delete/${id}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const editVariable = (id, data) => async (dispatch) => {
  const response = await dispatch({
    type: types.UPDATE_VARIABLE,
    payload: {
      request: {
        url: `/planning/variables/${id}/`,
        method: 'PUT',
        data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return Promise.resolve()
}

export const editVariableValues =
  (id, data = {}, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPDATE_VARIABLE_VALUES,
      payload: {
        request: {
          url: `/planning/variables/${id}/drawer_update/`,
          method: 'PATCH',
          data: data,
          params: params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return Promise.resolve()
  }

export const fetchBehaviourList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_BEHAVIOUR_LIST,
      payload: {
        request: {
          url: `/planning/behaviours/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

// Fórmulas

export const setVariablesValues =
  (data = {}) =>
  (dispatch) =>
    dispatch({
      type: types.SET_VARIABLES_VALUES,
      payload: data,
    })

export const fetchRulesList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_RULES_LIST,
      payload: {
        request: {
          url: `/planning/business_rules/list_all/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const setDimensionsSelection =
  (data = {}) =>
  (dispatch) =>
    dispatch({
      type: types.SET_DIMENSIONS_SELECTION,
      payload: data,
    })

export const deleteDimensionsSelection =
  (data = {}) =>
  (dispatch) =>
    dispatch({
      type: types.DELETE_DIMENSIONS_SELECTION,
      payload: data,
    })

export const addDimensionsSelection =
  (data = {}) =>
  (dispatch) =>
    dispatch({
      type: types.ADD_DIMENSIONS_SELECTION,
      payload: data,
    })

export const setDataFormula =
  (data = {}) =>
  (dispatch) =>
    dispatch({
      type: types.SET_DATA_FORMULA,
      payload: data,
    })

export const setClearData = () => (dispatch) =>
  dispatch({
    type: types.SET_CLEAR_DATA,
  })

export const setRowsDimensions = () => (dispatch) =>
  dispatch({
    type: types.SET_ROWS_DIMENSIONS,
  })

export const createRule =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_RULE,
      payload: {
        request: {
          url: `/planning/business_rules/create_complete/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteRule =
  (id, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_RULE,
      payload: {
        request: {
          url: `/planning/business_rules/delete/${id}/`,
          method: 'DELETE',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error.response.data)
    }
    return response
  }

export const fetchFactoresList =
  (id, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_FACTORES_LIST,
      payload: {
        request: {
          url: `/planning/factores/${id}/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const createFormulaFactor =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_FORMULA_FACTOR,
      payload: {
        request: {
          url: `/planning/formulas/`,
          method: 'POST',
          data: data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error.response.data[0])
    }
    return response
  }

export const fetchFormulaDetail =
  (id, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_FORMULA_DETAIL,
      payload: {
        request: {
          url: `/planning/formulas/${id}/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const createFactor =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_FACTOR,
      payload: {
        request: {
          url: `/planning/factores/`,
          method: 'POST',
          data: data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const editFactor =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPDATE_FACTOR,
      payload: {
        request: {
          url: `/planning/factores/${id}/`,
          method: 'PATCH',
          data: data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return Promise.resolve()
  }

export const deleteFactor =
  (id, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_FACTOR,
      payload: {
        request: {
          url: `/planning/factores/${id}/`,
          method: 'DELETE',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error.response.data)
    }
    return response
  }

export const fetchFactoresBehaviours = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_FACTORES_BEHAVIOURS,
    payload: {
      request: {
        url: `/planning/factor-behaviours/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

// tipo de presupuestos
export const deleteBudgetTypeAssociation = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_BUDGET_TYPE_ASSOCIATION,
    payload: {
      request: {
        url: `/planning/concept-configs/${id}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const createBudgetTypeAssociation =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_BUDGET_TYPE_ASSOCIATION,
      payload: {
        request: {
          url: `/planning/concept-configs/`,
          method: 'POST',
          data: {
            dimension_id: data.dimension,
            concept: data.concept,
          },
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

//ACCIONES FORMULA
export const editRule = (id, data) => async (dispatch) => {
  const response = await dispatch({
    type: types.UPDATE_FORMULA,
    payload: {
      request: {
        url: `/planning/business_rules/${id}/`,
        method: 'PATCH',
        data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return Promise.resolve()
}

export const desactivateFormula = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DESACTIVATE_FORMULA,
    payload: {
      request: {
        url: `/planning/formulas/${id}/deactivate/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

//creacion nuevo reporte
export const createOtherReport =
  (data, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_OTHER_REPORT,
      payload: {
        request: {
          url: '/report/custom/',
          method: 'POST',
          data,
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchOtherReportsList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_OTHER_REPORT_LIST,
      payload: {
        request: {
          url: `/report/custom/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const editReportName =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_REPORT_NAME,
      payload: {
        request: {
          url: `/reports/update_report_name/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const updateOtherReportName =
  (id, data = {}, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.UPDATE_REPORT_NAME,
      payload: {
        request: {
          url: `/report/custom/${id}/`,
          method: 'PATCH',
          data,
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteOtherReport =
  (id, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_OTHER_REPORT,
      payload: {
        request: {
          url: `/report/custom/${id}/`,
          method: 'DELETE',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error.response.data)
    }
    return response
  }

export const fetchOtherReportStructure =
  (id, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_OTHER_REPORT_STRUCTURE,
      payload: {
        request: {
          url: `/report/custom/${id}/info/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

// ++ ROLES ++
export const fetchRolesList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_ROLES_LIST,
      payload: {
        request: {
          url: `/auth/role/get_roles/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const createRole =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_ROLE,
      payload: {
        request: {
          url: `auth/role/create/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const duplicateRole =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DUPLICATE_ROLE,
      payload: {
        request: {
          url: `/auth/role/duplicate_role/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const editRole =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_ROLE,
      payload: {
        request: {
          url: `/auth/role/update/${id}/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteRole = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_ROLE,
    payload: {
      request: {
        url: `/auth/role/delete/${id}/`,
        data: { id: id },
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const editRoleOperations =
  ({ checked = false, operationsKeys = [], dimensions = [] }) =>
  (dispatch) =>
    dispatch({
      type: types.EDIT_ROLE_OPERATIONS,
      payload: { checked, operationsKeys, dimensions },
    })

export const saveRoleOperations = (id, data) => async (dispatch) => {
  const response = await dispatch({
    type: types.SAVE_ROLE_OPERATIONS,
    payload: {
      request: {
        url: `/auth/role/${id}/toggle_permission/`,
        method: 'POST',
        data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchRoleDimensionsValues =
  (data, params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_ROLE_DIMENSIONS_VALUES,
      payload: {
        request: {
          url: `/auth/users/dimensions/get_dimension/`,
          method: 'POST',
          data,
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const selectRoleDimension =
  (data = {}) =>
  (dispatch) =>
    dispatch({
      type: types.SELECT_ROLE_DIMENSION,
      payload: { data },
    })

export const cleanAllRoleDimension = () => (dispatch) =>
  dispatch({
    type: types.CLEAN_ROLE_DIMENSION,
  })

export const asignRoleDimensions =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.ASIGN_ROLE_OPERATIONS,
      payload: {
        request: {
          url: `/auth/role/${id}/save_data_permissions/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchConceptAccountAssociationTemplateXLS = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_CONCEPT_ACCOUNT_ASSOCIATION_TEMPLATE_XLS,
    payload: {
      request: {
        url: '/dimensions/cuenta-concept/template/',
        method: 'GET',
        responseType: 'blob',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }

  saveAs(response.payload.data, i18n.t('CONFIG_ACCOUNT_CONCEPT_ASSOCIATION_TEMPLATE_NAME'))
  return Promise.resolve()
}

export const uploadConceptAccountAssociationXLS = (data) => async (dispatch) => {
  const formData = new FormData()
  data.forEach((file) => {
    formData.append('file', file)
  })
  const response = await dispatch({
    type: types.UPLOAD_CONCEPT_ACCOUNT_ASSOCIATION_XLS,
    payload: {
      request: {
        url: '/dimensions/cuenta-concept/excel-upload/',
        method: 'POST',
        data: formData,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const editConceptCascader = (data) => async (dispatch) => {
  const response = await dispatch({
    type: types.EDIT_CONCEPT_CASCADER,
    payload: {
      request: {
        url: '/dimensions/cuenta-concept/',
        method: 'POST',
        data: data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

/*-------MULTIMONEDA--------*/
export const fetchCurrencyList = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_CURRENCY_LIST,
    payload: {
      request: {
        url: `/currency/list_currency/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const createCurrencies = (data) => async (dispatch) => {
  const response = await dispatch({
    type: types.CREATE_CURRENCIES,
    payload: {
      request: {
        url: `/currency/create_currency/`,
        method: 'POST',
        data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const editCurrencies = (data) => async (dispatch) => {
  const response = await dispatch({
    type: types.EDIT_CURRENCIES,
    payload: {
      request: {
        url: `/currency/update_currency/`,
        method: 'PUT',
        data,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const deleteCurrencies = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_CURRENCIES,
    payload: {
      request: {
        url: `/currency/${id}/delete_currency/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const setPin = (data) => (dispatch) =>
  dispatch({
    type: types.SET_PIN,
    payload: data,
  })

export const fetchExchangeRateList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_EXCHANGE_RATE_LIST,
      payload: {
        request: {
          url: `/currency/exchange-rate/list_rate_currency/`,
          method: 'GET',
          params,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const setExchangeRateValues =
  (data = {}) =>
  (dispatch) =>
    dispatch({
      type: types.SET_EXCHANGE_RATE_VALUES,
      payload: data,
    })

export const editExchangeRate =
  (idMoneda, params = {}, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_EXCHANGE_RATE,
      payload: {
        request: {
          url: `/currency/exchange-rate/${idMoneda}/update_rate_currency/`,
          method: 'PUT',
          params,
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const setCurrencyAction =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.SET_CURRENCY_ACTION,
      payload: {
        request: {
          url: `/planning/currency/${id}/`,
          method: 'PATCH',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const predefineCurrency = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.PREDEFINE_CURRENCY,
    payload: {
      request: {
        url: `/planning/currency/${id}/predefine/`,
        method: 'POST',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

/*-------ONBOARDING--------*/
// export const fetchOnboardingSteps = () => async dispatch => {
//   const response = await dispatch({
//     type: types.FETCH_ONBOARDING_STEPS,
//     payload: {
//       request: {
//         url: '/auth/users/current/',
//         method: 'GET',
//       },
//     },
//   });
//   if (response.error) {
//     return Promise.reject(response.error);
//   }
//   return response;
// };

export const setOnboardingStep =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.SET_ONBOARDING_STEP,
      payload: {
        request: {
          url: '/auth/users/update_onboarding/',
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteDimensionValuesSelections =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_DIMENSION_VALUES_SELECTION,
      payload: {
        request: {
          url: `/dimensions/delete_dimension_values/${id}/`,
          method: 'DELETE',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteUsersSelections =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_USERS_SELECTION,
      payload: {
        request: {
          url: `/auth/list_users/`,
          method: 'DELETE',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteDimensionsSelections =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_DIMENSIONS_SELECTIONS,
      payload: {
        request: {
          url: `/dimensions/delete_dimensions/`,
          method: 'DELETE',
          data,
        },
      },
    })

    if (response.error) {
      return Promise.reject(response.error)
    }

    return response
  }

export const deleteAllDimensions =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_ALL_DIMENSIONS,
      payload: {
        request: {
          url: `/dimensions/delete_all_dimensions/`,
          method: 'DELETE',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const downloadTableFile = (fileName) => async (dispatch) => {
  const response = await dispatch({
    type: types.DOWNLOAD_TABLE_FILE,
    payload: {
      request: {
        url: `/dimensions/download_dimensions/`,
        method: 'GET',
        responseType: 'blob',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  saveAs(response.payload.data, fileName)
  return Promise.resolve()
}

export const downloadDimensionValuesFile = (id, fileName) => async (dispatch) => {
  const response = await dispatch({
    type: types.DOWNLOAD_DIMENSION_VALUES_FILE,
    payload: {
      request: {
        url: `/dimensions/dimension_values/${id}/download/`,
        method: 'GET',
        responseType: 'blob',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  saveAs(response.payload.data, fileName)
  return Promise.resolve()
}

export const deleteRolesSelections =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_ROLES_SELECTIONS,
      payload: {
        request: {
          url: `/auth/role/delete_list/`,
          method: 'DELETE',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const activateUser = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.ACTIVATE_USER,
    payload: {
      request: {
        url: `/auth/activate_user/${id}/`,
        method: 'PATCH',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const resetPasswordUser = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.RESET_PASSWORD_USER,
    payload: {
      request: {
        url: `/auth/reset_password/${id}/`,
        method: 'PATCH',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const uploadReportStructure = (report_id, files) => async (dispatch) => {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('files_data', file)
  })
  const response = await dispatch({
    type: types.UPLOAD_REPORT_STRUCTURE,
    payload: {
      request: {
        url: `/reports/overwrite_report_config/${report_id}/`,
        method: 'POST',
        data: formData,
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchNotificationsList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_NOTIFICATIONS_LIST,
      payload: {
        request: {
          url: `/notifications/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const setShowAllComments =
  (data = {}) =>
  (dispatch) =>
    dispatch({
      type: types.SET_SHOW_ALL_COMMENTS,
      payload: data,
    })

export const setElementRefId =
  (data = {}) =>
  (dispatch) =>
    dispatch({
      type: types.SET_ELEMENT_REF_ID,
      payload: data,
    })

export const setHasComment =
  (data = {}) =>
  (dispatch) =>
    dispatch({
      type: types.SET_HAS_COMMENT,
      payload: data,
    })

export const createComment =
  (data = {}) =>
  async (dispatch) => {
    const formData = new FormData()
    _.forEach(data, (value, key) => {
      if (key === 'mentions') {
        if (!_.isEmpty(value)) {
          value = _.uniq(value)
          formData.append(key, value)
        }
      } else {
        formData.append(key, value)
      }
    })

    const response = await dispatch({
      type: types.CREATE_COMMENT,
      payload: {
        request: {
          url: `/comments/`,
          method: 'POST',
          data: formData,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const markReaded =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.MARK_READED,
      payload: {
        request: {
          url: `/notifications/mark_readed/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }
export const editComment =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_COMMENT,
      payload: {
        request: {
          url: `/comments/${id}/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteComment = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_COMMENT,
    payload: {
      request: {
        url: `/comments/${id}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchCommentsList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_COMMENTS_LIST,
      payload: {
        request: {
          url: `/comments/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const resolveComment = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.RESOLVE_COMMENT,
    payload: {
      request: {
        url: `/comments/${id}/resolve/`,
        method: 'POST',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const setNewComment =
  (data = {}) =>
  (dispatch) =>
    dispatch({
      type: types.SET_NEW_COMMENT,
      payload: data,
    })

export const removeNewComment =
  (data = {}) =>
  (dispatch) =>
    dispatch({
      type: types.REMOVE_NEW_COMMENT,
      payload: data,
    })

export const setNewNotification =
  (data = {}) =>
  (dispatch) =>
    dispatch({
      type: types.SET_NEW_NOTIFICATION,
      payload: data,
    })

export const setCounter =
  (data = {}) =>
  (dispatch) =>
    dispatch({
      type: types.SET_COUNTER,
      payload: data,
    })

export const deleteMassiveReport =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.DELETE_MASSIVE_REPORT,
      payload: {
        request: {
          url: `/reports/delete_massive_report/`,
          method: 'DELETE',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchAreasList =
  (params = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.FETCH_AREAS_LIST,
      payload: {
        request: {
          url: `/auth/areas/`,
          method: 'GET',
          params,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const createArea =
  (data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_AREA,
      payload: {
        request: {
          url: `/auth/areas/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteArea = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_AREA,
    payload: {
      request: {
        url: `/auth/areas/${id}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const editArea =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_AREA,
      payload: {
        request: {
          url: `/auth/areas/${id}/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchAreaDetail = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_AREA_DETAIL,
    payload: {
      request: {
        url: `/auth/areas/${id}/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchLevels = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_LEVELS,
    payload: {
      request: {
        url: `/auth/${id}/levels/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const createLevel =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.CREATE_LEVEL,
      payload: {
        request: {
          url: `/auth/${id}/levels/`,
          method: 'POST',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const deleteLevel = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.DELETE_LEVEL,
    payload: {
      request: {
        url: `/auth/levels/${id}/`,
        method: 'DELETE',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const editLevel =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_LEVEL,
      payload: {
        request: {
          url: `/auth/levels/${id}/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const restrictAreas =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.RESTRICT_AREAS,
      payload: {
        request: {
          url: `/auth/areas_restrict/${id}/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const fetchUnmappedValues = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_UNMAPPED_VALUES,
    payload: {
      request: {
        url: `/dimensions/dimension_values/unmapped/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}
export const fetchUserProfile = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_USER_PROFILE,
    payload: {
      request: {
        url: `/auth/profile/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const applyMap = () => async (dispatch) => {
  const response = await dispatch({
    type: types.APPLY_MAP,
    payload: {
      request: {
        url: `/dimensions/dimension_values/apply_mapping/`,
        method: 'POST',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const checkIsMap = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.CHECK_IS_MAP,
    payload: {
      request: {
        url: `/dimensions/migration_check/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}
export const editProfile =
  (id, data = {}) =>
  async (dispatch) => {
    const response = await dispatch({
      type: types.EDIT_PROFILE,
      payload: {
        request: {
          url: `/auth/profile/${id}/`,
          method: 'PUT',
          data,
        },
      },
    })
    if (response.error) {
      return Promise.reject(response.error)
    }
    return response
  }

export const userDetail = (id) => async (dispatch) => {
  const response = await dispatch({
    type: types.USER_DETAIL,
    payload: {
      request: {
        url: `/auth/user/${id}/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

export const fetchOnlineUsers = () => async (dispatch) => {
  const response = await dispatch({
    type: types.FETCH_ONLINE_USERS,
    payload: {
      request: {
        url: `/auth/online/`,
        method: 'GET',
      },
    },
  })
  if (response.error) {
    return Promise.reject(response.error)
  }
  return response
}

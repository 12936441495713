import {
  DeleteOutlined,
  FileSyncOutlined,
  PlusCircleOutlined,
  SolutionOutlined,
} from '@ant-design/icons'
import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'
import { Popconfirm, Space, Tooltip } from 'antd'
import { Button, ButtonActionBar, CustomBottomAction, DropdownMenu } from 'modules/core/components'
import React from 'react'
import { useTranslation } from 'react-i18next'

const isStepClosing = (step) => step === 'closing'

const UserActionsPhases = ({
  onConfirmPopConfirm,
  onCancelPopconfirm,
  showPopConfirmSend,
  onClickSend,
  buttonName,
  disabled,
  dataPhase,
  loading,
  is_closing,
  buttonUploadData,
  onClickActivity,
  onClickApply,
  loadingDelete,
  visible,
  onCancelDeleteAll,
  onConfirmDeleteAll,
  onClickDeleteAll,
  onClickCreate,
  hasCreate = false,
  extraButtons,
  type = '',
  module = '',
  isDisable,
  currentStep = undefined,
  onClickUpload = () => {},
}) => {
  const abUploadExcelFile = useFeatureIsOn('ab_upload_excel_file')
  const actionStepByRole = useFeatureValue('feature-action-step-role', {
    planning: 'True',
    forecast: 'True',
  })
  const { t } = useTranslation()

  const getDisabled = () => {
    const canCreate = !disabled
    if (canCreate) {
      switch (module) {
        case 'planning':
          return !(actionStepByRole.planning === 'True')
        case 'forecast':
          return !(actionStepByRole.forecast === 'True')
        default:
          return false
      }
    }
    return disabled
  }

  const getDisabledTools = () => {
    if (dataPhase.key !== 'base' && !isDisable) {
      switch (module) {
        case 'planning':
          return !(actionStepByRole.planning === 'True')
        case 'forecast':
          return !(actionStepByRole.forecast === 'True')
        default:
          return false
      }
    }
    return isDisable
  }

  const getDisabledAction = () => {
    if (dataPhase.key !== 'base') {
      switch (module) {
        case 'planning':
          return !(actionStepByRole.planning === 'True')
        case 'forecast':
          return !(actionStepByRole.forecast === 'True')
        default:
          return false
      }
    } else if (currentStep != null && currentStep !== dataPhase.key) {
      switch (module) {
        case 'planning':
          return !(actionStepByRole.planning === 'True')
        case 'forecast':
          return !(actionStepByRole.forecast === 'True')
        default:
          return false
      }
    }
    return false
  }

  return (
    <ButtonActionBar>
      <Space>
        {hasCreate && (
          <Tooltip title={t('ADD_NEW_TRANSACTION_INFORMATIVE_TOOLTIP_TITLE')}>
            <Button.PrimaryWhitRightIcon
              icon={<PlusCircleOutlined />}
              title="ACTION_ADD_NEW"
              onClick={onClickCreate}
              disabled={disabled}
            />
          </Tooltip>
        )}
        <Popconfirm
          placement="bottomRight"
          title={
            <div style={{ width: 300 }}>
              {t('PLANNING_PHASES_ACTION_CONFIRM_POPCONFIRM_TITLE', {
                data: isStepClosing(dataPhase.key)
                  ? t('PLANNING_PHASES_CLOSE_CONFIRM_POPCONFIRM_TITLE')
                  : t('PLANNING_PHASES_SEND_CONFIRM_POPCONFIRM_TITLE'),
                action: isStepClosing(dataPhase.key)
                  ? t('ACTION_CLOSE').toLowerCase()
                  : t('ACTION_SEND').toLowerCase(),
              })}
            </div>
          }
          okText={isStepClosing(dataPhase.key) ? t('ACTION_CLOSE') : t('ACTION_SEND')}
          onConfirm={onConfirmPopConfirm}
          okButtonProps={{ loading: loading }}
          cancelText={t('ACTION_CANCEL')}
          onCancel={onCancelPopconfirm}
          visible={showPopConfirmSend}
        >
          <Button.Primary
            disabled={getDisabled()}
            onClick={onClickSend}
            loading={loading}
            title={buttonName}
          />
        </Popconfirm>
        {!is_closing && buttonUploadData}
        <Button.Default onClick={onClickApply} disabled={getDisabledTools()} title="LABEL_TOOLS" />
        <CustomBottomAction extraButtons={extraButtons} disabled={getDisabledAction()} />
        <DropdownMenu
          title={t('ACTION_MORE')}
          menu={[
            {
              title: t('ACTION_ACTIVITIES'),
              icon: <SolutionOutlined />,
              onClick: onClickActivity,
            },
            ...(abUploadExcelFile
              ? [
                  {
                    title: t('LABEL_PROCESS_UPLOAD'),
                    icon: <FileSyncOutlined />,
                    onClick: onClickUpload,
                  },
                ]
              : []),
            {
              title: (
                <Tooltip
                  title={t('DELETE_ALL_INFORMATIVE_TOOLTIP_TITLE', {
                    extraText:
                      type === 'forecast' ? t('EXTRA_TEXT_DELETE_INFORMATIVE_TOOLTIP_TITLE') : '',
                  })}
                >
                  {t('ACTION_DELETE_ALL')}
                </Tooltip>
              ),
              icon: <DeleteOutlined />,
              onClick: onClickDeleteAll,
              disabled: getDisabledAction(),
            },
          ]}
        />
        <Popconfirm
          title={t('POPCONFIRM_DELETE_ALL_PAGES')}
          placement="right"
          okText={t('ACTION_DELETE')}
          okButtonProps={{ loading: loadingDelete }}
          cancelButtonProps={{ disabled: loadingDelete }}
          onConfirm={onConfirmDeleteAll}
          cancelText={t('ACTION_CANCEL')}
          onCancel={onCancelDeleteAll}
          visible={visible}
        />
      </Space>
    </ButtonActionBar>
  )
}

export default UserActionsPhases

import { message, notification } from 'antd'
import { UploadModal } from 'modules/core/components'
import { generateMessageError } from 'modules/core/utils'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import configuration from 'modules/configuration'
import { useParams } from 'react-router-dom'

const UploadImgModal = ({ visible, onClose, onRefetch, editProfile }) => {
  const [fileList, setFileList] = useState([])
  const [isSaving, setIsSaving] = useState(false)
  let { userId } = useParams()
  const { t } = useTranslation()

  const onAddUploadFile = (file) => {
    setFileList([file])
    return false
  }

  const handleEditPhoto = () => {
    setIsSaving(true)
    const formData = new FormData()
    fileList.forEach((file) => {
      formData.append('photo', file)
    })
    editProfile(userId, formData)
      .then(() => {
        onRefetch()
        message.success(t('FEEDBACK_CHANGES_SAVED_SUCCESS'), 8)
        setIsSaving(false)
        setFileList([])
        onClose()
      })
      .catch((error) => {
        setIsSaving(false)
        onClose()
        setFileList([])
        notification.error({
          message: t('FEEDBACK_DEFAULT_ERROR'),
          description: generateMessageError(error),
          duration: 0,
        })
      })
  }

  return (
    <UploadModal
      title={t('CONFIG_PROFILE_MODIFY_PHOTO_MODAL_TITLE')}
      visible={visible}
      onCancel={onClose}
      onConfirm={() => handleEditPhoto()}
      uploadProps={{
        listType: 'picture',
        accept: '.jpg, .png',
        showUploadList: { showPreviewIcon: false },
        beforeUpload: onAddUploadFile,
        onRemove: (file) => setFileList([]),
        fileList: fileList,
        maxCount: 1,
      }}
      isUploadingFile={isSaving}
    />
  )
}

const mapDispatchToProps = {
  editProfile: configuration.actions.editProfile,
}

export default connect(null, mapDispatchToProps)(UploadImgModal)

import React from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Popover, Select, Space, Spin, Tooltip } from 'antd'
import { Button, Typography } from 'modules/core/components'
import { PROJECTION, REPORT } from 'modules/core/constants'
import { generateSelectOptions, setPhaseAvailable } from 'modules/core/utils'
import i18n from 'i18next'
import _ from 'lodash'

const { OptGroup, Option } = Select

const renderOptionSelect = ({ label, globalList, parentId, disabled = false }) => {
  return (
    <OptGroup label={label}>
      {globalList.map((global) => (
        <Option key={`${parentId}-${global.id}`} title={global.name} disabled={disabled}>
          <Tooltip
            placement="right"
            title={disabled ? i18n.t('REPORT_FINANCIAL_NO_DATA_INFORMATIVE_TOOLTIP_TITLE') : ''}
          >
            {global.name}
          </Tooltip>
        </Option>
      ))}
    </OptGroup>
  )
}

const renderGroupWithOption = ({ label, parentId, optionList, disabledOption }) => {
  const optionKey = (optionId) => `${parentId}-${optionId}`
  return (
    <OptGroup label={label}>
      {optionList.map((option) => (
        <Option
          key={optionKey(option.id)}
          title={option.name}
          disabled={disabledOption === optionKey(option.id)}
        >
          {option.name}
        </Option>
      ))}
    </OptGroup>
  )
}

const renderBudgetSelect = (globalBudgetList) => {
  return renderOptionSelect({
    label: i18n.t('LABEL_BUDGET'),
    globalList: globalBudgetList,
    parentId: PROJECTION.BUDGET,
  })
}

// const renderForecastSelect = ({ globalForecatList, disabled = false }) => {
//   return renderOptionSelect({
//     label: i18n.t('LAST_FORECAST'),
//     globalList: globalForecatList,
//     parentId: PROJECTION.LAST_FORECAST,
//     disabled,
//   });
// };

const renderInformativeIcon = (dataBudget) => {
  return (
    <Tooltip
      placement="topLeft"
      title={i18n.t('POPOVER_NOT_GLOBAL_BUDGET_FIXED', {
        type: !dataBudget
          ? i18n.t('LABEL_BUDGET').toLowerCase()
          : i18n.t('LABEL_FORECAST').toLowerCase(),
      })}
    >
      <Typography.Icon icon={ExclamationCircleOutlined} type="warning" />
    </Tooltip>
  )
}

const renderGlobalForecastSelect = (globalForecatList) => {
  return renderOptionSelect({
    label: i18n.t('LABEL_FORECAST'),
    globalList: globalForecatList,
    parentId: PROJECTION.FORECAST,
  })
}

const EvolutionSelect = ({
  loading = false,
  activeOptionTable,
  setActiveByPeriodTable,
  groupProjection = {
    BUDGET: [],
    FORECAST: [],
  },
}) => {
  return (
    <Spin spinning={loading} size="small">
      <Tooltip title={i18n.t('REPORT_SELECT_PROYECTION_INFORMATIVE_TOOLTIP_TITLE')}>
        <Select
          className="report-detail-table-actions-select"
          value={
            !loading &&
            `${activeOptionTable.value}${activeOptionTable.id ? `-${activeOptionTable.id}` : ''}`
          }
          showArrow
          showSearch
          onChange={(value) =>
            setActiveByPeriodTable(
              value.includes('-')
                ? {
                    value: _.split(value, '-', 1)[0],
                    id: _.split(value, '-', 2)[1],
                  }
                : { value: value, id: null },
            )
          }
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children && option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value={PROJECTION.REAL} title={i18n.t('REAL')}>
            {i18n.t('REAL')}
          </Option>
          {groupProjection.BUDGET.length > 0 && renderBudgetSelect(groupProjection.BUDGET)}
          {groupProjection.FORECAST.length > 0 &&
            renderGlobalForecastSelect(groupProjection.FORECAST)}
        </Select>
      </Tooltip>
    </Spin>
  )
}

const ComparisonSelect = ({
  loading = false,
  baseReference,
  selectedOptions,
  setSelectedOptions,
  groupProjection = {
    BUDGET: [],
    FORECAST: [],
  },
}) => {
  let disabledOptionId = baseReference
    ? `${baseReference.value}${baseReference.id ? `-${baseReference.id}` : ''}`
    : undefined

  const renderOptionBudget = (optionList, disabledOption) =>
    renderGroupWithOption({
      label: i18n.t('LABEL_BUDGET'),
      parentId: PROJECTION.BUDGET,
      optionList: optionList,
      disabledOption: disabledOption,
    })

  const renderOptionForecast = (optionList, disabledOption) =>
    renderGroupWithOption({
      label: i18n.t('LABEL_FORECAST'),
      parentId: PROJECTION.FORECAST,
      optionList: optionList,
      disabledOption: disabledOption,
    })

  return (
    <Space>
      {_.filter(selectedOptions, null).length === 1 &&
        renderInformativeIcon(selectedOptions.includes(PROJECTION.BUDGET))}
      <Popover
        title={i18n.t('FIELD_PREDETERMINED_BUDGET')}
        placement="top"
        content={
          <div style={{ width: 300 }}>
            {_.filter(selectedOptions, (item) => {
              return item.startsWith(PROJECTION.BUDGET) || item.startsWith(PROJECTION.FORECAST)
            }).length === 0
              ? i18n.t('POPOVER_NOT_DEFAULT_GLOBAL_BUDGET_DESCRIPTION')
              : i18n.t('POPOVER_DEFAULT_GLOBAL_BUDGET_DESCRIPTION')}
          </div>
        }
      >
        <Spin spinning={loading} size="small">
          <Select
            style={{ width: 300 }}
            value={
              !loading && selectedOptions
                ? selectedOptions.filter((item) => item !== disabledOptionId)
                : undefined
            }
            showArrow
            showSearch
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children && option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            maxTagCount={2}
            maxTagTextLength={6}
            onChange={(value) => {
              if (value.length > 0) {
                setSelectedOptions(value)
              }
            }}
          >
            <Option
              value={PROJECTION.REAL}
              title={i18n.t('REAL')}
              disabled={disabledOptionId === PROJECTION.REAL}
            >
              {i18n.t('REAL')}
            </Option>
            {groupProjection.BUDGET.length > 0 &&
              renderOptionBudget(groupProjection.BUDGET, disabledOptionId)}
            {groupProjection.FORECAST.length > 0 &&
              renderOptionForecast(groupProjection.FORECAST, disabledOptionId)}
            <Option
              value={PROJECTION.REAL_LAST_PERIOD}
              title={i18n.t('REAL_LAST_PERIOD')}
              disabled={disabledOptionId === PROJECTION.REAL_LAST_PERIOD}
            >
              {i18n.t('REAL_LAST_PERIOD')}
            </Option>
          </Select>
        </Spin>
      </Popover>
    </Space>
  )
}

const ReportSelect = ({ defaultSelectValue, setDefaultSelectValue, selection }) => {
  const REPORT_OPTIONS = [
    {
      id: REPORT.REPORTS_KEYS.REPORTE_KEY,
      name: i18n.t('LABEL_REPORTS'),
    },
    // {
    //   id: REPORT.REPORTS_KEYS.CUSTOM_KEY,
    //   name: i18n.t('LABEL_CUSTOM_REPORTS'),
    // },
  ]

  return (
    <Select
      style={{ width: 205 }}
      defaultValue={defaultSelectValue}
      onChange={(value) => setDefaultSelectValue(value)}
      options={generateSelectOptions({ options: REPORT_OPTIONS, selection })}
    />
  )
}

const VerticalAnalysisSelect = ({ loading, groupLines, selectedOptions, setSelectedOptions }) => {
  return (
    <Spin spinning={loading} size="small">
      <Select
        showArrow
        showSearch
        allowClear
        placeholder={i18n.t('REPORT_PLACEHOLDER_VERTICAL_ANALYSIS')}
        style={{ width: 300 }}
        onChange={(value) => setSelectedOptions(value)}
      >
        {groupLines.map((option) => (
          <Option key={option.name} value={option.name} title={option.name}>
            {option.name}
          </Option>
        ))}
      </Select>
    </Spin>
  )
}

const ComparisonVariantSelect = ({
  loading = false,
  baseReference,
  selectedOptions,
  setSelectedOptions,
  groupProjection = {
    BUDGET: [],
    FORECAST: [],
  },
}) => {
  let disabledOptionId = baseReference
    ? `${baseReference.value}${baseReference.id ? `-${baseReference.id}` : ''}`
    : undefined

  const renderOptionBudget = (optionList, disabledOption) =>
    renderGroupWithOption({
      label: i18n.t('LABEL_BUDGET'),
      parentId: PROJECTION.BUDGET,
      optionList: optionList,
      disabledOption: disabledOption,
    })

  const renderOptionForecast = (optionList, disabledOption) =>
    renderGroupWithOption({
      label: i18n.t('LABEL_FORECAST'),
      parentId: PROJECTION.FORECAST,
      optionList: optionList,
      disabledOption: disabledOption,
    })

  return (
    <Spin spinning={loading} size="small">
      <Tooltip title={i18n.t('REPORT_SELECT_PROYECTION_INFORMATIVE_TOOLTIP_TITLE')}>
        <Select
          className="report-detail-table-actions-select"
          value={
            !loading &&
            selectedOptions &&
            `${selectedOptions.value}${selectedOptions.id ? `-${selectedOptions.id}` : ''}`
          }
          showArrow
          showSearch
          onChange={(value) =>
            setSelectedOptions(
              value.includes('-')
                ? {
                    value: _.split(value, '-', 1)[0],
                    id: _.split(value, '-', 2)[1],
                  }
                : { value: value, id: null },
            )
          }
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children && option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option
            value={PROJECTION.REAL}
            title={i18n.t('REAL')}
            disabled={disabledOptionId === PROJECTION.REAL}
          >
            {i18n.t('REAL')}
          </Option>
          {groupProjection.BUDGET.length > 0 &&
            renderOptionBudget(groupProjection.BUDGET, disabledOptionId)}
          {groupProjection.FORECAST.length > 0 &&
            renderOptionForecast(groupProjection.FORECAST, disabledOptionId)}
        </Select>
      </Tooltip>
    </Spin>
  )
}

const GlobalComparisonSelect = ({
  localReferenceBase,
  setLocalReferenceBase,
  stepsInstance,
  dataPhaseBudget,
  localReferenceComparisonBase,
  comparativeOptions,
  setOptionsSelected,
  onApply,
  optionsSelected,
}) => {
  return (
    <Space direction="horizontal">
      <Select
        placeholder={i18n.t('ACTION_SELECT')}
        defaultValue={localReferenceBase.value}
        style={{ width: 200 }}
        key="selectbase"
        onChange={(val) => {
          setLocalReferenceBase({ value: val, id: null })
          const data = comparativeOptions.filter((el) => el !== val.toLowerCase())
          setOptionsSelected(data)
        }}
        options={generateSelectOptions({
          options: setPhaseAvailable(stepsInstance, dataPhaseBudget).map((el) => {
            return { id: el.key.toUpperCase(), name: i18n.t(el.title) }
          }),
          selection: localReferenceComparisonBase,
        })}
      />
      <Tooltip
        title={comparativeOptions.length === 0 ? i18n.t('NOT_PHASES_TO_COMPARE_TOOLTIP_TITLE') : ''}
      >
        <Select
          placeholder={i18n.t('ACTION_SELECT')}
          mode="multiple"
          style={{ width: 200 }}
          maxTagCount={1}
          disabled={comparativeOptions.length === 0}
          value={optionsSelected.length > 0 ? optionsSelected : comparativeOptions}
          key="select"
          onChange={(val) => setOptionsSelected(val)}
          options={generateSelectOptions({
            options: setPhaseAvailable(stepsInstance, dataPhaseBudget).map((el) => {
              return { id: el.key, name: i18n.t(el.title) }
            }),
            selection: [localReferenceBase.value.toLowerCase()],
          })}
        />
      </Tooltip>
      <Button.Primary
        onClick={onApply}
        title="ACTION_APPLY"
        disabled={comparativeOptions.length === 0}
      />
    </Space>
  )
}

const SelectType = {
  EvolutionSelect,
  ComparisonSelect,
  ReportSelect,
  VerticalAnalysisSelect,
  ComparisonVariantSelect,
  GlobalComparisonSelect,
}

export default SelectType

import { Select } from 'antd'
import { Typography as TypographyAntd } from 'antd'
import { generateSelectOptions } from 'modules/core/utils'
import { useTranslation } from 'react-i18next'
import { PushpinOutlined, PushpinFilled /*DeleteOutlined*/ } from '@ant-design/icons'
import Button from '../../Button/Button'
import Typography from '../../Typography/Typography'
import _ from 'lodash'
import ButtonActionBar from '../../ButtonActionBar/ButtonActionBar'

const { Text } = TypographyAntd

const FavouriteSelect = ({
  viewSelect,
  loading,
  options,
  setPrimary,
  setViewSelect,
  onClickDeleteFav,
  onClickEditNameFav,
}) => {
  const { t } = useTranslation()
  return (
    <Select
      defaultValue={!_.isEmpty(viewSelect) && viewSelect.name}
      key={!_.isEmpty(viewSelect) && viewSelect.name}
      onChange={(val) => console.log(val)}
      style={{ width: 200 }}
      bordered={false}
      loading={loading}
      options={generateSelectOptions({ options })}
      dropdownRender={(menu) => {
        return (
          <div style={{ maxHeight: 150, width: '100%', overflowY: 'auto' }}>
            <ButtonActionBar>
              <Button.Link title="ACTION_EDIT" onClick={() => onClickEditNameFav()} />
            </ButtonActionBar>
            {options.map((el) => {
              return (
                <div
                  key={el.value}
                  style={{
                    padding: 10,
                    width: '100%',
                    cursor: 'pointer',
                    ...(!_.isEmpty(viewSelect) && el.name === viewSelect.name
                      ? { backgroundColor: '#e1effa' }
                      : {}),
                  }}
                >
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div
                      style={{ width: 108 }}
                      onClick={(val) => setViewSelect(val.target.innerText)}
                    >
                      <Text title={el.name} ellipsis={true}>
                        {el.name}
                      </Text>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Button.Icon
                        title={
                          !_.isEmpty(viewSelect) && el.primary
                            ? t('FIELD_PREDETERMINED')
                            : t('ACTION_PREDETERMINED_PIN_UP')
                        }
                        icon={
                          <Typography.Icon
                            level={2}
                            icon={
                              !_.isEmpty(viewSelect) && el.primary ? PushpinFilled : PushpinOutlined
                            }
                          />
                        }
                        style={!_.isEmpty(viewSelect) && el.primary ? { cursor: 'default' } : {}}
                        onClick={() => {
                          !_.isEmpty(viewSelect) && !el.primary && setPrimary(el)
                        }}
                      />
                      {/* {!el.primary && (
                        <Button.Icon
                          title="ACTION_DELETE"
                          icon={<Typography.Icon level={2} icon={DeleteOutlined} />}
                          onClick={() => onClickDeleteFav(el.name)}
                        />
                      )} */}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        )
      }}
    />
  )
}

export default FavouriteSelect

import { useEffect, useState } from 'react'
import { Card, Col, Row, Space } from 'antd'
import { Button, ButtonActionBar, Typography } from 'modules/core/components'
import { Filters, /*Kpis,*/ SlidersInputs } from './components'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { UpSquareOutlined, DownSquareOutlined } from '@ant-design/icons'
import configuration from 'modules/configuration'

const FiltersSlidersKpis = ({
  dataPeriod,
  setSelectedDates,
  selectedDates,
  periodId,
  filters,
  setFilters,
  setSliderValue,
  sliderValue,
  dataDimensions,
  handleOnClick,
  isSaving,
  fetchCurrencyList,
  currencyList,
  disabled,
}) => {
  const [showFiltersSliders, setShowFiltersSliders] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    fetchCurrencyList()
  }, [fetchCurrencyList])

  return (
    <Space direction="vertical">
      <Space direction="horizontal">
        <Button.Icon
          icon={
            <Typography.Icon
              level={2}
              icon={showFiltersSliders ? UpSquareOutlined : DownSquareOutlined}
            />
          }
          onClick={() => setShowFiltersSliders(!showFiltersSliders)}
        />
        <Typography.Body>Sliders</Typography.Body>
      </Space>
      {showFiltersSliders && (
        <Card bodyStyle={{ padding: 10 }}>
          <Row gutter={[12, 12]}>
            <Col span={16}>
              <Space direction="vertical">
                <Typography.Body>{t('FIELD_FILTERS')}</Typography.Body>
                <Filters
                  dataPeriod={dataPeriod}
                  selectedDates={selectedDates}
                  setSelectedDates={setSelectedDates}
                  periodId={periodId}
                  filters={filters}
                  setFilters={setFilters}
                  dataDimensions={dataDimensions}
                  currencyList={currencyList.data}
                  disabled={disabled}
                />
              </Space>
            </Col>
            <Col span={8}>
              <Space direction="vertical" style={{ paddingLeft: 20 }}>
                <Typography.Body>Sliders</Typography.Body>
                <Row gutter={[12, 12]}>
                  <SlidersInputs
                    sliderValue={sliderValue}
                    setSliderValue={setSliderValue}
                    name={t('FIELD_PRICE')}
                    type="price_percentage_increase"
                    disabled={sliderValue.amount_percentage_increase || disabled}
                  />
                  <SlidersInputs
                    sliderValue={sliderValue}
                    setSliderValue={setSliderValue}
                    name={t('FIELD_COUNT')}
                    type="quantity_percentage_increase"
                    disabled={sliderValue.amount_percentage_increase || disabled}
                  />
                  <SlidersInputs
                    sliderValue={sliderValue}
                    setSliderValue={setSliderValue}
                    name={t('FIELD_AMOUNT')}
                    type="amount_percentage_increase"
                    disabled={
                      sliderValue.price_percentage_increase ||
                      sliderValue.quantity_percentage_increase ||
                      disabled
                    }
                  />
                </Row>
                <ButtonActionBar>
                  <Button.Primary
                    disabled={
                      (sliderValue.price_percentage_increase === 0 &&
                        sliderValue.quantity_percentage_increase === 0 &&
                        sliderValue.amount_percentage_increase === 0) ||
                      disabled
                    }
                    loading={isSaving}
                    onClick={handleOnClick}
                    title="ACTION_APPLY"
                    tooltipTitle={
                      sliderValue.price_percentage_increase === 0 &&
                      sliderValue.quantity_percentage_increase === 0 &&
                      sliderValue.amount_percentage_increase === 0
                        ? t('PLANNING_SLIDERS_FILTERS_DISABLED_BUTTON_TOOLTIP_TITLE')
                        : ''
                    }
                  />
                </ButtonActionBar>
              </Space>
            </Col>
            {/* <Col span={4}>
            <Card bodyStyle={{ padding: 10 }}>
              <Space direction="vertical" size="large">
                <Typography.Body>{t('FIELD_INDICATORS')}</Typography.Body>
                <Kpis />
              </Space>
            </Card>
          </Col> */}
          </Row>
        </Card>
      )}
    </Space>
  )
}

const mapStateToProps = (state) => ({
  currencyList: configuration.selectors.getCurrencyList(state),
})

const mapDispatchToProps = {
  fetchCurrencyList: configuration.actions.fetchCurrencyList,
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersSlidersKpis)

import React, { useEffect, useState } from 'react'
import { Select, Divider } from 'antd'
import { generateSelectOptions /*orderBySelection*/ } from 'modules/core/utils'
import Button from '../Button/Button'
import ButtonActionBar from '../ButtonActionBar/ButtonActionBar'
import _ from 'lodash'

const FilterSelect = ({
  label,
  dimensionId,
  onChangeSelection,
  selection,
  options,
  props = { mode: 'multiple' },
  showButtonDeleteFilter = null,
  setPagination = null,
}) => {
  const [valuesItems, setValuesItems] = useState(
    !_.isEmpty(selection) ? selection[`${dimensionId}`] : [],
  )

  useEffect(() => {
    _.isEmpty(selection) && setValuesItems([])
  }, [selection])

  const onDeleteAllItems = (idDimension) => {
    setValuesItems([])
    onChangeSelection([], idDimension)
    showButtonDeleteFilter && showButtonDeleteFilter(false)
    setPagination && setPagination()
  }

  const onDeselectItem = (idItem, idDimension) => {
    const auxValues = _.remove(valuesItems, function (id) {
      return id !== idItem
    })
    setValuesItems(auxValues)
    onChangeSelection(auxValues, idDimension)
    setPagination && setPagination()
  }

  return (
    <Select
      value={valuesItems}
      key={dimensionId}
      maxTagCount={1}
      showSearch
      optionFilterProp="children"
      style={{ width: 170 }}
      placeholder={label}
      maxTagTextLength={8}
      onChange={(idItem) => {
        setValuesItems(idItem)
        onChangeSelection(idItem, dimensionId)
      }}
      onDeselect={(idItem) => onDeselectItem(idItem, dimensionId)}
      filterOption={(input, option) => option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider />
          <ButtonActionBar>
            <Button.Link onClick={() => onDeleteAllItems(dimensionId)} title="ACTION_CLEAR" />
          </ButtonActionBar>
        </>
      )}
      options={generateSelectOptions({
        options: options, //orderBySelection(valuesItems, options),
      })}
      {...props}
    />
  )
}

export default FilterSelect

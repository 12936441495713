import { List } from 'antd'
import { Button, Typography } from 'modules/core/components'
import {
  RightCircleOutlined,
  WhatsAppOutlined,
  LinkedinOutlined,
  MailOutlined,
  ReadOutlined,
  SolutionOutlined,
  QuestionOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const ListInformation = ({ dataSource }) => {
  const { t } = useTranslation()

  const ICONS = {
    linkedin: <LinkedinOutlined style={{ color: '#6CA4E0', fontSize: 30 }} />,
    whatsapp: <WhatsAppOutlined style={{ color: '#6CA4E0', fontSize: 30 }} />,
    email: <MailOutlined style={{ color: '#6CA4E0', fontSize: 30 }} />,
    usermanual: <ReadOutlined style={{ color: '#6CA4E0', fontSize: 30 }} />,
    site: <SolutionOutlined style={{ color: '#6CA4E0', fontSize: 30 }} />,
    faqs: <QuestionOutlined style={{ color: '#6CA4E0', fontSize: 30 }} />,
  }

  return (
    <List
      dataSource={dataSource}
      renderItem={(item) => (
        <List.Item key={item.id}>
          <List.Item.Meta
            avatar={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '50%',
                  width: 50,
                  height: 50,
                  backgroundColor: '#E1EFFA',
                }}
              >
                {ICONS[item.id]}
              </div>
            }
            title={
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {t(item.title)}
                <Button.Icon
                  icon={<Typography.Icon icon={RightCircleOutlined} />}
                  title="ACTION_GO"
                  onClick={() => window.open(item.link, item.id !== 'email' ? '_blank' : '_self')}
                />
              </div>
            }
            description={t(item.description)}
          />
        </List.Item>
      )}
    />
  )
}

export default ListInformation

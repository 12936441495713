import React, { useState } from 'react'
import AdjustmentSkeleton from '../../../../../../../adjustments/containers/AdjustmentsList/components/AdjustmentSkeleton'

const FRONT_URL = process.env.REACT_APP_FRONT_URL || 'https://next.stg.getplika.com'

const Flat = ({ periodId, planningId, step }) => {
  // Revamp configuration
  const [loadingIframe, setLoadingIframe] = useState(true)

  const handleLoad = () => {
    console.log('Iframe loaded planning flat table')
    setLoadingIframe(false)
  }

  return (
    <div style={{ width: '100%', height: window.innerHeight - 170 }}>
      {loadingIframe && <AdjustmentSkeleton />}
      <iframe
        id="DashboardhHome"
        title="DashboardhHome"
        src={`${FRONT_URL}/iframe/planning/${periodId}/${planningId}/${step}/flat-table`}
        width="100%"
        height="100%"
        style={{
          border: 'none',
          padding: '0 0',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
        onLoad={handleLoad}
      ></iframe>
    </div>
  )
}

export default Flat
